import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid } from '@mui/material';
import ProductionReport from './ProductionReport';
import MortalityReport from './MortalityReport';
import EggWeightReport from './EggWeightReport';
import BodyWeightReport from './BodyWeightReport';


interface ReportDialogProps {
  open: boolean;
  onClose: () => void;
  report: any;
}

const ReportDialog: React.FC<ReportDialogProps> = ({ open, onClose, report }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>Weekly Reports</DialogTitle>
      <DialogContent>
        {report ? (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <ProductionReport report={report.productionReport} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MortalityReport report={report.mortalityReport} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <EggWeightReport report={report.eggWeightReport} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <BodyWeightReport report={report.bodyWeightReport} />
            </Grid>
           
          </Grid>
        ) : (
          'No data available for the selected criteria.'
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReportDialog;