import React from "react";
import { Link, Outlet } from "react-router-dom";
import "./LayoutScreen.css";

const LayoutScreen: React.FC = () => {
  return (
    <div className="layout-container5">
      <header className="header5">
        <div className="logo5">
          <Link to="/">
          <img src="http://sakkugroup.com/wp-content/uploads/2014/11/logo.png" alt="logo"/>
          </Link>
        </div>
        <nav className="nav5">
          <ul>
            {/* <li className="dropdown5">
              <span className="dropdown-toggle5">Menu</span>
              <div className="dropdown-content5">
                <Link to="/menu/excelUpload">Excel Upload</Link>
              </div>
            </li> */}
            <li>
            <span style={{marginLeft:"-295px"}} className="dropdown-toggle5"> <img style={{width:"300px", height:"65px", margin:"-10px", marginLeft:"-184px"}} src="Images/crux1.png" /> </span>
            </li>

            <li className="dropdown5">
              <span className="dropdown-toggle5">Masters</span>
              <div className="dropdown-content5">
                <Link to="/masters/unitcode">Unit Code</Link>
                <Link to="/masters/feedtype">Feed Type</Link>
              </div>
            </li>
            {/* <li className="dropdown5" >
              <span className="dropdown-toggle5">Breed Analysis</span>
              <div className="dropdown-content5">
                <Link to="/ai/ai-excel">Breed Analysis</Link>
                <Link to="/ai/ai-excel">Breed Week Analysis</Link>
              </div>
            </li> */}
            <li className="dropdown5" >
              <span className="dropdown-toggle5">Data Capturing</span>
              <div className="dropdown-content5">
                <Link to="/data-capturing/excelUpload">Poultry Data Capturing</Link>
                <Link to="/data-capturing/poultryStandard"> Poultry Standard </Link>
              </div>
            </li>
            <li>
            <li className="dropdown5" >
              <span className="dropdown-toggle5">Dashboards</span>
              <div className="dropdown-content5">
                <Link to="/dashboard/production-dashboard">Production Dashboard</Link>
                <Link to="/dashboard/business-dashboard"> Business Dashboard </Link>
              </div>
            </li>
              
            </li>
          </ul>
        </nav>
      </header>
      <main className="content5">
        <Outlet />
      </main>
    </div>
  );
};

export default LayoutScreen;
