// import React, { useState } from 'react';
// import './poultryShed.css';
// import "../dashboardCard/dashboardCard.css";
// import { Popover, Radio, Select } from 'antd';
// import PoultryDataAnalysis from '../dashboardCard/dashboardCard';
// import { poultryData } from '../dashboardCard/dashboardCardData';

// const { Option } = Select;

// export interface poultryShed {
//     shedImg?: string;
//     birdImg?: string;
//     eggImg?: string;
//     bodyImg?: string;
//     mortalityImg?: string;
//     productionImg?: string;
//     unit: {
//         unitName?: string;
//         shedName: number;
//         birdCount: number;
//         eggWeight: {
//             actual: string;
//             standard: string;
//         };
//         bodyWeight: {
//             actual: string;
//             standard: string;
//         };
//         mortality: {
//             actual: string;
//             standard: string;
//         };
//         production: {
//             actual: string;
//             standard: string;
//         };
//     }[];
// }

// export interface poultryShedProps {
//     poultryShedData: poultryShed[];
// }

// const getColor = (actual: string, standard: string): string => {
//     const actualValue = parseFloat(actual);
//     const standardValue = parseFloat(standard);

//     if (actualValue >= standardValue) return "#92BA78";
//     if (actualValue < standardValue) return "#ef233c";
//     return "#92BA78";
// };

// const shouldBackgroundBeRed = (shed: any): boolean => {
//     return (
//         getColor(shed.eggWeight.actual, shed.eggWeight.standard) === '#ef233c' ||
//         getColor(shed.bodyWeight.actual, shed.bodyWeight.standard) === '#ef233c' ||
//         getColor(shed.mortality.actual, shed.mortality.standard) === '#ef233c' ||
//         getColor(shed.production.actual, shed.production.standard) === '#ef233c'
//     );
// };

// const getUnitColor = (unit: any[]): string => {
//     for (const shed of unit) {
//         if (shouldBackgroundBeRed(shed)) {
//             return '#ef233c';
//         }
//     }
//     return '#92BA78';
// };

// const calculateAggregatedData = (unitData: any[]) => {
//     const total = unitData.length;

//     const aggregates = unitData.reduce(
//         (acc, shed) => {
//             acc.birdCount += shed.birdCount;
//             acc.eggWeight.actual += parseFloat(shed.eggWeight.actual);
//             acc.eggWeight.standard += parseFloat(shed.eggWeight.standard);
//             acc.bodyWeight.actual += parseFloat(shed.bodyWeight.actual);
//             acc.bodyWeight.standard += parseFloat(shed.bodyWeight.standard);
//             acc.mortality.actual += parseFloat(shed.mortality.actual);
//             acc.mortality.standard += parseFloat(shed.mortality.standard);
//             acc.production.actual += parseFloat(shed.production.actual);
//             acc.production.standard += parseFloat(shed.production.standard);
//             return acc;
//         },
//         {
//             birdCount: 0,
//             eggWeight: { actual: 0, standard: 0 },
//             bodyWeight: { actual: 0, standard: 0 },
//             mortality: { actual: 0, standard: 0 },
//             production: { actual: 0, standard: 0 },
//         }
//     );

//     return {
//         birdCount: Math.round(aggregates.birdCount / total),
//         eggWeight: {
//             actual: (aggregates.eggWeight.actual / total).toFixed(1),
//             standard: (aggregates.eggWeight.standard / total).toFixed(1),
//         },
//         bodyWeight: {
//             actual: (aggregates.bodyWeight.actual / total).toFixed(1),
//             standard: (aggregates.bodyWeight.standard / total).toFixed(1),
//         },
//         mortality: {
//             actual: (aggregates.mortality.actual / total).toFixed(1),
//             standard: (aggregates.mortality.standard / total).toFixed(1),
//         },
//         production: {
//             actual: (aggregates.production.actual / total).toFixed(1),
//             standard: (aggregates.production.standard / total).toFixed(1),
//         },
//     };
// };

// const PoultryShed: React.FC<poultryShedProps> = ({ poultryShedData }) => {
//     const [selectedUnit, setSelectedUnit] = useState<string>('All');
//     const [value, setValue] = React.useState('day');

//     const handleChange = (e: any) => {
//         setValue(e.target.value);
//     };

//     const handleUnitChange = (value: string) => {
//         setSelectedUnit(value);
//     };

//     const unitsArray = Array.from(new Set(poultryShedData.flatMap(data => data.unit.map(u => u.unitName))));

//     const filteredData = poultryShedData.map(data => ({
//         ...data,
//         unit: data.unit.filter(shed => {
//             if (selectedUnit === 'All' || !selectedUnit) return true;
//             return shed.unitName === selectedUnit;
//         }),
//     })).filter(data => data.unit.length > 0);

//     return (
//         <div className='poultry-shed-container-div'>
//             <div className='poultry-heading'>
//                 <div className="header-section">
//                     <div className="date-info">
//                         <span className="date_" style={{ marginLeft: "13px" }} >
//                             Current Date: <span id="date" > {new Date().toISOString().split("T")[0]} </span>
//                             <span style={{ marginLeft: "10px" }} className="dot"> &bull; </span>
//                             Last Updated Date: <span id="date">2024-08-15</span>
//                         </span>
//                     </div>
//                 </div>

//                 <div className="filter-container1">
//                     <Select
//                         className='filter_'
//                         placeholder="Select Unit"
//                         onChange={handleUnitChange}
//                         value={selectedUnit}
//                         allowClear
//                         style={{ width: 100, }}
//                     >
//                         <Option value="All">All</Option>
//                         {unitsArray.map((unit, index) => (
//                             <Option key={index} value={unit}>
//                                 {unit}
//                             </Option>
//                         ))}
//                     </Select>
//                     <div style={{ marginLeft: "10px" }}>
//                         <Radio.Group value={value} onChange={handleChange} style={{ display: 'flex', gap: '10px' }}>
//                             <Radio.Button value="day">This Day</Radio.Button>
//                             <Radio.Button value="week">This Week</Radio.Button>
//                             <Radio.Button value="month">This Month</Radio.Button>
//                         </Radio.Group>
//                     </div>
//                 </div>
//             </div>
//             <div className='dbCard-poultryShed-div'>
//                 < PoultryDataAnalysis data={poultryData} />
//                 <div className='poultry-shed-container-div-1'>
//                     {filteredData.map((data, index) => (
//                         <div className='poultry_shed-container' key={index}>
//                             {selectedUnit === 'All' ? (
//                                 //all units  
//                                 <div className='poultry_shed-avg-container'>
//                                     <div className='poultry_shed-avg-div'>
//                                         <h3 style={{ color: getUnitColor(data.unit) }}>{data.unit[0].unitName}</h3>
//                                         <div className="unit-data">
//                                             {(() => {
//                                                 const aggregates = calculateAggregatedData(data.unit);
//                                                 return (
//                                                     <div className='avg-unit'>
//                                                         <div style={{ marginRight: "8px" }}>
//                                                             <div style={{ display: "flex", marginTop: "px" }} >
//                                                                 <img className='poultry_unit-Img' src={data.birdImg} />
//                                                                 <span style={{ textAlign: 'center' }}>
//                                                                     {aggregates.birdCount}
//                                                                 </span>
//                                                             </div>

//                                                             <div style={{ display: "flex", marginTop: "8px" }} >
//                                                                 <span style={{ display: "flex" }}>
//                                                                     <img className='poultry_unit-Img' src={data.bodyImg} />
//                                                                     <p style={{ textAlign: 'center', color: getColor(aggregates.bodyWeight.actual, aggregates.bodyWeight.standard) }}>
//                                                                         {aggregates.bodyWeight.actual}g
//                                                                     </p>
//                                                                 </span>
//                                                             </div>

//                                                             <div style={{ display: "flex", marginTop: "8px" }} >
//                                                                 <img className='poultry_unit-Img' src={data.mortalityImg} />
//                                                                 <span style={{ textAlign: 'center' }}> {aggregates.mortality.actual}% </span>
//                                                             </div>
//                                                         </div>

//                                                         <div>
//                                                             <div style={{ display: "flex", marginTop: "3px" }} >
//                                                                 <span style={{ display: "flex" }}>
//                                                                     <img className='poultry_unit-Img' src={data.productionImg} />
//                                                                     <p style={{ textAlign: 'center', color: getColor(aggregates.production.actual, aggregates.production.standard) }}>
//                                                                         {aggregates.production.actual}%
//                                                                     </p>
//                                                                 </span>
//                                                             </div>

//                                                             <div style={{ display: "flex", marginTop: "8px" }} >
//                                                                 <span style={{ display: "flex" }}>
//                                                                     <img className='poultry_unit-egg-Img' src={data.eggImg} />
//                                                                     <p style={{ textAlign: 'center', color: getColor(aggregates.eggWeight.actual, aggregates.eggWeight.standard) }}>
//                                                                         {aggregates.eggWeight.actual}g
//                                                                     </p>
//                                                                 </span>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                 );
//                                             })()}
//                                         </div>
//                                     </div>
//                                 </div>
//                             ) : (
//                                 //sheds
//                                 data.unit.map((shed, idx) => (
//                                     <Popover
//                                         // mouseEnterDelay={0.5}
//                                         // mouseLeaveDelay={550}
//                                         key={idx}
//                                         content={
//                                             <div className='poultry-shed-popover'>
//                                                 <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
//                                                     <img className='poultry_ShedImg' src={data.shedImg} />
//                                                     <h3>{shed.shedName}</h3>
//                                                 </span>
//                                                 <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
//                                                     <img className='poultry_BirdImg' src={data.birdImg} />
//                                                     <h3>Bird Count: {shed.birdCount}</h3>
//                                                 </span>
//                                                 <span className='poultry-popover-span'>
//                                                     <span style={{ display: 'flex', alignItems: "center", justifyContent: "center", marginBottom: "-20px", }} >
//                                                         <img className='poultry_PopOver_Img' src={data.eggImg} />
//                                                         <p>  Egg Weight: </p>
//                                                     </span>
//                                                     <br />
//                                                     <p className='poultry-popover-p'>
//                                                         Act vs Std: &nbsp; {shed.eggWeight.actual} / {shed.eggWeight.standard}
//                                                     </p>
//                                                 </span>
//                                                 <span className='poultry-popover-span'>
//                                                     <span style={{ display: 'flex', alignItems: "center", justifyContent: "center", marginBottom: "-20px" }} >
//                                                         <img className='poultry_PopOver_Img' src={data.bodyImg} />
//                                                         <p>  Body Weight: </p>
//                                                     </span>
//                                                     <br />
//                                                     <p className='poultry-popover-p'>
//                                                         Act vs Std: &nbsp;  {shed.bodyWeight.actual} /  {shed.bodyWeight.standard}
//                                                     </p>
//                                                 </span>
//                                                 <span className='poultry-popover-span'>
//                                                     <span style={{ display: 'flex', alignItems: "center", justifyContent: "center", marginBottom: "-20px" }} >
//                                                         <img className='poultry_PopOver_Img' src={data.mortalityImg} />
//                                                         <p>  Mortality: </p>
//                                                     </span>
//                                                     <br />
//                                                     <p className='poultry-popover-p'>
//                                                         Act vs Std: &nbsp; {shed.mortality.actual} / {shed.mortality.standard}

//                                                     </p>
//                                                 </span>
//                                                 <span className='poultry-popover-span'>
//                                                     <span style={{ display: 'flex', alignItems: "center", justifyContent: "center", marginBottom: "-20px" }} >
//                                                         <img className='poultry_PopOver_Img' src={data.productionImg} />
//                                                         <p>  Production: </p>
//                                                     </span>
//                                                     <br />
//                                                     <p className='poultry-popover-p'>
//                                                         Act vs Std: &nbsp; {shed.production.actual} / {shed.production.standard}
//                                                     </p>
//                                                 </span>
//                                             </div>
//                                         }
//                                     >
//                                         <div style={{ display: "flex", fontWeight: "bold", }} className='poultry_shed-div'>
//                                             <div style={{ display: "flex", flexDirection: "column", flex: "0.6", color: "white" }}>
//                                                 <div style={{ backgroundColor: shouldBackgroundBeRed(shed) ? '#ef233c' : '#92BA78', flex: "0.5", alignItems: "center", justifyContent: "space-evenly", display: "flex" }}>
//                                                     <img className='poultry_ShedImg' src={data.shedImg} />
//                                                     <h3>{shed.shedName}</h3>
//                                                 </div>
//                                                 <div style={{ flex: "0.5", display: "", justifyContent: "space-evenly", marginTop:"5px" }}>
//                                                     <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
//                                                         <img className='poultry_Img' src={data.birdImg} />
//                                                         <p>{shed.birdCount}</p>
//                                                     </span>
//                                                     <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
//                                                         <img className='poultry_Img'  src={data.mortalityImg} />
//                                                         <p>{shed.mortality.actual}</p>
//                                                     </span>
//                                                 </div>
//                                             </div>
//                                             <div style={{ display: 'grid', flex: "0.5", color: "black" }}>
//                                                 <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
//                                                     <img className='poultry_Img' src={data.eggImg} />
//                                                     <p style={{ color: getColor(shed.eggWeight.actual, shed.eggWeight.standard), textAlign: 'center' }}>
//                                                         {shed.eggWeight.actual}
//                                                     </p>
//                                                 </span>
//                                                 <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
//                                                     <img className='poultry_Img' src={data.bodyImg} />
//                                                     <p style={{ color: getColor(shed.bodyWeight.actual, shed.bodyWeight.standard), textAlign: 'center' }}>
//                                                         {shed.bodyWeight.actual}
//                                                     </p>
//                                                 </span>
//                                                 <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
//                                                     <img className='poultry_Img' src={data.productionImg} />
//                                                     <p style={{ color: getColor(shed.production.actual, shed.production.standard), textAlign: 'center' }}>
//                                                         {shed.production.actual}
//                                                     </p>
//                                                 </span>
//                                             </div>
//                                         </div>
//                                     </Popover>
//                                 ))
//                             )}
//                         </div>
//                     ))}
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default PoultryShed;
















// const getColor = (minValue: number, maxValue: number, currentValue: number, reverseLogic = false): string => {
//     if (reverseLogic) {
//         // Logic for mortality
//         if (currentValue <= minValue) return 'green';
//         if (currentValue <= maxValue) return 'yellow';
//         return '#ef233c';
//     } else {
//         // Logic for chick count, weight, egg weight, production
//         if (currentValue <= minValue) return '#ef233c';
//         if (currentValue < maxValue) return 'yellow';
//         return 'green';
//     }
// };

















// tesing with min and max value data












// import React, { useState } from 'react';
// import './poultryShed.css';
// import "../dashboardCard/dashboardCard.css";
// import { Popover, Radio, Select } from 'antd';
// import PoultryDataAnalysis from '../dashboardCard/dashboardCard';
// import { poultryData } from '../dashboardCard/dashboardCardData';

// const { Option } = Select;

// export interface poultryShed {
//     shedImg?: string;
//     birdImg?: string;
//     eggImg?: string;
//     bodyImg?: string;
//     mortalityImg?: string;
//     productionImg?: string;
//     unit: {
//         unitName?: string;
//         shedName: number;
//         birdCount: number;
//         eggWeight: {
//             actual: string;
//             standard: string;
//             minValue?: string;
//             maxValue?: string;
//         };
//         bodyWeight: {
//             actual: string;
//             standard: string;
//             minValue?: string;
//             maxValue?: string;
//         };
//         mortality: {
//             actual: string;
//             standard: string;
//             minValue?: string;
//             maxValue?: string;
//         };
//         production: {
//             actual: string;
//             standard: string;
//             minValue?: string;
//             maxValue?: string;
//         };
//     }[];
// }

// export interface poultryShedProps {
//     poultryShedData: poultryShed[];
// }

// const getColor = (minValue: number, maxValue: number, currentValue: number, reverseLogic = false): string => {
//     console.log(`getColor called with minValue: ${minValue}, maxValue: ${maxValue}, currentValue: ${currentValue}, reverseLogic: ${reverseLogic}`);

//     if (reverseLogic) {
//         // Logic for mortality
//         if (currentValue <= minValue) {
//             console.log('Color: green');
//             return 'green';
//         }
//         if (currentValue <= maxValue) {
//             console.log('Color: yellow');
//             return 'yellow';
//         }
//         console.log('Color: red');
//         return '#ef233c';
//     } else {
//         // Logic for chick count, weight, egg weight, production
//         if (currentValue <= minValue) {
//             console.log('Color: red');
//             return '#ef233c';
//         }
//         if (currentValue < maxValue) {
//             console.log('Color: yellow');
//             return 'yellow';
//         }
//         console.log('Color: green');
//         return 'green';
//     }
// };

// const shouldBackgroundBeRed = (shed: any): boolean => {
//     return (
//         getColor(shed.eggWeight.actual, shed.eggWeight.minValue, shed.eggWeight.maxValue) === '#ef233c' ||
//         getColor(shed.bodyWeight.actual, shed.bodyWeight.standard, shed.bodyWeight.minValue, shed.bodyWeight.maxValue) === '#ef233c' ||
//         getColor(shed.mortality.actual, shed.mortality.minValue, shed.mortality.maxValue, true) === '#ef233c' ||
//         getColor(shed.production.actual, shed.production.standard, shed.production.minValue, shed.production.maxValue) === '#ef233c'
//     );
// };

// const getUnitColor = (unit: any[]): string => {
//     for (const shed of unit) {
//         if (shouldBackgroundBeRed(shed)) {
//             return '#ef233c';
//         }
//     }
//     return '#92BA78';
// };

// const calculateAggregatedData = (unitData: any[]) => {
//     const total = unitData.length;

//     const aggregates = unitData.reduce(
//         (acc, shed) => {
//             acc.birdCount += shed.birdCount;
//             acc.eggWeight.actual += parseFloat(shed.eggWeight.actual);
//             acc.eggWeight.standard += parseFloat(shed.eggWeight.standard);
//             acc.bodyWeight.actual += parseFloat(shed.bodyWeight.actual);
//             acc.bodyWeight.standard += parseFloat(shed.bodyWeight.standard);
//             acc.mortality.actual += parseFloat(shed.mortality.actual);
//             acc.mortality.standard += parseFloat(shed.mortality.standard);
//             acc.production.actual += parseFloat(shed.production.actual);
//             acc.production.standard += parseFloat(shed.production.standard);
//             return acc;
//         },
//         {
//             birdCount: 0,
//             eggWeight: { actual: 0, standard: 0 },
//             bodyWeight: { actual: 0, standard: 0 },
//             mortality: { actual: 0, standard: 0 },
//             production: { actual: 0, standard: 0 },
//         }
//     );

//     return {
//         birdCount: Math.round(aggregates.birdCount / total),
//         eggWeight: {
//             actual: (aggregates.eggWeight.actual / total).toFixed(1),
//             standard: (aggregates.eggWeight.standard / total).toFixed(1),
//         },
//         bodyWeight: {
//             actual: (aggregates.bodyWeight.actual / total).toFixed(1),
//             standard: (aggregates.bodyWeight.standard / total).toFixed(1),
//         },
//         mortality: {
//             actual: (aggregates.mortality.actual / total).toFixed(1),
//             standard: (aggregates.mortality.standard / total).toFixed(1),
//         },
//         production: {
//             actual: (aggregates.production.actual / total).toFixed(1),
//             standard: (aggregates.production.standard / total).toFixed(1),
//         },
//     };
// };

// const PoultryShed: React.FC<poultryShedProps> = ({ poultryShedData }) => {
//     const [selectedUnit, setSelectedUnit] = useState<string>('All');
//     const [value, setValue] = React.useState('day');

//     const handleChange = (e: any) => {
//         setValue(e.target.value);
//     };

//     const handleUnitChange = (value: string) => {
//         setSelectedUnit(value);
//     };

//     const renderColor = (actual: string, minValue?: string, maxValue?: string, reverseLogic = false) => {
//         const currentValue = parseFloat(actual);
//         const minVal = minValue ? parseFloat(minValue) : 0;
//         const maxVal = maxValue ? parseFloat(maxValue) : 0;
//         return getColor(minVal, maxVal, currentValue, reverseLogic);
//     };


//     const unitsArray = Array.from(new Set(poultryShedData.flatMap(data => data.unit.map(u => u.unitName))));

//     const filteredData = poultryShedData.map(data => ({
//         ...data,
//         unit: data.unit.filter(shed => {
//             if (selectedUnit === 'All' || !selectedUnit) return true;
//             return shed.unitName === selectedUnit;
//         }),
//     })).filter(data => data.unit.length > 0);

//     return (
//         <div className='poultry-shed-container-div'>
//             <div className='poultry-heading'>
//                 <div className="header-section">
//                     <div className="date-info">
//                         <span className="date_" style={{ marginLeft: "13px" }} >
//                             Current Date: <span id="date" > {new Date().toISOString().split("T")[0]} </span>
//                             <span style={{ marginLeft: "10px" }} className="dot"> &bull; </span>
//                             Last Updated Date: <span id="date">2024-08-15</span>
//                         </span>
//                     </div>
//                 </div>

//                 <div className="filter-container1">
//                     <Select
//                         className='filter_'
//                         placeholder="Select Unit"
//                         onChange={handleUnitChange}
//                         value={selectedUnit}
//                         allowClear
//                         style={{ width: 100, }}
//                     >
//                         <Option value="All">All</Option>
//                         {unitsArray.map((unit, index) => (
//                             <Option key={index} value={unit}>
//                                 {unit}
//                             </Option>
//                         ))}
//                     </Select>
//                     <div style={{ marginLeft: "10px" }}>
//                         <Radio.Group value={value} onChange={handleChange} style={{ display: 'flex', gap: '10px' }}>
//                             <Radio.Button value="day">This Day</Radio.Button>
//                             <Radio.Button value="week">This Week</Radio.Button>
//                             <Radio.Button value="month">This Month</Radio.Button>
//                         </Radio.Group>
//                     </div>
//                 </div>
//             </div>
//             <div className='dbCard-poultryShed-div'>
//                 < PoultryDataAnalysis data={poultryData} />
//                 <div className='poultry-shed-container-div-1'>
//                     {filteredData.map((data, index) => (
//                         <div className='poultry_shed-container' key={index}>
//                             {selectedUnit === 'All' ? (
//                                 //all units  
//                                 <div className='poultry_shed-avg-container'>
//                                     <div className='poultry_shed-avg-div'>
//                                         <h3 style={{ color: getUnitColor(data.unit) }}>{data.unit[0].unitName}</h3>
//                                         <div className="unit-data">
//                                             {(() => {
//                                                 const aggregates = calculateAggregatedData(data.unit);
//                                                 return (
//                                                     <div className='avg-unit'>
//                                                         <div style={{ marginRight: "8px" }}>
//                                                             <div style={{ display: "flex", marginTop: "px" }} >
//                                                                 <img className='poultry_unit-Img' src={data.birdImg} />
//                                                                 <span style={{ textAlign: 'center' }}>
//                                                                     {aggregates.birdCount}
//                                                                 </span>
//                                                             </div>

//                                                             <div style={{ display: "flex", marginTop: "8px" }} >
//                                                                 <span style={{ display: "flex" }}>
//                                                                     <img className='poultry_unit-Img' src={data.bodyImg} />
//                                                                     <p style={{ textAlign: 'center', color: renderColor(aggregates.bodyWeight.actual, aggregates.bodyWeight.standard) }}>
//                                                                         {aggregates.bodyWeight.actual}g
//                                                                     </p>
//                                                                 </span>
//                                                             </div>

//                                                             <div style={{ display: "flex", marginTop: "8px" }} >
//                                                                 <img className='poultry_unit-Img' src={data.mortalityImg} />
//                                                                 <span style={{ textAlign: 'center' }}> {aggregates.mortality.actual}% </span>
//                                                             </div>
//                                                         </div>

//                                                         <div>
//                                                             <div style={{ display: "flex", marginTop: "3px" }} >
//                                                                 <span style={{ display: "flex" }}>
//                                                                     <img className='poultry_unit-Img' src={data.productionImg} />
//                                                                     <p style={{ textAlign: 'center', color: renderColor(aggregates.production.actual, aggregates.production.standard) }}>
//                                                                         {aggregates.production.actual}%
//                                                                     </p>
//                                                                 </span>
//                                                             </div>

//                                                             <div style={{ display: "flex", marginTop: "8px" }} >
//                                                                 <span style={{ display: "flex" }}>
//                                                                     <img className='poultry_unit-egg-Img' src={data.eggImg} />
//                                                                     <p style={{ textAlign: 'center', color: renderColor(aggregates.eggWeight.actual, aggregates.eggWeight.standard) }}>
//                                                                         {aggregates.eggWeight.actual}g
//                                                                     </p>
//                                                                 </span>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                 );
//                                             })()}
//                                         </div>
//                                     </div>
//                                 </div>
//                             ) : (
//                                 //sheds
//                                 data.unit.map((shed, idx) => (
//                                     <Popover
//                                         // mouseEnterDelay={0.5}
//                                         // mouseLeaveDelay={550}
//                                         key={idx}
//                                         content={
//                                             <div className='poultry-shed-popover'>
//                                                 <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
//                                                     <img className='poultry_ShedImg' src={data.shedImg} />
//                                                     <h3>{shed.shedName}</h3>
//                                                 </span>
//                                                 <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
//                                                     <img className='poultry_BirdImg' src={data.birdImg} />
//                                                     <h3>Bird Count: {shed.birdCount}</h3>
//                                                 </span>
//                                                 <span className='poultry-popover-span'>
//                                                     <span style={{ display: 'flex', alignItems: "center", justifyContent: "center", marginBottom: "-20px", }} >
//                                                         <img className='poultry_PopOver_Img' src={data.eggImg} />
//                                                         <p>  Egg Weight: </p>
//                                                     </span>
//                                                     <br />
//                                                     <p className='poultry-popover-p'>
//                                                         Act vs Std: &nbsp; {shed.eggWeight.actual} / {shed.eggWeight.standard}
//                                                     </p>
//                                                 </span>
//                                                 <span className='poultry-popover-span'>
//                                                     <span style={{ display: 'flex', alignItems: "center", justifyContent: "center", marginBottom: "-20px" }} >
//                                                         <img className='poultry_PopOver_Img' src={data.bodyImg} />
//                                                         <p>  Body Weight: </p>
//                                                     </span>
//                                                     <br />
//                                                     <p className='poultry-popover-p'>
//                                                         Act vs Std: &nbsp;  {shed.bodyWeight.actual} /  {shed.bodyWeight.standard}
//                                                     </p>
//                                                 </span>
//                                                 <span className='poultry-popover-span'>
//                                                     <span style={{ display: 'flex', alignItems: "center", justifyContent: "center", marginBottom: "-20px" }} >
//                                                         <img className='poultry_PopOver_Img' src={data.mortalityImg} />
//                                                         <p>  Mortality: </p>
//                                                     </span>
//                                                     <br />
//                                                     <p className='poultry-popover-p'>
//                                                         Act vs Std: &nbsp; {shed.mortality.actual} / {shed.mortality.standard}

//                                                     </p>
//                                                 </span>
//                                                 <span className='poultry-popover-span'>
//                                                     <span style={{ display: 'flex', alignItems: "center", justifyContent: "center", marginBottom: "-20px" }} >
//                                                         <img className='poultry_PopOver_Img' src={data.productionImg} />
//                                                         <p>  Production: </p>
//                                                     </span>
//                                                     <br />
//                                                     <p className='poultry-popover-p'>
//                                                         Act vs Std: &nbsp; {shed.production.actual} / {shed.production.standard}
//                                                     </p>
//                                                 </span>
//                                             </div>
//                                         }
//                                     >
//                                         <div style={{ display: "flex", fontWeight: "bold", }} className='poultry_shed-div'>
//                                             <div style={{ display: "flex", flexDirection: "column", flex: "0.6", color: "white" }}>
//                                                 <div style={{ backgroundColor: shouldBackgroundBeRed(shed) ? '#ef233c' : '#92BA78', flex: "0.5", alignItems: "center", justifyContent: "space-evenly", display: "flex" }}>
//                                                     <img className='poultry_ShedImg' src={data.shedImg} />
//                                                     <h3>{shed.shedName}</h3>
//                                                 </div>
//                                                 <div style={{ flex: "0.5", display: "", justifyContent: "space-evenly", marginTop:"5px" }}>
//                                                     <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
//                                                         <img className='poultry_Img' src={data.birdImg} />
//                                                         <p>{shed.birdCount}</p>
//                                                     </span>
//                                                     <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
//                                                         <img className='poultry_Img'  src={data.mortalityImg} />
//                                                         <p style={{ color: renderColor(shed.mortality.actual, shed.mortality.minValue, shed.mortality.maxValue), textAlign: 'center' }}>
//                                                         {shed.mortality.actual}
//                                                     </p>
//                                                     </span>
//                                                 </div>
//                                             </div>
//                                             <div style={{ display: 'grid', flex: "0.5", color: "black" }}>
//                                                 <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
//                                                     <img className='poultry_Img' src={data.eggImg} />
//                                                     <p style={{ color: renderColor(shed.eggWeight.actual, shed.eggWeight.minValue, shed.eggWeight.maxValue), textAlign: 'center' }}>
//                                                         {shed.eggWeight.actual}
//                                                     </p>
//                                                 </span>
//                                                 <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
//                                                     <img className='poultry_Img' src={data.bodyImg} />
//                                                     <p style={{ color: renderColor(shed.bodyWeight.actual, shed.bodyWeight.minValue, shed.bodyWeight.maxValue), textAlign: 'center' }}>
//                                                         {shed.bodyWeight.actual}
//                                                     </p>
//                                                 </span>
//                                                 <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
//                                                     <img className='poultry_Img' src={data.productionImg} />
//                                                     <p style={{ color: renderColor(shed.production.actual, shed.production.minValue, shed.production.maxValue), textAlign: 'center' }}>
//                                                         {shed.production.actual}
//                                                     </p>
//                                                 </span>
//                                             </div>
//                                         </div>
//                                     </Popover>
//                                 ))
//                             )}
//                         </div>
//                     ))}
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default PoultryShed;






















//tesing for mortality color logic.












import React, { useState } from 'react';
import { Popover, Radio, Select } from 'antd';
import './poultryShed.css';

import PoultryDataAnalysis from '../dashboardCard/dashboardCard';
import { poultryData } from '../dashboardCard/dashboardCardData';
import "../dashboardCard/dashboardCard.css";
import { poultryShedData } from './poultryShedData';


const { Option } = Select;

export interface poultryShed {
    shedImg?: string;
    birdImg?: string;
    eggImg?: string;
    bodyImg?: string;
    mortalityImg?: string;
    productionImg?: string;
    unit: {
        unitName?: string;
        shedName: number;
        birdCount: number;
        eggWeight: {
            actual: string;
            standard: string;
            minValue?: string;
            maxValue?: string;
        };
        bodyWeight: {
            actual: string;
            standard: string;
            minValue?: string;
            maxValue?: string;
        };
        mortality: {
            actual: any;
            standard: string;
            minValue?: any;
            maxValue?: any;
        };
        production: {
            actual: string;
            standard: string;
            minValue?: string;
            maxValue?: string;
        };
    }[];
}

export interface poultryShedProps {
    poultryShedData: poultryShed[];
}

export interface PoultryDataItem {
    title: string;
    imgSrc: string;
    today: number | string;
    yesterday: number | string;
    borderColor: string;
    lastThreeDays: {
        actual: string;
        standard: string;
    }[];
}



export interface PoultryDataAnalysisProps {
    data: PoultryDataItem[]
}


const getColor = (
    minValue: number,
    maxValue: number,
    currentValue: number,
    reverseLogic = false
): string => {
    console.log(`getColor called with minValue: ${minValue}, maxValue: ${maxValue}, currentValue: ${currentValue}, reverseLogic: ${reverseLogic}`);

    if (reverseLogic) {
        if (currentValue > maxValue) {
            console.log('Color: red');
            return '#ef233c';
        }
        if (currentValue > minValue) {
            console.log('Color: yellow');
            return 'yellow';
        }
        console.log('Color: green');
        return 'green';
    } else {
        if (currentValue < minValue) {
            console.log('Color: red');
            return '#ef233c';
        }
        if (currentValue < maxValue) {
            console.log('Color: yellow');
            return 'yellow';
        }
        console.log('Color: green');
        return 'green';
    }
};

const shouldBackgroundBeRed = (shed: any): boolean => {
    return (
        getColor(shed.eggWeight.minValue, shed.eggWeight.maxValue, shed.eggWeight.actual) === '#ef233c' ||
        getColor(shed.bodyWeight.minValue, shed.bodyWeight.maxValue, shed.bodyWeight.actual) === '#ef233c' ||
        getColor(shed.mortality.minValue, shed.mortality.maxValue, shed.mortality.actual, true) === '#ef233c' ||
        getColor(shed.production.minValue, shed.production.maxValue, shed.production.actual) === '#ef233c'
    );
};



const getUnitColor = (unit: any[]): string => {
    for (const shed of unit) {
        if (shouldBackgroundBeRed(shed)) {
            return '#ef233c';
        }
    }
    return '#92BA78';
};

const calculateAggregatedData = (unitData: any[]) => {
    const total = unitData.length;

    const aggregates = unitData.reduce(
        (acc, shed) => {
            acc.birdCount += shed.birdCount;
            acc.eggWeight.actual += parseFloat(shed.eggWeight.actual);
            acc.eggWeight.standard += parseFloat(shed.eggWeight.standard);
            acc.bodyWeight.actual += parseFloat(shed.bodyWeight.actual);
            acc.bodyWeight.standard += parseFloat(shed.bodyWeight.standard);
            acc.mortality.actual += parseFloat(shed.mortality.actual);
            acc.mortality.standard += parseFloat(shed.mortality.standard);
            acc.production.actual += parseFloat(shed.production.actual);
            acc.production.standard += parseFloat(shed.production.standard);
            return acc;
        },
        {
            birdCount: 0,
            eggWeight: { actual: 0, standard: 0 },
            bodyWeight: { actual: 0, standard: 0 },
            mortality: { actual: 0, standard: 0 },
            production: { actual: 0, standard: 0 },
        }
    );

    return {
        birdCount: Math.round(aggregates.birdCount / total),
        eggWeight: {
            actual: (aggregates.eggWeight.actual / total).toFixed(1),
            standard: (aggregates.eggWeight.standard / total).toFixed(1),
        },
        bodyWeight: {
            actual: (aggregates.bodyWeight.actual / total).toFixed(1),
            standard: (aggregates.bodyWeight.standard / total).toFixed(1),
        },
        mortality: {
            actual: (aggregates.mortality.actual / total).toFixed(1),
            standard: (aggregates.mortality.standard / total).toFixed(1),
        },
        production: {
            actual: (aggregates.production.actual / total).toFixed(1),
            standard: (aggregates.production.standard / total).toFixed(1),
        },
    };
};

export const DashboardCards: React.FC<PoultryDataAnalysisProps> = ({ data }) => {
    return (
        <div>
            {data.map((dbCards, index) => (

                 <div>  Active Sheds : {dbCards.today}  </div>

            ))}
        </div>
        
    )
}


const PoultryShed: React.FC<poultryShedProps> = ({ poultryShedData }) => {
    const [selectedUnit, setSelectedUnit] = useState<string>('All');
    const [value, setValue] = React.useState('day');

    const handleChange = (e: any) => {
        setValue(e.target.value);
    };

    const handleUnitChange = (value: string) => {
        setSelectedUnit(value);
    };

    const renderColor = (actual: string, minValue?: string, maxValue?: string, reverseLogic = false) => {
        const currentValue = parseFloat(actual);
        const minVal = minValue ? parseFloat(minValue) : 0;
        const maxVal = maxValue ? parseFloat(maxValue) : 0;
        return getColor(minVal, maxVal, currentValue, reverseLogic);
    };

    const reverseRenderColor = (actual: string, minValue: string, maxValue: string, reverseLogic = true) => {
        const currentValue = parseFloat(actual);
        const minVal = minValue ? parseFloat(minValue) : 0;
        const maxVal = maxValue ? parseFloat(maxValue) : 0;
        return getColor(minVal, maxVal, currentValue, reverseLogic);
    }

    const unitsArray = Array.from(new Set(poultryShedData.flatMap(data => data.unit.map(u => u.unitName))));

    const filteredData = poultryShedData.map(data => ({
        ...data,
        unit: data.unit.filter(shed => {
            if (selectedUnit === 'All' || !selectedUnit) return true;
            return shed.unitName === selectedUnit;
        }),
    })).filter(data => data.unit.length > 0);


    return (
        <div className='poultry-shed-container-div'>
            <div className='poultry-heading'>
                <div className="header-section">
                    <div className="date-info">
                        <span className="date_" style={{ marginLeft: "13px" }} >
                            Current Date: <span id="date" > {new Date().toISOString().split("T")[0]} </span>
                            <span style={{ marginLeft: "10px" }} className="dot"> &bull; </span>
                            Last Updated Date: <span id="date">2024-08-15</span>
                            {/* <span> Active Sheds: <DashboardCards data={poultryData} />   </span> */}
                        </span>
                    </div>
                </div>

                <div className="filter-container1">
                    <Select
                        className='filter_'
                        placeholder="Select Unit"
                        onChange={handleUnitChange}
                        value={selectedUnit}
                        allowClear
                        style={{ width: 100, }}
                    >
                        <Option value="All">All</Option>
                        {unitsArray.map((unit, index) => (
                            <Option key={index} value={unit}>
                                {unit}
                            </Option>
                        ))}
                    </Select>
                    <div style={{ marginLeft: "10px" }}>
                        <Radio.Group value={value} onChange={handleChange} style={{ display: 'flex', gap: '10px' }}>
                            <Radio.Button value="day">This Day</Radio.Button>
                            <Radio.Button value="week">This Week</Radio.Button>
                            <Radio.Button value="month">This Month</Radio.Button>
                        </Radio.Group>
                    </div>
                </div>
            </div>
            <div className='dbCard-poultryShed-div'>
                < PoultryDataAnalysis data={poultryData} />
                <div className='poultry-shed-container-div-1'>
                    {filteredData.map((data, index) => (
                        <div className='poultry_shed-container' key={index}>
                            {selectedUnit === 'All' ? (   //all units  
                                <div className='poultry_shed-avg-container'>
                                    <div className='poultry_shed-avg-div'>
                                        <h3 style={{ color: getUnitColor(data.unit) }}>{data.unit[0].unitName}</h3>
                                        <div className="unit-data">
                                            {(() => {
                                                const aggregates = calculateAggregatedData(data.unit);
                                                return (
                                                    <div className='avg-unit'>
                                                        <div style={{ marginRight: "8px" }}>
                                                            <div style={{ display: "flex", marginTop: "px" }} >
                                                                <img className='poultry_unit-Img' src={data.birdImg} />
                                                                <span style={{ textAlign: 'center' }}>
                                                                    {aggregates.birdCount}
                                                                </span>
                                                            </div>

                                                            <div style={{ display: "flex", marginTop: "8px" }} >
                                                                <span style={{ display: "flex" }}>
                                                                    <img className='poultry_unit-Img' src={data.bodyImg} />
                                                                    <p style={{ textAlign: 'center', color: renderColor(aggregates.bodyWeight.actual, aggregates.bodyWeight.standard) }}>
                                                                        {aggregates.bodyWeight.actual}g
                                                                    </p>
                                                                </span>
                                                            </div>

                                                            <div style={{ display: "flex", marginTop: "8px" }} >
                                                                <img className='poultry_unit-Img' src={data.mortalityImg} />
                                                                <span style={{ textAlign: 'center' }}> {aggregates.mortality.actual}% </span>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div style={{ display: "flex", marginTop: "3px" }} >
                                                                <span style={{ display: "flex" }}>
                                                                    <img className='poultry_unit-Img' src={data.productionImg} />
                                                                    <p style={{ textAlign: 'center', color: renderColor(aggregates.production.actual, aggregates.production.standard) }}>
                                                                        {aggregates.production.actual}%
                                                                    </p>
                                                                </span>
                                                            </div>

                                                            <div style={{ display: "flex", marginTop: "8px" }} >
                                                                <span style={{ display: "flex" }}>
                                                                    <img className='poultry_unit-egg-Img' src={data.eggImg} />
                                                                    <p style={{ textAlign: 'center', color: renderColor(aggregates.eggWeight.actual, aggregates.eggWeight.standard) }}>
                                                                        {aggregates.eggWeight.actual}g
                                                                    </p>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })()}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                //sheds
                                data.unit.map((shed, idx) => (
                                    <Popover
                                        // mouseLeaveDelay={550}
                                        key={idx}
                                        content={
                                            <div className='poultry-shed-popover'>
                                                <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                                                    <img className='poultry_ShedImg' src={data.shedImg} />
                                                    <h3>{shed.shedName}</h3>
                                                </span>
                                                <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                                                    <img className='poultry_BirdImg' src={data.birdImg} />
                                                    <h3>Bird Count: {shed.birdCount}</h3>
                                                </span>
                                                <span className='poultry-popover-span'>
                                                    <span style={{ display: 'flex', alignItems: "center", justifyContent: "center", marginBottom: "-20px", }} >
                                                        <img className='poultry_PopOver_Img' src={data.eggImg} />
                                                        <p>  Egg Weight: </p>
                                                    </span>
                                                    <br />
                                                    <p className='poultry-popover-p'>
                                                        Act vs Std: &nbsp; {shed.eggWeight.actual} / {shed.eggWeight.standard}
                                                    </p>
                                                </span>
                                                <span className='poultry-popover-span'>
                                                    <span style={{ display: 'flex', alignItems: "center", justifyContent: "center", marginBottom: "-20px" }} >
                                                        <img className='poultry_PopOver_Img' src={data.bodyImg} />
                                                        <p>  Body Weight: </p>
                                                    </span>
                                                    <br />
                                                    <p className='poultry-popover-p'>
                                                        Act vs Std: &nbsp;  {shed.bodyWeight.actual} /  {shed.bodyWeight.standard}
                                                    </p>
                                                </span>
                                                <span className='poultry-popover-span'>
                                                    <span style={{ display: 'flex', alignItems: "center", justifyContent: "center", marginBottom: "-20px" }} >
                                                        <img className='poultry_PopOver_Img' src={data.mortalityImg} />
                                                        <p>  Mortality: </p>
                                                    </span>
                                                    <br />
                                                    <p className='poultry-popover-p'>
                                                        Act vs Std: &nbsp; {shed.mortality.actual} / {shed.mortality.standard}

                                                    </p>
                                                </span>
                                                <span className='poultry-popover-span'>
                                                    <span style={{ display: 'flex', alignItems: "center", justifyContent: "center", marginBottom: "-20px" }} >
                                                        <img className='poultry_PopOver_Img' src={data.productionImg} />
                                                        <p>  Production: </p>
                                                    </span>
                                                    <br />
                                                    <p className='poultry-popover-p'>
                                                        Act vs Std: &nbsp; {shed.production.actual} / {shed.production.standard}
                                                    </p>
                                                </span>
                                            </div>
                                        }
                                    >
                                        <div style={{ display: "flex", fontWeight: "bold", }} className='poultry_shed-div'>
                                            <div style={{ display: "flex", flexDirection: "column", flex: "0.6", color: "white" }}>
                                                <div style={{ backgroundColor: shouldBackgroundBeRed(shed) ? '#ef233c' : '#92BA78', flex: "0.5", alignItems: "center", justifyContent: "space-evenly", display: "flex" }}>
                                                    <img className='poultry_ShedImg' src={data.shedImg} />
                                                    <h3>{shed.shedName}</h3>
                                                </div>
                                                <div style={{ flex: "0.5", display: "", justifyContent: "space-evenly", marginTop: "5px" }}>
                                                    <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                        <img className='poultry_Img' src={data.birdImg} />
                                                        <p>{shed.birdCount}</p>
                                                    </span>
                                                    <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                        <img className='poultry_Img' src={data.mortalityImg} />
                                                        <p style={{ color: reverseRenderColor(shed.mortality.actual, shed.mortality.minValue, shed.mortality.maxValue), textAlign: 'center' }}>
                                                            {shed.mortality.actual}
                                                        </p>
                                                    </span>
                                                </div>
                                            </div>
                                            <div style={{ display: 'grid', flex: "0.5", color: "black" }}>
                                                <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <img className='poultry_Img' src={data.eggImg} />
                                                    <p style={{ color: renderColor(shed.eggWeight.actual, shed.eggWeight.minValue, shed.eggWeight.maxValue), textAlign: 'center' }}>
                                                        {shed.eggWeight.actual}
                                                    </p>
                                                </span>
                                                <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <img className='poultry_Img' src={data.bodyImg} />
                                                    <p style={{ color: renderColor(shed.bodyWeight.actual, shed.bodyWeight.minValue, shed.bodyWeight.maxValue), textAlign: 'center' }}>
                                                        {shed.bodyWeight.actual}
                                                    </p>
                                                </span>
                                                <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <img className='poultry_Img' src={data.productionImg} />
                                                    <p style={{ color: renderColor(shed.production.actual, shed.production.minValue, shed.production.maxValue), textAlign: 'center' }}>
                                                        {shed.production.actual}
                                                    </p>
                                                </span>
                                            </div>
                                        </div>
                                    </Popover>
                                ))
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PoultryShed;