import React from 'react';
import { Table, Typography } from 'antd';

interface ProductionReportProps {
  report: any;
}

const ProductionReport: React.FC<ProductionReportProps> = ({ report }) => {
  const columns = [
    {
      title: 'Current Production Percentage',
      dataIndex: 'currentProductionPercentage',
      key: 'currentProductionPercentage',
    },
    {
      title: 'Standard Production Percentage',
      dataIndex: 'standardProductionPercentage',
      key: 'standardProductionPercentage',
    },
    {
      title: 'Minimum Production Percentage',
      dataIndex: 'minProductionPercentage',
      key: 'minProductionPercentage',
    },
    {
      title: 'Maximum Production Percentage',
      dataIndex: 'maxProductionPercentage',
      key: 'maxProductionPercentage',
    },
  ];

  const data = [
    {
      key: '1',
      currentProductionPercentage: `${report.currentProductionPercentage.toFixed(2)}%`,
      standardProductionPercentage: `${report.standardProductionPercentage.toFixed(2)}%`,
      minProductionPercentage: `${report.minProductionPercentage.toFixed(2)}% in 
      ${report.minWeek}`,
      maxProductionPercentage: `${report.maxProductionPercentage.toFixed(2)}% in 
      ${report.maxWeek}`,
    },
  ];

  return (
    <>
      <Typography.Title level={4}>Production Report</Typography.Title>
      <Table columns={columns} dataSource={data} pagination={false} />
    </>
  );
};

export default ProductionReport;
