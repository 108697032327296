// export const poultryShedData = [
//     {
//         shedImg: "Images/egg/shed-1.png",
//         birdImg: "Images/shed-images/chick.png",
//         eggImg: "Images/egg/egg2.png",
//         bodyImg: "Images/shed-images/chick-weight.png",
//         mortalityImg: "Images/shed-images/mortality.png ",
//         productionImg: "Images/shed-images/stack-of-eggs.png",

//         unit: [
//             {
//                 unitName: "Unit - 1",
//                 shedName: 1,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "12",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "65W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 1",
//                 shedName: 2,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 1",
//                 shedName: 3,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 1",
//                 shedName: 4,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 1",
//                 shedName: 5,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "17",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 1",
//                 shedName: 6,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {

//                 unitName: "Unit - 1",
//                 shedName: 7,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "11",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {

//                 unitName: "Unit - 1",
//                 shedName: 8,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {

//                 unitName: "Unit - 1",
//                 shedName: 9,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {

//                 unitName: "Unit - 1",
//                 shedName: 10,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },
//             {

//                 unitName: "Unit - 1",
//                 shedName: 11,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },
//             {

//                 unitName: "Unit - 1",
//                 shedName: 12,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },
//             {

//                 unitName: "Unit - 1",
//                 shedName: 13,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//         ],
//     },

//     {
//         shedImg: "Images/egg/shed-1.png",
//         birdImg: "Images/shed-images/chick.png",
//         eggImg: "Images/egg/egg2.png",
//         bodyImg: "Images/shed-images/chick-weight.png",
//         mortalityImg: "Images/shed-images/mortality.png ",
//         productionImg: "Images/shed-images/stack-of-eggs.png",
//         unit: [
//             {

//                 unitName: "Unit - 2",
//                 shedName: 14,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {

//                 unitName: "Unit - 2",
//                 shedName: 15,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {

//                 unitName: "Unit - 2",
//                 shedName: 16,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {

//                 unitName: "Unit - 2",
//                 shedName: 17,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {

//                 unitName: "Unit - 2",
//                 shedName: 18,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {

//                 unitName: "Unit - 2",
//                 shedName: 19,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {

//                 unitName: "Unit - 2",
//                 shedName: 20,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },
//             {
//                 unitName: "Unit - 2",
//                 shedName: 21,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 2",
//                 shedName: 22,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 2",
//                 shedName: 23,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "51",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 2",
//                 shedName: 24,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 2",
//                 shedName: 25,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 2",
//                 shedName: 26,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//         ],
//     },

//     {
//         shedImg: "Images/egg/shed-1.png",
//         birdImg: "Images/shed-images/chick.png",
//         eggImg: "Images/egg/egg2.png",
//         bodyImg: "Images/shed-images/chick-weight.png",
//         mortalityImg: "Images/shed-images/mortality.png ",
//         productionImg: "Images/shed-images/stack-of-eggs.png",
//         unit: [

//             {
//                 unitName: "Unit - 3",
//                 shedName: 27,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "5",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 3",
//                 shedName: 28,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 3",
//                 shedName: 29,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 3",
//                 shedName: 30,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },
//             {
//                 unitName: "Unit - 3",
//                 shedName: 31,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 3",
//                 shedName: 32,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 3",
//                 shedName: 33,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 3",
//                 shedName: 34,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 3",
//                 shedName: 35,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 3",
//                 shedName: 36,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 3",
//                 shedName: 37,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 3",
//                 shedName: 38,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 3",
//                 shedName: 39,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },
//         ],
//     },

//     {
//         shedImg: "Images/egg/shed-1.png",
//         birdImg: "Images/shed-images/chick.png",
//         eggImg: "Images/egg/egg2.png",
//         bodyImg: "Images/shed-images/chick-weight.png",
//         mortalityImg: "Images/shed-images/mortality.png ",
//         productionImg: "Images/shed-images/stack-of-eggs.png",
//         unit: [
//             {
//                 unitName: "Unit - 4",
//                 shedName: 40,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },
//             {
//                 unitName: "Unit - 4",
//                 shedName: 41,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 4",
//                 shedName: 42,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 4",
//                 shedName: 43,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 4",
//                 shedName: 44,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 4",
//                 shedName: 45,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 4",
//                 shedName: 46,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 4",
//                 shedName: 47,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 4",
//                 shedName: 48,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 4",
//                 shedName: 49,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 4",
//                 shedName: 50,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },
//             {
//                 unitName: "Unit - 4",
//                 shedName: 51,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },
//             {
//                 unitName: "Unit - 4",
//                 shedName: 52,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },
//         ],
//     },

//     {
//         shedImg: "Images/egg/shed-1.png",
//         birdImg: "Images/shed-images/chick.png",
//         eggImg: "Images/egg/egg2.png",
//         bodyImg: "Images/shed-images/chick-weight.png",
//         mortalityImg: "Images/shed-images/mortality.png ",
//         productionImg: "Images/shed-images/stack-of-eggs.png",
//         unit: [
//             {
//                 unitName: "Unit - 6",
//                 shedName: 53,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 6",
//                 shedName: 54,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 6",
//                 shedName: 55,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 6",
//                 shedName: 56,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 6",
//                 shedName: 57,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 6",
//                 shedName: 58,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 6",
//                 shedName: 59,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 6",
//                 shedName: 60,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },
//             {
//                 unitName: "Unit - 6",
//                 shedName: 61,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 6",
//                 shedName: 62,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 6",
//                 shedName: 63,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 6",
//                 shedName: 64,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 6",
//                 shedName: 65,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },
//         ],
//     },

//     {
//         shedImg: "Images/egg/shed-1.png",
//         birdImg: "Images/shed-images/chick.png",
//         eggImg: "Images/egg/egg2.png",
//         bodyImg: "Images/shed-images/chick-weight.png",
//         mortalityImg: "Images/shed-images/mortality.png ",
//         productionImg: "Images/shed-images/stack-of-eggs.png",
//         unit: [
//             {
//                 unitName: "Unit - 7",
//                 shedName: 66,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 7",
//                 shedName: 67,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 7",
//                 shedName: 68,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 7",
//                 shedName: 69,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 7",
//                 shedName: 70,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },
//             {
//                 unitName: "Unit - 7",
//                 shedName: 71,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 7",
//                 shedName: 72,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 7",
//                 shedName: 73,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 7",
//                 shedName: 74,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 7",
//                 shedName: 75,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 7",
//                 shedName: 76,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 7",
//                 shedName: 77,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 7",
//                 shedName: 78,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },


//         ],
//     },

//     {
//         shedImg: "Images/egg/shed-1.png",
//         birdImg: "Images/shed-images/chick.png",
//         eggImg: "Images/egg/egg2.png",
//         bodyImg: "Images/shed-images/chick-weight.png",
//         mortalityImg: "Images/shed-images/mortality.png ",
//         productionImg: "Images/shed-images/stack-of-eggs.png",
//         unit: [
//             {
//                 unitName: "Unit - 8",
//                 shedName: 79,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 8",
//                 shedName: 80,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },
//             {
//                 unitName: "Unit - 8",
//                 shedName: 81,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 8",
//                 shedName: 82,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 8",
//                 shedName: 83,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 8",
//                 shedName: 84,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 8",
//                 shedName: 85,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 8",
//                 shedName: 86,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 8",
//                 shedName: 87,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 8",
//                 shedName: 88,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 8",
//                 shedName: 89,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 8",
//                 shedName: 90,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },
//             {
//                 unitName: "Unit - 8",
//                 shedName: 91,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },
//         ],
//     },

//     {
//         shedImg: "Images/egg/shed-1.png",
//         birdImg: "Images/shed-images/chick.png",
//         eggImg: "Images/egg/egg2.png",
//         bodyImg: "Images/shed-images/chick-weight.png",
//         mortalityImg: "Images/shed-images/mortality.png ",
//         productionImg: "Images/shed-images/stack-of-eggs.png",
//         unit: [
//             {
//                 unitName: "Unit - 9",
//                 shedName: 92,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },
//             {
//                 unitName: "Unit - 9",
//                 shedName: 93,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 9",
//                 shedName: 94,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 9",
//                 shedName: 95,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 9",
//                 shedName: 96,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 9",
//                 shedName: 97,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 9",
//                 shedName: 98,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 9",
//                 shedName: 99,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },

//             {
//                 unitName: "Unit - 9",
//                 shedName: 100,
//                 birdCount: 550,
//                 eggWeight: {
//                     actual: "55",
//                     standard: "55",
//                 },
//                 bodyWeight: {
//                     actual: "15",
//                     standard: "15",
//                 },
//                 mortality: {
//                     actual: "55W",
//                     standard: "55W",
//                 },
//                 production: {
//                     actual: "100",
//                     standard: "100",
//                 },
//             },
//         ],
//     },

// ]













export const poultryShedData = [
    {
        shedImg: "Images/egg/shed-1.png",
        birdImg: "Images/shed-images/chick.png",
        eggImg: "Images/egg/egg2.png",
        bodyImg: "Images/shed-images/chick-weight.png",
        mortalityImg: "Images/shed-images/mortality.png ",
        productionImg: "Images/shed-images/stack-of-eggs.png",
        unit: [
            {
                "unitName": "Unit 1",
                "shedName": 1,
                "birdCount": 1000,
                "eggWeight": {
                    "actual": "28",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "1.8",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "95",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 1",
                "shedName": 2,
                "birdCount": 950,
                "eggWeight": {
                    "actual": "62",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "1.9",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "6",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "91",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 1",
                "shedName": 3,
                "birdCount": 900,
                "eggWeight": {
                    "actual": "65",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "1.85",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "4",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "93",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 1",
                "shedName": 4,
                "birdCount": 870,
                "eggWeight": {
                    "actual": "68",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "1.75",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "7",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "89",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 1",
                "shedName": 5,
                "birdCount": 880,
                "eggWeight": {
                    "actual": "70",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "1.95",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "94",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 1",
                "shedName": 6,
                "birdCount": 860,
                "eggWeight": {
                    "actual": "63",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "1.80",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "6",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "90",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 1",
                "shedName": 7,
                "birdCount": 890,
                "eggWeight": {
                    "actual": "61",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "1.85",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "4",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "88",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 1",
                "shedName": 8,
                "birdCount": 920,
                "eggWeight": {
                    "actual": "67",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "1.90",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "96",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 1",
                "shedName": 9,
                "birdCount": 850,
                "eggWeight": {
                    "actual": "64",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "1.80",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "3",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "91",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 1",
                "shedName": 10,
                "birdCount": 910,
                "eggWeight": {
                    "actual": "66",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "1.95",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "4",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "92",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                unitName: 'Unit 1',
                shedName: 11,
                birdCount: 910,
                eggWeight: {
                    actual: '68',
                    standard: '67',
                    minValue: "25",
                    maxValue: "60",
                },
                bodyWeight: {
                    actual: '2.1',
                    standard: '6.0',
                    minValue: "2.5",
                    maxValue: "5.8",
                },
                mortality: {
                    actual: '5',
                    standard: '8',
                    minValue: "2",
                    maxValue: "6",
                },
                production: {
                    actual: '93',
                    standard: '90',
                    minValue: "25",
                    maxValue: "60",
                },
            },
            {
                "unitName": "Unit 1",
                "shedName": 12,
                "birdCount": 940,
                "eggWeight": {
                    "actual": "68",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "1.80",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "6",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "90",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 1",
                "shedName": 13,
                "birdCount": 850,
                "eggWeight": {
                    "actual": "62",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "1.95",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "4",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "88",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            }
        ],
    },
    {
        shedImg: "Images/egg/shed-1.png",
        birdImg: "Images/shed-images/chick.png",
        eggImg: "Images/egg/egg2.png",
        bodyImg: "Images/shed-images/chick-weight.png",
        mortalityImg: "Images/shed-images/mortality.png ",
        productionImg: "Images/shed-images/stack-of-eggs.png",
        unit: [
            {
                "unitName": "Unit 2",
                "shedName": 1,
                "birdCount": 1100,
                "eggWeight": {
                    "actual": "68",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.1",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "93",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 2",
                "shedName": 2,
                "birdCount": 980,
                "eggWeight": {
                    "actual": "20",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.0",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "8",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "94",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 2",
                "shedName": 3,
                "birdCount": 1050,
                "eggWeight": {
                    "actual": "20",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.2",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "1",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "96",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 2",
                "shedName": 4,
                "birdCount": 1080,
                "eggWeight": {
                    "actual": "66",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.1",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "4",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "91",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 2",
                "shedName": 5,
                "birdCount": 1120,
                "eggWeight": {
                    "actual": "70",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.0",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "6",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "94",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 2",
                "shedName": 6,
                "birdCount": 1070,
                "eggWeight": {
                    "actual": "64",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.2",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "7",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "90",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 2",
                "shedName": 7,
                "birdCount": 1090,
                "eggWeight": {
                    "actual": "65",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.1",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "92",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 2",
                "shedName": 8,
                "birdCount": 1110,
                "eggWeight": {
                    "actual": "69",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.0",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "4",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "95",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 2",
                "shedName": 9,
                "birdCount": 1040,
                "eggWeight": {
                    "actual": "62",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.2",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "3",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "89",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 2",
                "shedName": 10,
                "birdCount": 1060,
                "eggWeight": {
                    "actual": "67",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.1",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "91",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 2",
                "shedName": 11,
                "birdCount": 1030,
                "eggWeight": {
                    "actual": "61",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.0",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "6",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "90",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 2",
                "shedName": 12,
                "birdCount": 1000,
                "eggWeight": {
                    "actual": "70",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.1",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "4",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "92",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 2",
                "shedName": 13,
                "birdCount": 1020,
                "eggWeight": {
                    "actual": "69",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.2",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "7",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "90",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            }
        ]

    },
    {
        shedImg: "Images/egg/shed-1.png",
        birdImg: "Images/shed-images/chick.png",
        eggImg: "Images/egg/egg2.png",
        bodyImg: "Images/shed-images/chick-weight.png",
        mortalityImg: "Images/shed-images/mortality.png ",
        productionImg: "Images/shed-images/stack-of-eggs.png",
        unit: [
            {
                "unitName": "Unit 3",
                "shedName": 1,
                "birdCount": 1150,
                "eggWeight": {
                    "actual": "66",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.3",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "4",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "91",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 3",
                "shedName": 2,
                "birdCount": 1080,
                "eggWeight": {
                    "actual": "64",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.2",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "92",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 3",
                "shedName": 3,
                "birdCount": 1120,
                "eggWeight": {
                    "actual": "65",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.3",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "6",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "93",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 3",
                "shedName": 4,
                "birdCount": 1100,
                "eggWeight": {
                    "actual": "67",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.1",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "3",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "90",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 3",
                "shedName": 5,
                "birdCount": 1050,
                "eggWeight": {
                    "actual": "68",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.4",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "92",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 3",
                "shedName": 6,
                "birdCount": 1130,
                "eggWeight": {
                    "actual": "63",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.2",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "4",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "91",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 3",
                "shedName": 7,
                "birdCount": 1170,
                "eggWeight": {
                    "actual": "70",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.3",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "6",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "95",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 3",
                "shedName": 8,
                "birdCount": 1190,
                "eggWeight": {
                    "actual": "66",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.2",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "3",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "94",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 3",
                "shedName": 9,
                "birdCount": 1010,
                "eggWeight": {
                    "actual": "62",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.1",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "89",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 3",
                "shedName": 10,
                "birdCount": 1040,
                "eggWeight": {
                    "actual": "64",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.3",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "6",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "92",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 3",
                "shedName": 11,
                "birdCount": 1060,
                "eggWeight": {
                    "actual": "68",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.4",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "4",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "93",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 3",
                "shedName": 12,
                "birdCount": 1090,
                "eggWeight": {
                    "actual": "67",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.2",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "91",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 3",
                "shedName": 13,
                "birdCount": 1020,
                "eggWeight": {
                    "actual": "69",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.3",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "6",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "90",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            }
        ]
    },
    {
        shedImg: "Images/egg/shed-1.png",
        birdImg: "Images/shed-images/chick.png",
        eggImg: "Images/egg/egg2.png",
        bodyImg: "Images/shed-images/chick-weight.png",
        mortalityImg: "Images/shed-images/mortality.png ",
        productionImg: "Images/shed-images/stack-of-eggs.png",
        unit: [
            {
                "unitName": "Unit 4",
                "shedName": 1,
                "birdCount": 1200,
                "eggWeight": {
                    "actual": "72",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.5",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "7",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "96",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 4",
                "shedName": 2,
                "birdCount": 1150,
                "eggWeight": {
                    "actual": "70",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.4",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "94",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 4",
                "shedName": 3,
                "birdCount": 1180,
                "eggWeight": {
                    "actual": "71",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.6",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "6",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "97",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 4",
                "shedName": 4,
                "birdCount": 1100,
                "eggWeight": {
                    "actual": "69",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.3",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "4",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "93",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 4",
                "shedName": 5,
                "birdCount": 1060,
                "eggWeight": {
                    "actual": "67",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.2",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "92",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 4",
                "shedName": 6,
                "birdCount": 1040,
                "eggWeight": {
                    "actual": "64",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.1",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "3",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "91",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 4",
                "shedName": 7,
                "birdCount": 1080,
                "eggWeight": {
                    "actual": "66",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.3",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "4",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "95",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 4",
                "shedName": 8,
                "birdCount": 1110,
                "eggWeight": {
                    "actual": "68",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.4",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "6",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "96",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 4",
                "shedName": 9,
                "birdCount": 1030,
                "eggWeight": {
                    "actual": "65",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.3",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "94",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 4",
                "shedName": 10,
                "birdCount": 1070,
                "eggWeight": {
                    "actual": "62",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.2",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "4",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "92",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 4",
                "shedName": 11,
                "birdCount": 1120,
                "eggWeight": {
                    "actual": "67",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.3",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "93",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 4",
                "shedName": 12,
                "birdCount": 1160,
                "eggWeight": {
                    "actual": "71",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.4",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "6",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "95",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 4",
                "shedName": 13,
                "birdCount": 1090,
                "eggWeight": {
                    "actual": "69",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.5",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "92",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            }
        ]
    },
    {
        shedImg: "Images/egg/shed-1.png",
        birdImg: "Images/shed-images/chick.png",
        eggImg: "Images/egg/egg2.png",
        bodyImg: "Images/shed-images/chick-weight.png",
        mortalityImg: "Images/shed-images/mortality.png ",
        productionImg: "Images/shed-images/stack-of-eggs.png",
        unit: [
            {
                "unitName": "Unit 5",
                "shedName": 1,
                "birdCount": 1300,
                "eggWeight": {
                    "actual": "65",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.6",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "4",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "97",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 5",
                "shedName": 2,
                "birdCount": 1250,
                "eggWeight": {
                    "actual": "68",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.7",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "6",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "95",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 5",
                "shedName": 3,
                "birdCount": 1280,
                "eggWeight": {
                    "actual": "63",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.5",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "94",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 5",
                "shedName": 4,
                "birdCount": 1220,
                "eggWeight": {
                    "actual": "66",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.4",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "7",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "93",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 5",
                "shedName": 5,
                "birdCount": 1240,
                "eggWeight": {
                    "actual": "67",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.6",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "96",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 5",
                "shedName": 6,
                "birdCount": 1270,
                "eggWeight": {
                    "actual": "64",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.7",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "6",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "95",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 5",
                "shedName": 7,
                "birdCount": 1290,
                "eggWeight": {
                    "actual": "68",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.8",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "97",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 5",
                "shedName": 8,
                "birdCount": 1200,
                "eggWeight": {
                    "actual": "69",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.6",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "4",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "94",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 5",
                "shedName": 9,
                "birdCount": 1230,
                "eggWeight": {
                    "actual": "67",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.5",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "7",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "92",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 5",
                "shedName": 10,
                "birdCount": 1190,
                "eggWeight": {
                    "actual": "66",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.4",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "6",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "93",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 5",
                "shedName": 11,
                "birdCount": 1210,
                "eggWeight": {
                    "actual": "70",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.6",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "95",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 5",
                "shedName": 12,
                "birdCount": 1300,
                "eggWeight": {
                    "actual": "72",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.7",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "6",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "97",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 5",
                "shedName": 13,
                "birdCount": 1260,
                "eggWeight": {
                    "actual": "68",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.5",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "4",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "94",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            }
        ]
    },
    {
        shedImg: "Images/egg/shed-1.png",
        birdImg: "Images/shed-images/chick.png",
        eggImg: "Images/egg/egg2.png",
        bodyImg: "Images/shed-images/chick-weight.png",
        mortalityImg: "Images/shed-images/mortality.png ",
        productionImg: "Images/shed-images/stack-of-eggs.png",
        unit: [
            {
                "unitName": "Unit 6",
                "shedName": 1,
                "birdCount": 1400,
                "eggWeight": {
                    "actual": "66",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.8",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "96",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 6",
                "shedName": 2,
                "birdCount": 1350,
                "eggWeight": {
                    "actual": "70",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.7",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "6",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "94",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 6",
                "shedName": 3,
                "birdCount": 1380,
                "eggWeight": {
                    "actual": "65",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.6",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "4",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "97",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 6",
                "shedName": 4,
                "birdCount": 1320,
                "eggWeight": {
                    "actual": "67",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.5",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "7",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "93",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 6",
                "shedName": 5,
                "birdCount": 1370,
                "eggWeight": {
                    "actual": "69",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.8",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "95",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 6",
                "shedName": 6,
                "birdCount": 1300,
                "eggWeight": {
                    "actual": "64",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.7",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "6",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "96",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 6",
                "shedName": 7,
                "birdCount": 1390,
                "eggWeight": {
                    "actual": "68",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.6",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "97",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 6",
                "shedName": 8,
                "birdCount": 1340,
                "eggWeight": {
                    "actual": "66",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.5",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "4",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "94",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 6",
                "shedName": 9,
                "birdCount": 1310,
                "eggWeight": {
                    "actual": "67",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.7",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "7",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "93",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 6",
                "shedName": 10,
                "birdCount": 1360,
                "eggWeight": {
                    "actual": "69",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.6",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "95",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 6",
                "shedName": 11,
                "birdCount": 1400,
                "eggWeight": {
                    "actual": "64",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.7",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "6",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "96",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 6",
                "shedName": 12,
                "birdCount": 1330,
                "eggWeight": {
                    "actual": "66",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.8",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "94",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 6",
                "shedName": 13,
                "birdCount": 1370,
                "eggWeight": {
                    "actual": "68",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.6",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "4",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "97",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            }
        ]
    },
    {
        shedImg: "Images/egg/shed-1.png",
        birdImg: "Images/shed-images/chick.png",
        eggImg: "Images/egg/egg2.png",
        bodyImg: "Images/shed-images/chick-weight.png",
        mortalityImg: "Images/shed-images/mortality.png ",
        productionImg: "Images/shed-images/stack-of-eggs.png",
        unit: [
            {
                "unitName": "Unit 7",
                "shedName": 1,
                "birdCount": 1450,
                "eggWeight": {
                    "actual": "62",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.9",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "4",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "98",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 7",
                "shedName": 2,
                "birdCount": 1400,
                "eggWeight": {
                    "actual": "68",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.8",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "94",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 7",
                "shedName": 3,
                "birdCount": 1420,
                "eggWeight": {
                    "actual": "63",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.7",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "6",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "95",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 7",
                "shedName": 4,
                "birdCount": 1460,
                "eggWeight": {
                    "actual": "60",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "3.0",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "97",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 7",
                "shedName": 5,
                "birdCount": 1410,
                "eggWeight": {
                    "actual": "66",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.8",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "4",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "96",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 7",
                "shedName": 6,
                "birdCount": 1430,
                "eggWeight": {
                    "actual": "65",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.9",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "97",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 7",
                "shedName": 7,
                "birdCount": 1480,
                "eggWeight": {
                    "actual": "62",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.8",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "6",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "95",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 7",
                "shedName": 8,
                "birdCount": 1390,
                "eggWeight": {
                    "actual": "67",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.7",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "4",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "96",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 7",
                "shedName": 9,
                "birdCount": 1450,
                "eggWeight": {
                    "actual": "68",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.9",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "94",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 7",
                "shedName": 10,
                "birdCount": 1440,
                "eggWeight": {
                    "actual": "63",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.8",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "6",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "97",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 7",
                "shedName": 11,
                "birdCount": 1470,
                "eggWeight": {
                    "actual": "61",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "3.0",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "96",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 7",
                "shedName": 12,
                "birdCount": 1380,
                "eggWeight": {
                    "actual": "66",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.7",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "4",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "98",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 7",
                "shedName": 13,
                "birdCount": 1410,
                "eggWeight": {
                    "actual": "67",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.8",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "95",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            }
        ]
    },
    {
        shedImg: "Images/egg/shed-1.png",
        birdImg: "Images/shed-images/chick.png",
        eggImg: "Images/egg/egg2.png",
        bodyImg: "Images/shed-images/chick-weight.png",
        mortalityImg: "Images/shed-images/mortality.png ",
        productionImg: "Images/shed-images/stack-of-eggs.png",
        unit: [
            {
                "unitName": "Unit 8",
                "shedName": 1,
                "birdCount": 1500,
                "eggWeight": {
                    "actual": "70",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "3.1",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "3",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "99",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 8",
                "shedName": 2,
                "birdCount": 1480,
                "eggWeight": {
                    "actual": "65",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "3.0",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "4",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "97",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 8",
                "shedName": 3,
                "birdCount": 1520,
                "eggWeight": {
                    "actual": "68",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.9",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "6",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "96",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 8",
                "shedName": 4,
                "birdCount": 1490,
                "eggWeight": {
                    "actual": "71",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "3.2",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "98",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 8",
                "shedName": 5,
                "birdCount": 1510,
                "eggWeight": {
                    "actual": "64",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "3.1",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "4",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "95",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 8",
                "shedName": 6,
                "birdCount": 1530,
                "eggWeight": {
                    "actual": "69",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.9",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "3",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "99",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 8",
                "shedName": 7,
                "birdCount": 1470,
                "eggWeight": {
                    "actual": "62",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "3.0",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "6",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "96",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 8",
                "shedName": 8,
                "birdCount": 1500,
                "eggWeight": {
                    "actual": "70",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "3.2",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "97",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 8",
                "shedName": 9,
                "birdCount": 1550,
                "eggWeight": {
                    "actual": "67",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "3.1",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "4",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "98",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 8",
                "shedName": 10,
                "birdCount": 1520,
                "eggWeight": {
                    "actual": "66",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "3.0",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "6",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "95",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 8",
                "shedName": 11,
                "birdCount": 1480,
                "eggWeight": {
                    "actual": "69",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "3.1",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "96",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 8",
                "shedName": 12,
                "birdCount": 1540,
                "eggWeight": {
                    "actual": "71",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "3.2",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "4",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "98",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 8",
                "shedName": 13,
                "birdCount": 1560,
                "eggWeight": {
                    "actual": "68",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "3.3",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "99",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            }
        ]
    },
    {
        shedImg: "Images/egg/shed-1.png",
        birdImg: "Images/shed-images/chick.png",
        eggImg: "Images/egg/egg2.png",
        bodyImg: "Images/shed-images/chick-weight.png",
        mortalityImg: "Images/shed-images/mortality.png ",
        productionImg: "Images/shed-images/stack-of-eggs.png",
        unit: [
            {
                "unitName": "Unit 9",
                "shedName": 1,
                "birdCount": 1400,
                "eggWeight": {
                    "actual": "63",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.8",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "94",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 9",
                "shedName": 2,
                "birdCount": 1380,
                "eggWeight": {
                    "actual": "67",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.7",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "4",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "95",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 9",
                "shedName": 3,
                "birdCount": 1420,
                "eggWeight": {
                    "actual": "60",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.9",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "6",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "96",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 9",
                "shedName": 4,
                "birdCount": 1450,
                "eggWeight": {
                    "actual": "62",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "3.0",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "94",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 9",
                "shedName": 5,
                "birdCount": 1470,
                "eggWeight": {
                    "actual": "65",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.8",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "4",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "97",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 9",
                "shedName": 6,
                "birdCount": 1430,
                "eggWeight": {
                    "actual": "64",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.7",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "6",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "95",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 9",
                "shedName": 7,
                "birdCount": 1500,
                "eggWeight": {
                    "actual": "66",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.9",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "3",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "98",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 9",
                "shedName": 8,
                "birdCount": 1460,
                "eggWeight": {
                    "actual": "61",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "3.1",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "96",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 9",
                "shedName": 9,
                "birdCount": 1510,
                "eggWeight": {
                    "actual": "68",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.8",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "4",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "97",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 9",
                "shedName": 10,
                "birdCount": 1520,
                "eggWeight": {
                    "actual": "62",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "3.0",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "94",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 9",
                "shedName": 11,
                "birdCount": 1490,
                "eggWeight": {
                    "actual": "64",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.9",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "6",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "95",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 9",
                "shedName": 12,
                "birdCount": 1540,
                "eggWeight": {
                    "actual": "66",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "3.0",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "4",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "96",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 9",
                "shedName": 13,
                "birdCount": 1570,
                "eggWeight": {
                    "actual": "69",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "3.1",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "98",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            }
        ]
    },
    {
        shedImg: "Images/egg/shed-1.png",
        birdImg: "Images/shed-images/chick.png",
        eggImg: "Images/egg/egg2.png",
        bodyImg: "Images/shed-images/chick-weight.png",
        mortalityImg: "Images/shed-images/mortality.png ",
        productionImg: "Images/shed-images/stack-of-eggs.png",
        unit: [
            {
                "unitName": "Unit 10",
                "shedName": 1,
                "birdCount": 1600,
                "eggWeight": {
                    "actual": "58",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.5",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "7",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "92",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 10",
                "shedName": 2,
                "birdCount": 1550,
                "eggWeight": {
                    "actual": "60",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.6",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "94",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 10",
                "shedName": 3,
                "birdCount": 1580,
                "eggWeight": {
                    "actual": "62",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.7",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "6",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "95",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 10",
                "shedName": 4,
                "birdCount": 1620,
                "eggWeight": {
                    "actual": "65",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.8",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "4",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "97",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 10",
                "shedName": 5,
                "birdCount": 1590,
                "eggWeight": {
                    "actual": "63",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.9",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "96",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 10",
                "shedName": 6,
                "birdCount": 1640,
                "eggWeight": {
                    "actual": "66",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "3.0",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "6",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "94",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 10",
                "shedName": 7,
                "birdCount": 1670,
                "eggWeight": {
                    "actual": "67",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "3.1",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "7",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "95",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 10",
                "shedName": 8,
                "birdCount": 1700,
                "eggWeight": {
                    "actual": "68",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "3.2",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "4",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "98",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 10",
                "shedName": 9,
                "birdCount": 1720,
                "eggWeight": {
                    "actual": "70",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "3.3",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "97",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 10",
                "shedName": 10,
                "birdCount": 1740,
                "eggWeight": {
                    "actual": "69",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "3.4",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "6",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "98",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 10",
                "shedName": 11,
                "birdCount": 1760,
                "eggWeight": {
                    "actual": "64",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "3.2",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "95",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 10",
                "shedName": 12,
                "birdCount": 1790,
                "eggWeight": {
                    "actual": "66",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "3.3",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "4",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "96",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 10",
                "shedName": 13,
                "birdCount": 1820,
                "eggWeight": {
                    "actual": "68",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "3.4",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "98",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            }
        ]
    },
    {
        shedImg: "Images/egg/shed-1.png",
        birdImg: "Images/shed-images/chick.png",
        eggImg: "Images/egg/egg2.png",
        bodyImg: "Images/shed-images/chick-weight.png",
        mortalityImg: "Images/shed-images/mortality.png ",
        productionImg: "Images/shed-images/stack-of-eggs.png",
        unit: [
            {
                "unitName": "Unit 11",
                "shedName": 1,
                "birdCount": 1450,
                "eggWeight": {
                    "actual": "56",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.4",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "7",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "91",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 11",
                "shedName": 2,
                "birdCount": 1500,
                "eggWeight": {
                    "actual": "58",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.5",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "6",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "92",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 11",
                "shedName": 3,
                "birdCount": 1550,
                "eggWeight": {
                    "actual": "60",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.6",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "94",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 11",
                "shedName": 4,
                "birdCount": 1600,
                "eggWeight": {
                    "actual": "62",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.7",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "4",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "95",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 11",
                "shedName": 5,
                "birdCount": 1650,
                "eggWeight": {
                    "actual": "64",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.8",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "6",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "96",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 11",
                "shedName": 6,
                "birdCount": 1700,
                "eggWeight": {
                    "actual": "66",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "2.9",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "97",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 11",
                "shedName": 7,
                "birdCount": 1750,
                "eggWeight": {
                    "actual": "68",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "3.0",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "6",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "98",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 11",
                "shedName": 8,
                "birdCount": 1800,
                "eggWeight": {
                    "actual": "70",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "3.1",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "4",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "96",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 11",
                "shedName": 9,
                "birdCount": 1850,
                "eggWeight": {
                    "actual": "72",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "3.2",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "98",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 11",
                "shedName": 10,
                "birdCount": 1900,
                "eggWeight": {
                    "actual": "74",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "3.3",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "6",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "95",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 11",
                "shedName": 11,
                "birdCount": 1950,
                "eggWeight": {
                    "actual": "76",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "3.4",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "5",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "96",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 11",
                "shedName": 12,
                "birdCount": 2000,
                "eggWeight": {
                    "actual": "78",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "3.5",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "6",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "97",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            },
            {
                "unitName": "Unit 11",
                "shedName": 13,
                "birdCount": 2050,
                "eggWeight": {
                    "actual": "80",
                    "standard": "67",
                    "minValue": "25",
                    "maxValue": "60"
                },
                "bodyWeight": {
                    "actual": "3.6",
                    "standard": "6.0",
                    "minValue": "2.5",
                    "maxValue": "5.8"
                },
                "mortality": {
                    "actual": "4",
                    "standard": "8",
                    "minValue": "2",
                    "maxValue": "6"
                },
                "production": {
                    "actual": "98",
                    "standard": "90",
                    "minValue": "25",
                    "maxValue": "60"
                }
            }
        ]
    },
    
];
