import { DualAxes, DualAxesConfig } from "@ant-design/plots";
import React, { useEffect, useState } from "react";
import { DatePicker, Select } from "antd";
import moment, { Moment } from "moment";
import "./LineChart.css";

export interface DualLineChartData {
  day: string;
  expenditures: number;
  revenue: number;
}

export interface DualLineChartProps {
  dualLineChartData: DualLineChartData[];
}

const DualLineChart = (props: DualLineChartProps) => {
  const [data, setData] = useState<DualLineChartData[]>([]);
  const [filteredData, setFilteredData] = useState<DualLineChartData[]>([]);
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);

  useEffect(() => {
    setData(props?.dualLineChartData);
  }, [props?.dualLineChartData]);

  useEffect(() => {
    if (startDate && endDate) {
      const filtered = data.filter((item) => {
        const itemDate = moment(item.day, "YYYY-MM-DD");
        return itemDate.isBetween(startDate, endDate, "day", "[]");
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  }, [startDate, endDate, data]);

  const handleDateChange = (dates: [Moment, Moment] | null) => {
    if (dates && dates.length === 2) {
      setStartDate(dates[0]);
      setEndDate(dates[1]);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const config: DualAxesConfig = {
    data: [filteredData, filteredData],
    xField: "day",
    yField: ["expenditures", "revenue"],
    legend: { position: "top" },
    geometryOptions: [
      {
        geometry: "line",
        lineStyle: {
          stroke: "#5B8FF9",
          lineWidth: 2,
          lineDash: [],
        },
      },
      {
        geometry: "line",
        lineStyle: {
          stroke: "#5AD8A6",
          lineWidth: 2,
          lineDash: [3, 5],
        },
      },
    ],
    yAxis: {
      expenditures: {
        title: { text: "Expenditures", style: { fill: "#5B8FF9" } },
      },
      revenue: {
        title: { text: "Revenue", style: { fill: "#5AD8A6" } },
        position: "right",
      },
    },
  };

  return (
    <div className="dual-line-chart-container_1">
      <p className="dual-line-chart-heading_1">Daily Expenditures and Revenue</p>
      <div
        className="controls_1"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <DatePicker.RangePicker
          format="YYYY-MM-DD"
          style={{ marginBottom: "-5px" }}
        />
        <div className="selector_1" style={{ marginLeft: "10px" }}>
          <Select defaultValue="Day" style={{ width: 120 }}>
            <Select.Option value="Day">Day </Select.Option>
            <Select.Option value="Week">Week</Select.Option>
            <Select.Option value="Month">Month </Select.Option>
            <Select.Option value="Year">Year</Select.Option>
          </Select>
        </div>
      </div>
      <DualAxes {...config} className="dual-line-chart_1" />
    </div>
  );
};

export default DualLineChart;
