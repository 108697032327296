import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import Dashboard from "../Poultry Project/Dashboard/dashboard";
import { UnitCodeView } from "../Poultry Project/masters/UnitCodeView";
import LayoutScreen from "../Poultry Project/LayoutScreen";
import UnitExcelUpload from "../Poultry Project/Dashboard/excel-upload/UnitExcelUpload";
import PoultryStandardsPage from "../Poultry Project/Dashboard/Polutry Standards/PoultryStandard";
import Upload from "../Poultry Project/Dashboard/components/Upload";
import { BusinessDashboard } from "../Poultry Project/Dashboard/business-dashboard/business-dashboard";

const MasterRoute = () => {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<LayoutScreen />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="dashboard">
            <Route path="production-dashboard" element={<Dashboard />} />
            <Route path="business-dashboard" element={<BusinessDashboard />} />
          </Route>
          <Route path="masters">
            <Route path="unitcode" element={<UnitCodeView />} />
          </Route>
          <Route path="data-capturing">
            <Route path="excelUpload" element={<UnitExcelUpload />} />
            <Route path="poultryStandard" element={<PoultryStandardsPage />} />
          </Route>
          <Route path="ai">
            <Route path="ai-excel" element={<Upload setRecommendations={function (recommendations: any): void {
              throw new Error("Function not implemented.");
            }} />} />
          </Route>

        </Route>
      </Routes>
    </HashRouter>
  );
};

export default MasterRoute;
