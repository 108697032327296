
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaWhatsapp, FaChartLine } from 'react-icons/fa';
import { Bar } from 'react-chartjs-2';
import './weather-app.css';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Input } from 'antd';

// Register the necessary components
Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
interface WeatherData {
  datetime: string;
  temp: number;
  weather: {
    description: string;
    icon: string;
  };
  city_name: string;
}

interface CityWeatherData {
  city_name: string;
  data: WeatherData[];
}

interface GraphData {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string;
    borderColor: string;
    borderWidth: number;
  }[];
}

function WeatherApp() {
  const [weatherData, setWeatherData] = useState<WeatherData[] | null>(null);
  const defaultDate = new Date(Date.now() + 2 * 24 * 60 * 60 * 1000)
    .toISOString()
    .split('T')[0];

  const [date, setDate] = useState<string>(defaultDate);
  const [cityData, setCityData] = useState<CityWeatherData[] | null>(null);
  const [selectedDay, setSelectedDay] = useState<WeatherData | null>(null);
  const [showGraph, setShowGraph] = useState<boolean>(false);
  const [graphData, setGraphData] = useState<GraphData | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (selectedDay) {
      updateWeatherBackground(selectedDay.weather.description);
    }
  }, [selectedDay]);

  function updateWeatherBackground(description: string) {
    const container = document.querySelector('.today-weather') as HTMLElement;

    container.classList.remove('sunny', 'cloudy', 'rainy');

    const existingRaindrops = container.querySelectorAll('.raindrop');
    existingRaindrops.forEach(raindrop => raindrop.remove());

    const existingClouds = container.querySelectorAll('.cloud');
    existingClouds.forEach(cloud => cloud.remove());

    if (description.includes('sunny')) {
      container.classList.add('sunny');
    } else if (description.includes('cloud')) {
      container.classList.add('cloudy');

      const cloud = document.createElement('div');
      cloud.className = 'cloud';
      container.appendChild(cloud);
    } else if (description.includes('rain')) {
      container.classList.add('rainy');
      const numberOfClouds = 1;
      for (let i = 0; i < numberOfClouds; i++) {
        const cloud = document.createElement('div');
        cloud.className = 'cloud';
        cloud.style.top = `${30 + (i * 50)}px`;
        container.appendChild(cloud);
      }
      const numberOfRaindrops = 100;
      for (let i = 0; i < numberOfRaindrops; i++) {
        const raindrop = document.createElement('div');
        raindrop.className = 'raindrop';
        raindrop.style.setProperty('--i', (i + 1).toString());

        const randomDelay = Math.random() * 2;
        raindrop.style.animationDelay = `${randomDelay}s`;

        container.appendChild(raindrop);
      }
    }
  }

  useEffect(() => {
    // if (navigator.geolocation) {
    fetchWeatherData(17.690474, 83.231049);

    //       navigator.geolocation.getCurrentPosition(
    //         (position) => {
    //         //   const { latitude, longitude } = position.coords;
    //         },
    //         (error) => {
    //           switch(error.code) {
    //             case error.PERMISSION_DENIED:
    //               console.error("User denied the request for Geolocation.");
    //               setError("Permission to access location was denied.");
    //               break;
    //             case error.POSITION_UNAVAILABLE:
    //               console.error("Location information is unavailable.");
    //               setError("Location information is unavailable.");
    //               break;
    //             case error.TIMEOUT:
    //               console.error("The request to get user location timed out.");
    //               setError("The request to get your location timed out.");
    //               break;
    //             default:
    //               console.error("An unknown error occurred:", error);
    //               setError("Unable to retrieve your location.");
    //               break;
    //           }
    //         }
    //       );
    //     } else {
    //       setError("Geolocation is not supported by this browser.");
    //     }
  }, []);


  const data: { [key: number]: { [key: string]: string }[] }[] = [
    { "20": [{ "breed1": "75" }, { "breed2": "26" }, { "breed3": "54" }, { "breed4": "88" }, { "breed5": "37" }, { "breed6": "42" }, { "breed7": "80" }, { "breed8": "65" }, { "breed9": "80" }, { "breed10": "26" }] },
    { "21": [{ "breed1": "40" }, { "breed2": "75" }, { "breed3": "36" }, { "breed4": "92" }, { "breed5": "65" }, { "breed6": "78" }, { "breed7": "53" }, { "breed8": "63" }, { "breed9": "57" }, { "breed10": "99" }] },
    { "22": [{ "breed1": "89" }, { "breed2": "100" }, { "breed3": "18" }, { "breed4": "47" }, { "breed5": "50" }, { "breed6": "52" }, { "breed7": "10" }, { "breed8": "47" }, { "breed9": "18" }, { "breed10": "99" }] },
    { "23": [{ "breed1": "13" }, { "breed2": "83" }, { "breed3": "27" }, { "breed4": "37" }, { "breed5": "20" }, { "breed6": "39" }, { "breed7": "38" }, { "breed8": "76" }, { "breed9": "99" }, { "breed10": "87" }] },
    { "24": [{ "breed1": "44" }, { "breed2": "83" }, { "breed3": "76" }, { "breed4": "34" }, { "breed5": "82" }, { "breed6": "49" }, { "breed7": "69" }, { "breed8": "33" }, { "breed9": "62" }, { "breed10": "10" }] },
    { "25": [{ "breed1": "98" }, { "breed2": "85" }, { "breed3": "11" }, { "breed4": "28" }, { "breed5": "52" }, { "breed6": "56" }, { "breed7": "38" }, { "breed8": "51" }, { "breed9": "69" }, { "breed10": "46" }] },
    { "26": [{ "breed1": "88" }, { "breed2": "86" }, { "breed3": "28" }, { "breed4": "78" }, { "breed5": "78" }, { "breed6": "52" }, { "breed7": "57" }, { "breed8": "90" }, { "breed9": "12" }, { "breed10": "55" }] },
    { "27": [{ "breed1": "24" }, { "breed2": "83" }, { "breed3": "78" }, { "breed4": "37" }, { "breed5": "96" }, { "breed6": "47" }, { "breed7": "29" }, { "breed8": "89" }, { "breed9": "24" }, { "breed10": "99" }] },
    { "28": [{ "breed1": "71" }, { "breed2": "42" }, { "breed3": "38" }, { "breed4": "63" }, { "breed5": "36" }, { "breed6": "77" }, { "breed7": "20" }, { "breed8": "80" }, { "breed9": "52" }, { "breed10": "100" }] },
    { "29": [{ "breed1": "47" }, { "breed2": "64" }, { "breed3": "42" }, { "breed4": "13" }, { "breed5": "77" }, { "breed6": "45" }, { "breed7": "36" }, { "breed8": "49" }, { "breed9": "77" }, { "breed10": "51" }] },
    { "30": [{ "breed1": "18" }, { "breed2": "40" }, { "breed3": "17" }, { "breed4": "71" }, { "breed5": "87" }, { "breed6": "49" }, { "breed7": "70" }, { "breed8": "21" }, { "breed9": "69" }, { "breed10": "74" }] },
    { "31": [{ "breed1": "23" }, { "breed2": "14" }, { "breed3": "40" }, { "breed4": "31" }, { "breed5": "27" }, { "breed6": "71" }, { "breed7": "19" }, { "breed8": "58" }, { "breed9": "69" }, { "breed10": "74" }] },
    { "32": [{ "breed1": "10" }, { "breed2": "83" }, { "breed3": "40" }, { "breed4": "83" }, { "breed5": "25" }, { "breed6": "89" }, { "breed7": "32" }, { "breed8": "67" }, { "breed9": "84" }, { "breed10": "19" }] },
    { "33": [{ "breed1": "67" }, { "breed2": "36" }, { "breed3": "10" }, { "breed4": "46" }, { "breed5": "97" }, { "breed6": "82" }, { "breed7": "22" }, { "breed8": "31" }, { "breed9": "61" }, { "breed10": "75" }] },
    { "34": [{ "breed1": "72" }, { "breed2": "45" }, { "breed3": "13" }, { "breed4": "70" }, { "breed5": "15" }, { "breed6": "17" }, { "breed7": "23" }, { "breed8": "89" }, { "breed9": "80" }, { "breed10": "85" }] },
    { "35": [{ "breed1": "38" }, { "breed2": "91" }, { "breed3": "25" }, { "breed4": "62" }, { "breed5": "13" }, { "breed6": "88" }, { "breed7": "13" }, { "breed8": "63" }, { "breed9": "78" }, { "breed10": "46" }] },
    { "36": [{ "breed1": "32" }, { "breed2": "60" }, { "breed3": "80" }, { "breed4": "27" }, { "breed5": "63" }, { "breed6": "30" }, { "breed7": "97" }, { "breed8": "60" }, { "breed9": "22" }, { "breed10": "71" }] },
    { "37": [{ "breed1": "79" }, { "breed2": "53" }, { "breed3": "40" }, { "breed4": "67" }, { "breed5": "58" }, { "breed6": "91" }, { "breed7": "44" }, { "breed8": "70" }, { "breed9": "28" }, { "breed10": "73" }] },
    { "38": [{ "breed1": "11" }, { "breed2": "32" }, { "breed3": "76" }, { "breed4": "87" }, { "breed5": "26" }, { "breed6": "40" }, { "breed7": "44" }, { "breed8": "19" }, { "breed9": "12" }, { "breed10": "60" }] },
    { "39": [{ "breed1": "57" }, { "breed2": "48" }, { "breed3": "83" }, { "breed4": "47" }, { "breed5": "84" }, { "breed6": "97" }, { "breed7": "10" }, { "breed8": "14" }, { "breed9": "50" }, { "breed10": "57" }] },
    { "40": [{ "breed1": "14" }, { "breed2": "83" }, { "breed3": "76" }, { "breed4": "71" }, { "breed5": "55" }, { "breed6": "62" }, { "breed7": "93" }, { "breed8": "19" }, { "breed9": "66" }, { "breed10": "45" }] },
    { "41": [{ "breed1": "31" }, { "breed2": "61" }, { "breed3": "34" }, { "breed4": "60" }, { "breed5": "20" }, { "breed6": "18" }, { "breed7": "81" }, { "breed8": "42" }, { "breed9": "66" }, { "breed10": "47" }] },
    { "42": [{ "breed1": "87" }, { "breed2": "14" }, { "breed3": "92" }, { "breed4": "32" }, { "breed5": "13" }, { "breed6": "39" }, { "breed7": "27" }, { "breed8": "71" }, { "breed9": "38" }, { "breed10": "39" }] },
    { "43": [{ "breed1": "60" }, { "breed2": "81" }, { "breed3": "91" }, { "breed4": "66" }, { "breed5": "49" }, { "breed6": "11" }, { "breed7": "60" }, { "breed8": "50" }, { "breed9": "28" }, { "breed10": "71" }] },
    { "44": [{ "breed1": "60" }, { "breed2": "37" }, { "breed3": "29" }, { "breed4": "35" }, { "breed5": "30" }, { "breed6": "57" }, { "breed7": "76" }, { "breed8": "67" }, { "breed9": "21" }, { "breed10": "57" }] },
    { "45": [{ "breed1": "32" }, { "breed2": "93" }, { "breed3": "84" }, { "breed4": "85" }, { "breed5": "83" }, { "breed6": "64" }, { "breed7": "95" }, { "breed8": "12" }, { "breed9": "13" }, { "breed10": "30" }] },
    { "46": [{ "breed1": "42" }, { "breed2": "31" }, { "breed3": "11" }, { "breed4": "99" }, { "breed5": "54" }, { "breed6": "25" }, { "breed7": "10" }, { "breed8": "50" }, { "breed9": "36" }, { "breed10": "45" }] },
    { "47": [{ "breed1": "77" }, { "breed2": "47" }, { "breed3": "66" }, { "breed4": "83" }, { "breed5": "29" }, { "breed6": "85" }, { "breed7": "33" }, { "breed8": "86" }, { "breed9": "99" }, { "breed10": "98" }] },
    { "48": [{ "breed1": "56" }, { "breed2": "90" }, { "breed3": "21" }, { "breed4": "82" }, { "breed5": "91" }, { "breed6": "21" }, { "breed7": "68" }, { "breed8": "32" }, { "breed9": "64" }, { "breed10": "53" }] },
    { "49": [{ "breed1": "96" }, { "breed2": "69" }, { "breed3": "12" }, { "breed4": "20" }, { "breed5": "64" }, { "breed6": "93" }, { "breed7": "24" }, { "breed8": "49" }, { "breed9": "88" }, { "breed10": "98" }] },
    { "50": [{ "breed1": "72" }, { "breed2": "94" }, { "breed3": "97" }, { "breed4": "58" }, { "breed5": "100" }, { "breed6": "16" }, { "breed7": "32" }, { "breed8": "62" }, { "breed9": "30" }, { "breed10": "91" }] },
    { "51": [{ "breed1": "65" }, { "breed2": "87" }, { "breed3": "21" }, { "breed4": "41" }, { "breed5": "16" }, { "breed6": "81" }, { "breed7": "57" }, { "breed8": "28" }, { "breed9": "85" }, { "breed10": "96" }] },
    { '52': [{ 'breed1': '56' }, { 'breed2': '40' }, { 'breed3': '100' }, { 'breed4': '95' }, { 'breed5': '73' }, { 'breed6': '25' }, { 'breed7': '30' }, { 'breed8': '55' }, { 'breed9': '80' }, { 'breed10': '47' }] },
    { '53': [{ 'breed1': '31' }, { 'breed2': '38' }, { 'breed3': '22' }, { 'breed4': '46' }, { 'breed5': '82' }, { 'breed6': '42' }, { 'breed7': '20' }, { 'breed8': '61' }, { 'breed9': '93' }, { 'breed10': '57' }] },
    { '54': [{ 'breed1': '52' }, { 'breed2': '21' }, { 'breed3': '34' }, { 'breed4': '45' }, { 'breed5': '97' }, { 'breed6': '99' }, { 'breed7': '39' }, { 'breed8': '47' }, { 'breed9': '14' }, { 'breed10': '43' }] }

  ];

  const cities: string[] = [
    "New York","Los Angeles","Chicago", "Houston", "Phoenix", "Philadelphia",
    // "San Antonio", "San Diego", "Dallas", "San Jose", "Austin", "Jacksonville",
    // "Fort Worth", "Columbus", "San Francisco", "Charlotte", "Indianapolis",
    // "Seattle", "Denver", "Washington"
  ];

  const apiKey = "55f5ab2e144704e42c8bf3522249b921";
  function prodFind(temp: number, type: string | boolean): any {
    for (const entry of data) {
      if (entry.hasOwnProperty(temp)) {
        const value = entry[temp];
        let minKey = "";
        let minValue = Infinity;
        let maxKey = "";
        let maxValue = -Infinity;

        for (const item of value) {
          for (const key in item) {
            const itemValue = parseInt(item[key]);
            if (!isNaN(itemValue)) {
              if (itemValue < minValue) {
                minValue = itemValue;
                minKey = key;
              }
              if (itemValue > maxValue) {
                maxValue = itemValue;
                maxKey = key;
              }
            }
          }
        }
        if (type === "minOnly") {
          return minValue;
        }
        return type ? `↓(${minKey}): ${minValue}` : `↑(${maxKey}): ${maxValue}`;
      }
    }
    return 0; // Default value if nothing is found
  }


  const handleDateChange = (selectedDate: string) => {
    const selectedDay = weatherData?.find(day => day.datetime === selectedDate);

    if (selectedDay) {
      setDate(selectedDate);

      const updatedWeatherData = [...(weatherData || [])];
      updatedWeatherData[2] = selectedDay;
      setWeatherData(updatedWeatherData);
      setSelectedDay(selectedDay);

      for (const city of cityData || []) {
        const cityDay = city.data.find(day => day.datetime === selectedDate);

        if (cityDay) {
          if (Array.isArray(city.data)) {
            city.data.splice(2, 0, cityDay);
          } else {
            console.error('cityData is not an array');
          }
        } else {
          console.log("not working");
        }
      }
    } else {
      alert("No weather data found for the selected date.");
    }
  };

  const fetchWeatherData = async (latitude: number, longitude: number) => {
    const forecastURL = `https://api.openweathermap.org/data/2.5/forecast`;

    try {
      const cityArray: CityWeatherData[] = [];

      for (const city of cities) {
        try {
          const { data: cityData } = await axios.get(forecastURL, {
            params: {
              q: city,
              appid: apiKey,
              units: "metric",
            },
          });

          const cityForecast = cityData.list.map((entry: any) => ({
            datetime: entry.dt_txt,
            temp: entry.main.temp,
            weather: {
              description: entry.weather[0].description,
              icon: entry.weather[0].icon,
            },
            city_name: city,
          }));

          cityArray.push({
            city_name: city,
            data: cityForecast,
          });
        } catch (error) {
          console.error(`Error fetching weather data for ${city}:`, error);
        }
      }

      setCityData(cityArray);

      const { data: initialData } = await axios.get(forecastURL, {
        params: {
          lat: latitude,
          lon: longitude,
          appid: apiKey,
          units: "metric",
        },
      });

      const locationForecast = initialData.list.map((entry: any) => ({
        datetime: entry.dt_txt,
        temp: entry.main.temp,
        weather: {
          description: entry.weather[0].description,
          icon: entry.weather[0].icon,
        },
        city_name: "Your Location",
      }));

      setWeatherData(locationForecast);
      setSelectedDay(locationForecast[0]);
    } catch (error) {
      console.error("Error fetching weather data:", error);
      setError("Error fetching weather data.");
    }
  };
  const handleDayClick = (day: WeatherData) => {
    setSelectedDay(day);
  };

  const handleCityClick = (city: WeatherData) => {
    setSelectedDay(city);
  };

  const sendWhatsAppReport = (day: WeatherData) => {
    const message = `Weather report for ${new Date(day.datetime).toLocaleDateString("en-US")}:\nTemperature: ${day.temp}°C\nCondition: ${day.weather.description} in ${day.city_name} and this weather is not good for ${prodFind(Math.floor(selectedDay?.temp || 0), true)} this weather is good for  ${prodFind(Math.floor(selectedDay?.temp || 0), false)} `;
    const phoneNumber = "9676181159";
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, "_blank");
  };

  const handleGraphClick = () => {
    const tempFloor = Math.floor(selectedDay?.temp || 0);
    const breedData = data.find((entry) => entry[tempFloor]);
    if (breedData) {
      const labels = Object.keys(breedData[tempFloor]);
      const values = Object.values(breedData[tempFloor]).map((val) =>
        parseInt(val[Object.keys(val)[0]])
      );

      setGraphData({
        labels: labels,
        datasets: [
          {
            label: `Breed data for ${tempFloor}°C`,
            data: values,
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
          },
        ],
      });
      setShowGraph(true);
    }
  };

  return (
    <div className="WeatherApp">
      {error ? (
        <p>{error}</p>
      ) : weatherData && selectedDay ? (
        <>
          <div className="today-weather">
            <p className="location">
              <strong>{selectedDay.city_name}</strong>
            </p>
            <div className='selectedData'>
              <div>
                <img
                  src={`http://openweathermap.org/img/wn/${selectedDay.weather.icon}.png`}
                  alt={selectedDay.weather.description}
                  className="todayImg"
                />
                <p>{selectedDay.temp}°C</p>
                <p>{selectedDay.weather.description}</p>
              </div>
              <div >
                <FaWhatsapp
                  className="whatsapp-icon"
                  onClick={() => sendWhatsAppReport(selectedDay)}
                />
                <p>
                  {new Date(selectedDay.datetime).toLocaleDateString("en-US", {
                    weekday: "long",
                  })}
                </p>
                <p>
                  {new Date(selectedDay.datetime).toLocaleDateString("en-US", {
                    month: "long",
                    day: "numeric",
                  })}
                </p>
              </div>
              <div className="setwidth">
                <button className="Line-Chart" onClick={handleGraphClick}>
                  <FaChartLine size={20} />
                </button>
                <p style={{ color: 'red' }}>Bad For :{prodFind(Math.floor(selectedDay.temp), true)}</p>
                <p style={{ color: 'green' }}>Good For :{prodFind(Math.floor(selectedDay.temp), false)}</p>
              </div>
            </div>
          </div>

          <div className="forecast">
            {weatherData.map((day, index) => (
              index < 3 && (
                <div key={index} className="localCityCon">
                  {index === 2 ? (
                    <>
                      <p className='forecast_date'>
                        {new Date(date).toLocaleDateString('en-US', {
                          weekday: 'short',
                        })}
                      </p>
                      <Input
                        type="date" value={date} onChange={(e) => handleDateChange(e.target.value)}
                      />
                    </>
                  ) : (
                    <>
                      <p className='forecast_date'>
                        {new Date(day.datetime).toLocaleDateString("en-US", {
                          weekday: "short",
                        })}
                      </p>
                      <p className='forecast_date-1' >{new Date(day.datetime).toLocaleDateString("en-US")}</p>
                    </>
                  )}

                  <div
                    onClick={() => handleDayClick(day)}
                    className={`day ${prodFind(Math.floor(day.temp), "minOnly") < 20 ? "red-bg" : ""}`}
                  >
                    <p className="cityNamebg">
                      <strong>Your Location</strong></p>
                    <div className="iconCon">
                      <img
                        src={`http://openweathermap.org/img/wn/${day.weather.icon}.png`}
                        alt={day.weather.description}
                        className="weatherIcon"
                      />
                      <p>{day.temp}°C</p>
                    </div>
                    <p className='desP'>{day.weather.description}</p>
                  </div>

                  <div className="city">
                    {cityData &&
                      cityData.map((city, cityIndex) => (
                        <div
                          key={cityIndex}
                          className={`respcity ${prodFind(Math.floor(city.data[index].temp), "minOnly") < 20 ? "red-bg" : ""}`}
                          onClick={() => handleCityClick(city.data[index])}
                          style={{ backgroundColor: "white" }}
                        >
                          <p className='cityNamebg'>
                            <strong>{city.city_name}</strong>
                          </p>
                          <div className="iconCon">
                            <img
                              src={`http://openweathermap.org/img/wn/${city.data[index].weather.icon}.png`}
                              alt={city.data[index].weather.description}
                              className="weatherIcon"
                            />
                            <p>{city.data[index].temp}°C</p>
                          </div>
                          <p className='desP' >{city.data[index].weather.description}</p>
                        </div>
                      ))}
                  </div>
                </div>
              )
            ))}
          </div>

          {showGraph && (
            <div className="graph-container">
              <button onClick={() => setShowGraph(false)}>Close Graph</button>
              <Bar data={graphData as GraphData} width={500} height={400} />
            </div>
          )}
        </>
      ) : (
        <p>Loading weather data...</p>
      )}
    </div>
  );
}

export default WeatherApp;
