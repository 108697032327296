import React, { useState } from "react";
import "./BusinessStatic.css";
import { dashboardData } from "./BusinessStaticData";

type Period = "Day" | "Week" | "Monthly" | "Yearly";

const BusinessStatic: React.FC = () => {
  const [period, setPeriod] = useState<Period>("Day");

  const data = dashboardData[period];

  return (
    <div className="dashboard-container_1">
      <div className="header_1">
        <div className="title-section_1">
          <img
            className="icon_1"
            src="/Images/BusinessStaticIcons/pie-chart.png"
            alt="Pie Chart Icon"
          />
          <div className="title_1">
            <h2>Dashwise</h2>
            <h3>Farm Management Dashboard 2024</h3>
          </div>
        </div>
      </div>
      <div className="statistics-overview_1">
        <div className="overview-header_1">
          <h3>Static Overview</h3>
          <div className="button-group_1">
            <button
              className={`toggle-button_1 ${period === "Day" ? "active" : ""}`}
              onClick={() => setPeriod("Day")}
            >
              Day
            </button>
            <button
              className={`toggle-button_1 ${period === "Week" ? "active" : ""}`}
              onClick={() => setPeriod("Week")}
            >
              Week
            </button>
            <button
              className={`toggle-button_1 ${
                period === "Monthly" ? "active" : ""
              }`}
              onClick={() => setPeriod("Monthly")}
            >
              Monthly
            </button>
            <button
              className={`toggle-button_1 ${period === "Yearly" ? "active" : ""}`}
              onClick={() => setPeriod("Yearly")}
            >
              Yearly
            </button>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: "20px",
          }}
        >
          <div className="stat-card-content_1">
            <img
              className="icon_1"
              src={data.netProfit.icon}
              alt="Net Profit Icon"
            />
            <div className="stat-card-content_1">
              <h4>Net Profit</h4>
              <p>₹{data.netProfit.amount.toLocaleString()}</p>
            </div>
          </div>
          <div className="stat-card-content_1">
            <img
              className="icon_1"
              src={data.expenditures.icon}
              alt="Expenditures Icon"
            />
            <div className="stat-card-content_1">
              <h4>Expenditures</h4>
              <p>₹{data.expenditures.amount.toLocaleString()}</p>
            </div>
          </div>
          <div className="stat-card-content_1">
            <img
              className="icon_1"
              src={data.operationalProfit.icon}
              alt="Operational Profit Icon"
            />
            <div className="stat-card-content_1">
              <h4>Operational Profit</h4>
              <p>₹{data.operationalProfit.amount.toLocaleString()}</p>
            </div>
          </div>
          <div className="stat-card-content_1">
            <img className="icon_1" src={data.revenue.icon} alt="Revenue Icon" />
            <div className="stat-card-content_1">
              <h4>Revenue</h4>
              <p>₹{data.revenue.amount.toLocaleString()}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="expenses-overview_1">
        <ul>
          {data.expenses.map((expense, index) => (
            <li key={index}>
              <span className="amount">₹{expense.amount.toLocaleString()}</span>
              <span>{expense.name}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default BusinessStatic;
