// import { DualAxes, DualAxesConfig } from '@ant-design/plots';
// import './root-cause-analysis-chart.css';
// import React from 'react';

// export interface RootCauseAnalysisObj {
//     unitName: string;
//     production: {
//         productionRate: string;
//         productionStandardValue: string;
//     };
//     mortality: {
//         mortalityRate: string;
//         mortalityStandardValue: string;
//     };
//     damage: {
//         damageRate: string;
//         damageStandardValue: string;
//     };
//     soiled: {
//         soiledRate: string;
//         soiledStandardValue: string;
//     };
// }

// export interface RootCauseAnalysisProps {
//     rootCauseAnalysisData: RootCauseAnalysisObj[];
// }

// const RootCauseAnalysisChart: React.FC<RootCauseAnalysisProps> = ({ rootCauseAnalysisData }) => {
//     const columnData = rootCauseAnalysisData.flatMap((item) => [
//         { type: 'Production Rate', unitName: item.unitName, value: parseFloat(item.production.productionRate) },
//         { type: 'Mortality Rate', unitName: item.unitName, value: parseFloat(item.mortality.mortalityRate) },
//         { type: 'Damage Rate', unitName: item.unitName, value: parseFloat(item.damage.damageRate) },
//         { type: 'Soiled Rate', unitName: item.unitName, value: parseFloat(item.soiled.soiledRate) },
//     ]);

//     const lineData = rootCauseAnalysisData.flatMap((item) => [
//         { type: 'Production Standard', unitName: item.unitName, value: parseFloat(item.production.productionStandardValue) },
//         { type: 'Mortality Standard', unitName: item.unitName, value: parseFloat(item.mortality.mortalityStandardValue) },
//         { type: 'Damage Standard', unitName: item.unitName, value: parseFloat(item.damage.damageStandardValue) },
//         { type: 'Soiled Standard', unitName: item.unitName, value: parseFloat(item.soiled.soiledStandardValue) },
//     ]);

//     const config: DualAxesConfig = {
//         data: [columnData, lineData],
//         xField: 'unitName',
//         yField: ['value', 'value'],
//         geometryOptions: [
//             {
//                 geometry: 'column',
//                 seriesField: 'type',
//                 isGroup: true,
//                 color: ['#5B8FF9', 'orange', '#5AD8A6', '#FF7A45'],
//                 label: {
//                     position: 'middle',
//                 },
//                 columnStyle:{
//                     columnWidth:10
//                 }
//             },
//             {
//                 geometry: 'line',
//                 seriesField: 'type',
//                 lineStyle: {
//                     lineWidth: 2,
//                     lineDash: [4, 4],
//                 },
//                 smooth: true,
//             },
//         ],
//         yAxis: [
//             {
//                 title: {
//                     text: 'Value',
//                     style: {
//                         fontSize: 14,
//                     },
//                 },
//                 min: 0,
//                 max: Math.max(
//                     Math.max(...columnData.map(d => d.value)),
//                     Math.max(...lineData.map(d => d.value))
//                 ),
//             },
//             {
//                 // title: {
//                 //     // text: 'Value',
//                 //     style: {
//                 //         fontSize: 14,
//                 //     },
//                 // },
//                 min: 0,
//                 max: Math.max(
//                     Math.max(...columnData.map(d => d.value)),
//                     Math.max(...lineData.map(d => d.value))
//                 ),
//             },
//         ],
//         legend: {
//             position: 'bottom',
//             layout: 'horizontal',
//         },
//     };

//     return (
//         <div className='RCA-chart-container' >
//             <p className='RCA-chart-heading' > Root Cause Analysis </p>
//             <DualAxes className='RCA-chart' {...config} />;
//         </div>
//     )
// };

// export default RootCauseAnalysisChart;












// import React, { useState } from 'react';
// import { DualAxes, DualAxesConfig } from '@ant-design/plots';
// import './root-cause-analysis-chart.css';

// export interface RootCauseAnalysisObj {
//     unitName: string;
//     vaccination: {
//         VaccinationDeviationRate: string;
//         vaccinationStandardValue: string;
//     };
//     mortality: {
//         mortalityRate: string;
//         mortalityStandardValue: string;
//     };
//     damage: {
//         damageRate: string;
//         damageStandardValue: string;
//     };
//     soiled: {
//         soiledRate: string;
//         soiledStandardValue: string;
//     };
// }

// export interface RootCauseAnalysisProps {
//     rootCauseAnalysisData: RootCauseAnalysisObj[];
// }

// const RootCauseAnalysisChart: React.FC<RootCauseAnalysisProps> = ({ rootCauseAnalysisData }) => {
//     const [isFullscreen, setIsFullscreen] = useState(false);

//     const toggleFullscreen = () => {
//         setIsFullscreen(!isFullscreen);
//     };

//     const columnData = rootCauseAnalysisData.flatMap((item) => [
//         { type: 'Vaccination Rate', unitName: item.unitName, value: parseFloat(item.vaccination.vaccinationRate) },
//         { type: 'Mortality Rate', unitName: item.unitName, value: parseFloat(item.mortality.mortalityRate) },
//         { type: 'Damage Rate', unitName: item.unitName, value: parseFloat(item.damage.damageRate) },
//         { type: 'Soiled Rate', unitName: item.unitName, value: parseFloat(item.soiled.soiledRate) },
//     ]);

//     const lineData = rootCauseAnalysisData.flatMap((item) => [
//         { type: 'vaccination Standard', unitName: item.unitName, value: parseFloat(item.vaccination.vaccinationStandardValue) },
//         { type: 'Mortality Standard', unitName: item.unitName, value: parseFloat(item.mortality.mortalityStandardValue) },
//         { type: 'Damage Standard', unitName: item.unitName, value: parseFloat(item.damage.damageStandardValue) },
//         { type: 'Soiled Standard', unitName: item.unitName, value: parseFloat(item.soiled.soiledStandardValue) },
//     ]);

//     const config: DualAxesConfig = {
//         data: [columnData, lineData],
//         xField: 'unitName',
//         yField: ['value', 'value'],
//         geometryOptions: [
//             {
//                 geometry: 'column',
//                 seriesField: 'type',
//                 isGroup: true,
//                 color: ['#5B8FF9', 'orange', '#5AD8A6', '#FF7A45'],
//                 label: {
//                     position: 'middle',
//                 },
//                 columnStyle:{
//                     columnWidth:10
//                 }
//             },
//             {
//                 geometry: 'line',
//                 seriesField: 'type',
//                 lineStyle: {
//                     lineWidth: 2,
//                     lineDash: [4, 4],
//                 },
//                 smooth: true,
//             },
//         ],
//         yAxis: [
//             {
//                 title: {
//                     text: 'Value',
//                     style: {
//                         fontSize: 14,
//                     },
//                 },
//                 min: 0,
//                 max: Math.max(
//                     Math.max(...columnData.map(d => d.value)),
//                     Math.max(...lineData.map(d => d.value))
//                 ),
//             },
//             {
//                 min: 0,
//                 max: Math.max(
//                     Math.max(...columnData.map(d => d.value)),
//                     Math.max(...lineData.map(d => d.value))
//                 ),
//             },
//         ],
//         legend: {
//             position: 'top',
//             layout: 'horizontal',
//         },
//     };

//     return (
//         <div className={`RCA-chart-container ${isFullscreen ? 'fullscreen' : ''}`}>
//             <p className='RCA-chart-heading'>Root Cause Analysis</p>
//             <button className='fullscreen-button' onClick={toggleFullscreen}>
//                 {isFullscreen ? 'Exit Fullscreen' : 'Fullscreen'}
//             </button>
//             <DualAxes className='RCA-chart' {...config} />
//         </div>
//     );
// };

// export default RootCauseAnalysisChart;




















import React, { useState } from 'react';
import { DualAxes, DualAxesConfig } from '@ant-design/plots';
import './root-cause-analysis-chart.css';
import { Button } from 'antd';

export interface RootCauseAnalysisObj {
    unitName: string;
    vaccination: {
        VaccinationDeviationRate: string;
        vaccinationStandardValue: string;
        vaccinationMinValue: string;
        vaccinationMaxValue: string;
    };
    mortality: {
        mortalityRate: string;
        mortalityStandardValue: string;
        mortalityMinValue: string;
        mortalityMaxValue: string;
    };
    damage: {
        damageRate: string;
        damageStandardValue: string;
        damageMinValue: string;
        damageMaxValue: string;
    };
    feedIntake:{
        feedIntakeRate:string;
        feedIntakeStandardValue:string;
        feedIntakeMinValue:string;
        feedIntakeMaxValue:string;
    }
}

export interface RootCauseAnalysisProps {
    rootCauseAnalysisData: RootCauseAnalysisObj[];
}

const RootCauseAnalysisChart: React.FC<RootCauseAnalysisProps> = ({ rootCauseAnalysisData }) => {
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [activeFilter, setActiveFilter] = useState<'red' | 'yellow' | 'green' | 'all'>('all');

    const toggleFullscreen = () => {
        setIsFullscreen(!isFullscreen);
    };

    const parsePercentage = (percentage: string) => parseFloat(percentage.replace('%', ''));

    const getColor = (value: number, minValue: number, maxValue: number) => {
        if (value < minValue) {
            return 'red';
        } else if (value >= minValue && value <= maxValue) {
            return 'yellow';
        } else {
            return 'green';
        }
    };

    const columnData = rootCauseAnalysisData.flatMap((item) => [
        {
            type: 'Vaccination Deviation Rate',
            unitName: item.unitName,
            value: parsePercentage(item.vaccination.VaccinationDeviationRate),
            minValue: parsePercentage(item.vaccination.vaccinationMinValue),
            maxValue: parsePercentage(item.vaccination.vaccinationMaxValue),
        },
        {
            type: 'Mortality Rate',
            unitName: item.unitName,
            value: parsePercentage(item.mortality.mortalityRate),
            minValue: parsePercentage(item.mortality.mortalityMinValue),
            maxValue: parsePercentage(item.mortality.mortalityMaxValue),
        },
        {
            type: 'Damage Rate',
            unitName: item.unitName,
            value: parsePercentage(item.damage.damageRate),
            minValue: parsePercentage(item.damage.damageMinValue),
            maxValue: parsePercentage(item.damage.damageMaxValue),
        },
        {
            type: 'Feed Intake Rate',
            unitName: item.unitName,
            value: parsePercentage(item.feedIntake.feedIntakeRate),
            minValue:parsePercentage(item.feedIntake.feedIntakeMinValue),
            maxValue:parsePercentage(item.feedIntake.feedIntakeMaxValue),
        }
    ]);

    const lineData = rootCauseAnalysisData.flatMap((item) => [
        {
            type: 'Vaccination Standard',
            unitName: item.unitName,
            value: parsePercentage(item.vaccination.vaccinationStandardValue),
        },
        {
            type: 'Mortality Standard',
            unitName: item.unitName,
            value: parsePercentage(item.mortality.mortalityStandardValue),
        },
        {
            type: 'Damage Standard',
            unitName: item.unitName,
            value: parsePercentage(item.damage.damageStandardValue),
        },
        {
            type: 'Feed Intake Standard',
            unitName: item.unitName,
            value: parsePercentage(item.feedIntake.feedIntakeStandardValue),
        },
    ]);

    const filteredColumnData = columnData.filter((item) => {
        if (activeFilter === 'all') return true;
        const { value, minValue, maxValue } = item;
        if (activeFilter === 'red') return value < minValue;
        if (activeFilter === 'yellow') return value >= minValue && value <= maxValue;
        if (activeFilter === 'green') return value > maxValue;
        return false;
    });

    const config: DualAxesConfig = {
        data: [filteredColumnData, lineData],
        xField: 'unitName',
        yField: ['value', 'value'],
        geometryOptions: [
            {
                geometry: 'column',
                seriesField: 'type',
                isGroup: true,
                color: ['#d4a373', '#2a9d8f', '#48cae4', '#e29578', 'skyBlue'],
                label: {
                    position: 'middle',
                },
                columnStyle: {
                    columnWidth: 10,
                },
            },
            {
                geometry: 'line',
                seriesField: 'type',
                color: ['#d4a373', '#2a9d8f', '#48cae4', '#e29578', 'skyBlue'],
                lineStyle: {
                    lineWidth: 2,
                    lineDash: [4, 4],
                },
                smooth: true,
            },
        ],
        yAxis: [
            {
                title: {
                    text: 'Value',
                    style: {
                        fontSize: 14,
                    },
                },
                min: 0,
                max: Math.max(
                    Math.max(...columnData.map((d) => d.value)),
                    Math.max(...lineData.map((d) => d.value))
                ),
            },
            {
                min: 0,
                max: Math.max(
                    Math.max(...columnData.map((d) => d.value)),
                    Math.max(...lineData.map((d) => d.value))
                ),
            },
        ],
        legend: {
            position: 'top',
            layout: 'horizontal',
        },
    };

    return (
        <div className={`RCA-chart-container ${isFullscreen ? 'fullscreen' : ''}`}>
            <button className='fullscreen-button' onClick={toggleFullscreen}>
                {isFullscreen ? 'Exit Fullscreen' : 'Fullscreen'}
            </button>

            <div className='filter-buttons'>
                <Button style={{ backgroundColor: "#cf505d" }}
                    className={`filter-button ${activeFilter === 'red' ? 'active' : ''}`}
                    onClick={() => setActiveFilter('red')}
                >
                    Red Zone
                </Button>
                <Button style={{ backgroundColor: "#d6ad53" }}
                    className={`filter-button ${activeFilter === 'yellow' ? 'active' : ''}`}
                    onClick={() => setActiveFilter('yellow')}
                >
                    Yellow Zone
                </Button>
                <Button style={{ backgroundColor: "#57adba" }}
                    className={`filter-button ${activeFilter === 'green' ? 'active' : ''}`}
                    onClick={() => setActiveFilter('green')}
                >
                    Green Zone
                </Button>

                <Button style={{ backgroundColor: "#696a6d" }}
                    className={`filter-button ${activeFilter === 'all' ? 'active' : ''}`}
                    onClick={() => setActiveFilter('all')}
                >
                    Show All
                </Button>
            </div>

            <DualAxes className='RCA-chart' {...config} />
        </div>
    );
};

export default RootCauseAnalysisChart;
