export const poultryData = [
    {
        title: "Production%",
        imgSrc:"/Images/shed-images/stack-of-eggs.png",
        today: "75",
        yesterday: "77",
        borderColor: "#f94144",
        lastThreeDays: [
            {
                "actual": "75",
                "standard": "78"
            },
            {
                "actual": "77",
                "standard": "76"
            },
            {
                "actual": "72",
                "standard": "75"
            }
        ]
    },
    {
        title: "Damaged Eggs%",
        imgSrc:"/Images/shed-images/damaged-egg.png",
        today: "5",
        yesterday: "5.5",
        borderColor: '#f3722c',
        lastThreeDays: [
            {
                "actual": "5",
                "standard": "4.5"
            },
            {
                "actual": "5.5",
                "standard": "5"
            },
            {
                "actual": "6",
                "standard": "5.5"
            }
        ]
    },
    {
        title: "Mortality%",
        imgSrc:"/Images/shed-images/mortality.png",
        today: "1",
        yesterday: "1.4",
        borderColor: "#f8961e",
        lastThreeDays: [
            {
                "actual": "1",
                "standard": "0.8"
            },
            {
                "actual": "1.4",
                "standard": "1.2"
            },
            {
                "actual": "1.1",
                "standard": "1.3"
            }
        ]
    },

    {
        title: 'Active',
        imgSrc:"/Images/shed-images/active-sheds.png",
        today: 21,
        yesterday: 27,
        icon: 'inc/desc',
        borderColor: '#f9c74f',
        lastThreeDays: [
            {
                actual: '17',
                standard: "30",
            },
            {
                actual: '35',
                standard: "30",
            },
            {
                actual: '25',
                standard: "30"
            },
        ]
    },
    {
        title: 'Occupancy Rate%',
        imgSrc:"/Images/shed-images/occupancy.png",
        today: 85,
        yesterday: 97,
        icon: 'inc/desc',
        borderColor: '#90be6d',
        lastThreeDays: [
            {
                actual: '75',
                standard: "90",
            },
            {
                actual: '95',
                standard: "90",
            },
            {
                actual: '55',
                standard: "90"
            },
        ]
    },
    {
        title: 'Empty Sheds %',
        imgSrc:"/Images/shed-images/empty-shed.png",
        today: 85,
        yesterday: 97,
        icon: 'inc/desc',
        borderColor: '#43aa8b',
        lastThreeDays: [
            {
                actual: '75',
                standard: "90",
            },
            {
                actual: '95',
                standard: "90",
            },
            {
                actual: '55',
                standard: "90"
            },
        ]
    },
    {
        title: 'Feed per Egg(g)  ',
        imgSrc:"/Images/shed-images/egg-feed.png",
        today: 85,
        yesterday: 97,
        icon: 'inc/desc',
        borderColor: "#4d908e",
        lastThreeDays: [
            {
                actual: '75',
                standard: "90",
            },
            {
                actual: '95',
                standard: "90",
            },
            {
                actual: '55',
                standard: "90"
            },
        ]
    },
    {
        title: 'Feed per Chick(g)  ',
        imgSrc:"/Images/shed-images/feed-per-chik.png",
        today: 85,
        yesterday: 97,
        icon: 'inc/desc',
        borderColor: '#4cc9f0',
        lastThreeDays: [
            {
                actual: '75',
                standard: "90",
            },
            {
                actual: '95',
                standard: "90",
            },
            {
                actual: '55',
                standard: "90"
            },
        ]
    }
];
