export const calendarUnitData = [

  {
    date: "2024-09-20",
    unitName: "BHUVANAGIRI",
    shedName: "Shed 1",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1023",
    damagedEggs: "35",
    mortality: "12%",
    weather: "Sunny"
  },
  {
    date: "2024-09-20",
    unitName: "BHUVANAGIRI",
    shedName: "Shed 2",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "998",
    damagedEggs: "41",
    mortality: "14%",
    weather: "Sunny"
  },
  {
    date: "2024-09-20",
    unitName: "BHUVANAGIRI",
    shedName: "Shed 3",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "985",
    damagedEggs: "47",
    mortality: "15%",
    weather: "Sunny"
  },
  {
    date: "2024-09-20",
    unitName: "BHUVANAGIRI",
    shedName: "Shed 4",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1015",
    damagedEggs: "30",
    mortality: "10%",
    weather: "Sunny"
  },
  {
    date: "2024-09-20",
    unitName: "BHUVANAGIRI",
    shedName: "Shed 5",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1007",
    damagedEggs: "42",
    mortality: "13%",
    weather: "Sunny"
  },
  {
    date: "2024-09-20",
    unitName: "BHUVANAGIRI",
    shedName: "Shed 6",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "992",
    damagedEggs: "45",
    mortality: "14%",
    weather: "Sunny"
  },
  {
    date: "2024-09-20",
    unitName: "BHUVANAGIRI",
    shedName: "Shed 7",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "980",
    damagedEggs: "50",
    mortality: "18%",
    weather: "Sunny"
  },
  {
    date: "2024-09-20",
    unitName: "BHUVANAGIRI",
    shedName: "Shed 8",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1005",
    damagedEggs: "48",
    mortality: "16%",
    weather: "Sunny"
  },
  {
    date: "2024-09-20",
    unitName: "BHUVANAGIRI",
    shedName: "Shed 9",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "990",
    damagedEggs: "52",
    mortality: "19%",
    weather: "Sunny"
  },
  {
    date: "2024-09-20",
    unitName: "BHUVANAGIRI",
    shedName: "Shed 10",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "995",
    damagedEggs: "36",
    mortality: "12%",
    weather: "Sunny"
  },
  {
    date: "2024-09-20",
    unitName: "BHUVANAGIRI",
    shedName: "Shed 11",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1000",
    damagedEggs: "40",
    mortality: "14%",
    weather: "Sunny"
  },
  {
    date: "2024-09-20",
    unitName: "BHUVANAGIRI",
    shedName: "Shed 12",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "997",
    damagedEggs: "49",
    mortality: "17%",
    weather: "Sunny"
  },
  {
    date: "2024-09-20",
    unitName: "BHUVANAGIRI",
    shedName: "Shed 13",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "985",
    damagedEggs: "45",
    mortality: "15%",
    weather: "Sunny"
  },
  {
    date: "2024-09-21",
    unitName: "BHUVANAGIRI",
    shedName: "Shed 1",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1010",
    damagedEggs: "38",
    mortality: "13%",
    weather: "Sunny"
  },
  {
    date: "2024-09-21",
    unitName: "BHUVANAGIRI",
    shedName: "Shed 2",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1008",
    damagedEggs: "39",
    mortality: "14%",
    weather: "Sunny"
  },
  {
    date: "2024-09-21",
    unitName: "BHUVANAGIRI",
    shedName: "Shed 3",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "975",
    damagedEggs: "46",
    mortality: "16%",
    weather: "Sunny"
  },
  {
    date: "2024-09-21",
    unitName: "BHUVANAGIRI",
    shedName: "Shed 4",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1012",
    damagedEggs: "34",
    mortality: "11%",
    weather: "Sunny"
  },
  {
    date: "2024-09-21",
    unitName: "BHUVANAGIRI",
    shedName: "Shed 5",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "994",
    damagedEggs: "37",
    mortality: "13%",
    weather: "Sunny"
  },
  {
    date: "2024-09-21",
    unitName: "BHUVANAGIRI",
    shedName: "Shed 6",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "989",
    damagedEggs: "48",
    mortality: "15%",
    weather: "Sunny"
  },
  {
    date: "2024-09-21",
    unitName: "BHUVANAGIRI",
    shedName: "Shed 7",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "978",
    damagedEggs: "52",
    mortality: "19%",
    weather: "Sunny"
  },
  {
    date: "2024-09-21",
    unitName: "BHUVANAGIRI",
    shedName: "Shed 8",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1002",
    damagedEggs: "40",
    mortality: "14%",
    weather: "Sunny"
  },
  {
    date: "2024-09-21",
    unitName: "BHUVANAGIRI",
    shedName: "Shed 9",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "982",
    damagedEggs: "51",
    mortality: "18%",
    weather: "Sunny"
  },
  {
    date: "2024-09-21",
    unitName: "BHUVANAGIRI",
    shedName: "Shed 10",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "996",
    damagedEggs: "35",
    mortality: "12%",
    weather: "Sunny"
  },
  {
    date: "2024-09-21",
    unitName: "BHUVANAGIRI",
    shedName: "Shed 11",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1015",
    damagedEggs: "37",
    mortality: "12%",
    weather: "Sunny"
  },
  {
    date: "2024-09-21",
    unitName: "BHUVANAGIRI",
    shedName: "Shed 12",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "999",
    damagedEggs: "49",
    mortality: "17%",
    weather: "Sunny"
  },
  {
    date: "2024-09-21",
    unitName: "BHUVANAGIRI",
    shedName: "Shed 13",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "978",
    damagedEggs: "47",
    mortality: "15%",
    weather: "Sunny"
  },


  //unit-2
  {
    date: "2024-09-20",
    unitName: "PEDDAPURAM",
    shedName: "Shed 1",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1005",
    damagedEggs: "40",
    mortality: "12%",
    weather: "Rainy"
  },
  {
    date: "2024-09-20",
    unitName: "PEDDAPURAM",
    shedName: "Shed 2",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "995",
    damagedEggs: "45",
    mortality: "13%",
    weather: "Rainy"
  },
  {
    date: "2024-09-20",
    unitName: "PEDDAPURAM",
    shedName: "Shed 3",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "980",
    damagedEggs: "47",
    mortality: "14%",
    weather: "Rainy"
  },
  {
    date: "2024-09-20",
    unitName: "PEDDAPURAM",
    shedName: "Shed 4",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1008",
    damagedEggs: "38",
    mortality: "10%",
    weather: "Rainy"
  },
  {
    date: "2024-09-20",
    unitName: "PEDDAPURAM",
    shedName: "Shed 5",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "990",
    damagedEggs: "43",
    mortality: "12%",
    weather: "Rainy"
  },
  {
    date: "2024-09-20",
    unitName: "PEDDAPURAM",
    shedName: "Shed 6",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "982",
    damagedEggs: "44",
    mortality: "13%",
    weather: "Rainy"
  },
  {
    date: "2024-09-20",
    unitName: "PEDDAPURAM",
    shedName: "Shed 7",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "975",
    damagedEggs: "49",
    mortality: "15%",
    weather: "Rainy"
  },
  {
    date: "2024-09-20",
    unitName: "PEDDAPURAM",
    shedName: "Shed 8",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1003",
    damagedEggs: "41",
    mortality: "11%",
    weather: "Rainy"
  },
  {
    date: "2024-09-20",
    unitName: "PEDDAPURAM",
    shedName: "Shed 9",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "987",
    damagedEggs: "50",
    mortality: "16%",
    weather: "Rainy"
  },
  {
    date: "2024-09-20",
    unitName: "PEDDAPURAM",
    shedName: "Shed 10",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "992",
    damagedEggs: "34",
    mortality: "10%",
    weather: "Rainy"
  },
  {
    date: "2024-09-20",
    unitName: "PEDDAPURAM",
    shedName: "Shed 11",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1006",
    damagedEggs: "36",
    mortality: "11%",
    weather: "Rainy"
  },
  {
    date: "2024-09-20",
    unitName: "PEDDAPURAM",
    shedName: "Shed 12",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "998",
    damagedEggs: "48",
    mortality: "14%",
    weather: "Rainy"
  },
  {
    date: "2024-09-20",
    unitName: "PEDDAPURAM",
    shedName: "Shed 13",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "970",
    damagedEggs: "42",
    mortality: "13%",
    weather: "Rainy"
  },
  {
    date: "2024-09-21",
    unitName: "PEDDAPURAM",
    shedName: "Shed 1",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1012",
    damagedEggs: "39",
    mortality: "12%",
    weather: "Rainy"
  },
  {
    date: "2024-09-21",
    unitName: "PEDDAPURAM",
    shedName: "Shed 2",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "993",
    damagedEggs: "42",
    mortality: "13%",
    weather: "Rainy"
  },
  {
    date: "2024-09-21",
    unitName: "PEDDAPURAM",
    shedName: "Shed 3",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "979",
    damagedEggs: "44",
    mortality: "14%",
    weather: "Rainy"
  },
  {
    date: "2024-09-21",
    unitName: "PEDDAPURAM",
    shedName: "Shed 4",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1007",
    damagedEggs: "35",
    mortality: "11%",
    weather: "Rainy"
  },
  {
    date: "2024-09-21",
    unitName: "PEDDAPURAM",
    shedName: "Shed 5",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "989",
    damagedEggs: "39",
    mortality: "12%",
    weather: "Rainy"
  },
  {
    date: "2024-09-21",
    unitName: "PEDDAPURAM",
    shedName: "Shed 6",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "985",
    damagedEggs: "43",
    mortality: "13%",
    weather: "Rainy"
  },
  {
    date: "2024-09-21",
    unitName: "PEDDAPURAM",
    shedName: "Shed 7",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "977",
    damagedEggs: "50",
    mortality: "15%",
    weather: "Rainy"
  },
  {
    date: "2024-09-21",
    unitName: "PEDDAPURAM",
    shedName: "Shed 8",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1000",
    damagedEggs: "42",
    mortality: "12%",
    weather: "Rainy"
  },
  {
    date: "2024-09-21",
    unitName: "PEDDAPURAM",
    shedName: "Shed 9",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "983",
    damagedEggs: "48",
    mortality: "16%",
    weather: "Rainy"
  },
  {
    date: "2024-09-21",
    unitName: "PEDDAPURAM",
    shedName: "Shed 10",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "989",
    damagedEggs: "36",
    mortality: "11%",
    weather: "Rainy"
  },
  {
    date: "2024-09-21",
    unitName: "PEDDAPURAM",
    shedName: "Shed 11",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1010",
    damagedEggs: "37",
    mortality: "10%",
    weather: "Rainy"
  },
  {
    date: "2024-09-21",
    unitName: "PEDDAPURAM",
    shedName: "Shed 12",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1002",
    damagedEggs: "46",
    mortality: "14%",
    weather: "Rainy"
  },
  {
    date: "2024-09-21",
    unitName: "PEDDAPURAM",
    shedName: "Shed 13",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "968",
    damagedEggs: "45",
    mortality: "12%",
    weather: "Rainy"
  },



  //unit-3
  {
    date: "2024-09-20",
    unitName: "BHADRAPALI",
    shedName: "Shed 1",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "980",
    damagedEggs: "42",
    mortality: "10%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-20",
    unitName: "BHADRAPALI",
    shedName: "Shed 2",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1002",
    damagedEggs: "48",
    mortality: "12%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-20",
    unitName: "BHADRAPALI",
    shedName: "Shed 3",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "997",
    damagedEggs: "44",
    mortality: "11%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-20",
    unitName: "BHADRAPALI",
    shedName: "Shed 4",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "990",
    damagedEggs: "46",
    mortality: "13%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-20",
    unitName: "BHADRAPALI",
    shedName: "Shed 5",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "985",
    damagedEggs: "47",
    mortality: "10%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-20",
    unitName: "BHADRAPALI",
    shedName: "Shed 6",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1005",
    damagedEggs: "40",
    mortality: "12%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-20",
    unitName: "BHADRAPALI",
    shedName: "Shed 7",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "988",
    damagedEggs: "43",
    mortality: "11%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-20",
    unitName: "BHADRAPALI",
    shedName: "Shed 8",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "992",
    damagedEggs: "38",
    mortality: "14%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-20",
    unitName: "BHADRAPALI",
    shedName: "Shed 9",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "979",
    damagedEggs: "45",
    mortality: "12%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-20",
    unitName: "BHADRAPALI",
    shedName: "Shed 10",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "994",
    damagedEggs: "50",
    mortality: "13%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-20",
    unitName: "BHADRAPALI",
    shedName: "Shed 11",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "981",
    damagedEggs: "42",
    mortality: "14%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-20",
    unitName: "BHADRAPALI",
    shedName: "Shed 12",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1008",
    damagedEggs: "36",
    mortality: "11%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-20",
    unitName: "BHADRAPALI",
    shedName: "Shed 13",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "975",
    damagedEggs: "44",
    mortality: "15%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-21",
    unitName: "BHADRAPALI",
    shedName: "Shed 1",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "993",
    damagedEggs: "40",
    mortality: "10%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-21",
    unitName: "BHADRAPALI",
    shedName: "Shed 2",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "999",
    damagedEggs: "47",
    mortality: "12%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-21",
    unitName: "BHADRAPALI",
    shedName: "Shed 3",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "985",
    damagedEggs: "39",
    mortality: "11%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-21",
    unitName: "BHADRAPALI",
    shedName: "Shed 4",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "991",
    damagedEggs: "46",
    mortality: "13%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-21",
    unitName: "BHADRAPALI",
    shedName: "Shed 5",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "987",
    damagedEggs: "45",
    mortality: "10%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-21",
    unitName: "BHADRAPALI",
    shedName: "Shed 6",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1001",
    damagedEggs: "42",
    mortality: "12%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-21",
    unitName: "BHADRAPALI",
    shedName: "Shed 7",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "980",
    damagedEggs: "49",
    mortality: "11%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-21",
    unitName: "BHADRAPALI",
    shedName: "Shed 8",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "995",
    damagedEggs: "41",
    mortality: "14%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-21",
    unitName: "BHADRAPALI",
    shedName: "Shed 9",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "983",
    damagedEggs: "43",
    mortality: "12%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-21",
    unitName: "BHADRAPALI",
    shedName: "Shed 10",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "986",
    damagedEggs: "38",
    mortality: "13%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-21",
    unitName: "BHADRAPALI",
    shedName: "Shed 11",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1003",
    damagedEggs: "45",
    mortality: "14%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-21",
    unitName: "BHADRAPALI",
    shedName: "Shed 12",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "998",
    damagedEggs: "37",
    mortality: "11%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-21",
    unitName: "BHADRAPALI",
    shedName: "Shed 13",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "991",
    damagedEggs: "40",
    mortality: "15%",
    weather: "Cloudy"
  },



  //unit-4
  {
    date: "2024-09-20",
    unitName: "Village farm",
    shedName: "Shed 1",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1020",
    damagedEggs: "55",
    mortality: "8%",
    weather: "Stormy"
  },
  {
    date: "2024-09-20",
    unitName: "Village farm",
    shedName: "Shed 2",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "980",
    damagedEggs: "60",
    mortality: "55%",
    weather: "Stormy"
  },
  {
    date: "2024-09-20",
    unitName: "Village farm",
    shedName: "Shed 3",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "990",
    damagedEggs: "45",
    mortality: "52%",
    weather: "Stormy"
  },
  {
    date: "2024-09-20",
    unitName: "Village farm",
    shedName: "Shed 4",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1010",
    damagedEggs: "40",
    mortality: "45%",
    weather: "Stormy"
  },
  {
    date: "2024-09-20",
    unitName: "Village farm",
    shedName: "Shed 5",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1005",
    damagedEggs: "52",
    mortality: "60%",
    weather: "Stormy"
  },
  {
    date: "2024-09-20",
    unitName: "Village farm",
    shedName: "Shed 6",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "970",
    damagedEggs: "65",
    mortality: "10%",
    weather: "Stormy"
  },
  {
    date: "2024-09-20",
    unitName: "Village farm",
    shedName: "Shed 7",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "970",
    damagedEggs: "48",
    mortality: "51%",
    weather: "Stormy"
  },
  {
    date: "2024-09-20",
    unitName: "Village farm",
    shedName: "Shed 8",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1030",
    damagedEggs: "54",
    mortality: "49%",
    weather: "Stormy"
  },
  {
    date: "2024-09-20",
    unitName: "Village farm",
    shedName: "Shed 9",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "995",
    damagedEggs: "55",
    mortality: "55%",
    weather: "Stormy"
  },
  {
    date: "2024-09-20",
    unitName: "Village farm",
    shedName: "Shed 10",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1002",
    damagedEggs: "50",
    mortality: "48%",
    weather: "Stormy"
  },
  {
    date: "2024-09-20",
    unitName: "Village farm",
    shedName: "Shed 11",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1008",
    damagedEggs: "42",
    mortality: "47%",
    weather: "Stormy"
  },
  {
    date: "2024-09-20",
    unitName: "Village farm",
    shedName: "Shed 12",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "990",
    damagedEggs: "60",
    mortality: "53%",
    weather: "Stormy"
  },
  {
    date: "2024-09-20",
    unitName: "Village farm",
    shedName: "Shed 13",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1015",
    damagedEggs: "55",
    mortality: "49%",
    weather: "Stormy"
  },
  {
    date: "2024-09-21",
    unitName: "Village farm",
    shedName: "Shed 1",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1000",
    damagedEggs: "45",
    mortality: "48%",
    weather: "Stormy"
  },
  {
    date: "2024-09-21",
    unitName: "Village farm",
    shedName: "Shed 2",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "990",
    damagedEggs: "60",
    mortality: "50%",
    weather: "Stormy"
  },
  {
    date: "2024-09-21",
    unitName: "Village farm",
    shedName: "Shed 3",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "980",
    damagedEggs: "65",
    mortality: "51%",
    weather: "Stormy"
  },
  {
    date: "2024-09-21",
    unitName: "Village farm",
    shedName: "Shed 4",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1020",
    damagedEggs: "40",
    mortality: "47%",
    weather: "Stormy"
  },
  {
    date: "2024-09-21",
    unitName: "Village farm",
    shedName: "Shed 5",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1040",
    damagedEggs: "70",
    mortality: "60%",
    weather: "Stormy"
  },
  {
    date: "2024-09-21",
    unitName: "Village farm",
    shedName: "Shed 6",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "960",
    damagedEggs: "68",
    mortality: "8%",
    weather: "Stormy"
  },
  {
    date: "2024-09-21",
    unitName: "Village farm",
    shedName: "Shed 7",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "965",
    damagedEggs: "50",
    mortality: "52%",
    weather: "Stormy"
  },
  {
    date: "2024-09-21",
    unitName: "Village farm",
    shedName: "Shed 8",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1025",
    damagedEggs: "56",
    mortality: "48%",
    weather: "Stormy"
  },
  {
    date: "2024-09-21",
    unitName: "Village farm",
    shedName: "Shed 9",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "985",
    damagedEggs: "57",
    mortality: "50%",
    weather: "Stormy"
  },
  {
    date: "2024-09-21",
    unitName: "Village farm",
    shedName: "Shed 10",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1010",
    damagedEggs: "48",
    mortality: "49%",
    weather: "Stormy"
  },
  {
    date: "2024-09-21",
    unitName: "Village farm",
    shedName: "Shed 11",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1035",
    damagedEggs: "42",
    mortality: "46%",
    weather: "Stormy"
  },
  {
    date: "2024-09-21",
    unitName: "Village farm",
    shedName: "Shed 12",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "995",
    damagedEggs: "62",
    mortality: "52%",
    weather: "Stormy"
  },
  {
    date: "2024-09-21",
    unitName: "Village farm",
    shedName: "Shed 13",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1005",
    damagedEggs: "55",
    mortality: "49%",
    weather: "Stormy"
  },



  //unit-5

  {
    date: "2024-09-20",
    unitName: "ARJUNDA",
    shedName: "Shed 1",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1005",
    damagedEggs: "55",
    mortality: "48%",
    weather: "Sunny"
  },
  {
    date: "2024-09-20",
    unitName: "ARJUNDA",
    shedName: "Shed 2",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "980",
    damagedEggs: "52",
    mortality: "51%",
    weather: "Sunny"
  },
  {
    date: "2024-09-20",
    unitName: "ARJUNDA",
    shedName: "Shed 3",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "970",
    damagedEggs: "60",
    mortality: "54%",
    weather: "Sunny"
  },
  {
    date: "2024-09-20",
    unitName: "ARJUNDA",
    shedName: "Shed 4",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1008",
    damagedEggs: "48",
    mortality: "47%",
    weather: "Sunny"
  },
  {
    date: "2024-09-20",
    unitName: "ARJUNDA",
    shedName: "Shed 5",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1012",
    damagedEggs: "50",
    mortality: "50%",
    weather: "Sunny"
  },
  {
    date: "2024-09-20",
    unitName: "ARJUNDA",
    shedName: "Shed 6",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1020",
    damagedEggs: "45",
    mortality: "49%",
    weather: "Sunny"
  },
  {
    date: "2024-09-20",
    unitName: "ARJUNDA",
    shedName: "Shed 7",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "990",
    damagedEggs: "51",
    mortality: "52%",
    weather: "Sunny"
  },
  {
    date: "2024-09-20",
    unitName: "ARJUNDA",
    shedName: "Shed 8",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1040",
    damagedEggs: "53",
    mortality: "50%",
    weather: "Sunny"
  },
  {
    date: "2024-09-20",
    unitName: "ARJUNDA",
    shedName: "Shed 9",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1015",
    damagedEggs: "49",
    mortality: "48%",
    weather: "Sunny"
  },
  {
    date: "2024-09-20",
    unitName: "ARJUNDA",
    shedName: "Shed 10",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1025",
    damagedEggs: "50",
    mortality: "49%",
    weather: "Sunny"
  },
  {
    date: "2024-09-20",
    unitName: "ARJUNDA",
    shedName: "Shed 11",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "980",
    damagedEggs: "47",
    mortality: "50%",
    weather: "Sunny"
  },
  {
    date: "2024-09-20",
    unitName: "ARJUNDA",
    shedName: "Shed 12",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "985",
    damagedEggs: "60",
    mortality: "53%",
    weather: "Sunny"
  },
  {
    date: "2024-09-20",
    unitName: "ARJUNDA",
    shedName: "Shed 13",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1007",
    damagedEggs: "51",
    mortality: "50%",
    weather: "Sunny"
  },
  {
    date: "2024-09-21",
    unitName: "ARJUNDA",
    shedName: "Shed 1",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1010",
    damagedEggs: "54",
    mortality: "49%",
    weather: "Sunny"
  },
  {
    date: "2024-09-21",
    unitName: "ARJUNDA",
    shedName: "Shed 2",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1005",
    damagedEggs: "55",
    mortality: "50%",
    weather: "Sunny"
  },
  {
    date: "2024-09-21",
    unitName: "ARJUNDA",
    shedName: "Shed 3",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1015",
    damagedEggs: "53",
    mortality: "52%",
    weather: "Sunny"
  },
  {
    date: "2024-09-21",
    unitName: "ARJUNDA",
    shedName: "Shed 4",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "995",
    damagedEggs: "49",
    mortality: "48%",
    weather: "Sunny"
  },
  {
    date: "2024-09-21",
    unitName: "ARJUNDA",
    shedName: "Shed 5",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1012",
    damagedEggs: "57",
    mortality: "50%",
    weather: "Sunny"
  },
  {
    date: "2024-09-21",
    unitName: "ARJUNDA",
    shedName: "Shed 6",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1020",
    damagedEggs: "45",
    mortality: "49%",
    weather: "Sunny"
  },
  {
    date: "2024-09-21",
    unitName: "ARJUNDA",
    shedName: "Shed 7",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "965",
    damagedEggs: "50",
    mortality: "52%",
    weather: "Sunny"
  },
  {
    date: "2024-09-21",
    unitName: "ARJUNDA",
    shedName: "Shed 8",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1025",
    damagedEggs: "56",
    mortality: "48%",
    weather: "Sunny"
  },
  {
    date: "2024-09-21",
    unitName: "ARJUNDA",
    shedName: "Shed 9",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "985",
    damagedEggs: "57",
    mortality: "50%",
    weather: "Sunny"
  },
  {
    date: "2024-09-21",
    unitName: "ARJUNDA",
    shedName: "Shed 10",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1010",
    damagedEggs: "48",
    mortality: "49%",
    weather: "Sunny"
  },
  {
    date: "2024-09-21",
    unitName: "ARJUNDA",
    shedName: "Shed 11",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1035",
    damagedEggs: "42",
    mortality: "46%",
    weather: "Sunny"
  },
  {
    date: "2024-09-21",
    unitName: "ARJUNDA",
    shedName: "Shed 12",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "995",
    damagedEggs: "62",
    mortality: "52%",
    weather: "Sunny"
  },
  {
    date: "2024-09-21",
    unitName: "ARJUNDA",
    shedName: "Shed 13",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1005",
    damagedEggs: "55",
    mortality: "49%",
    weather: "Sunny"
  },


  //unit-6


  {
    date: "2024-09-20",
    unitName: "YERRAM PALEM",
    shedName: "Shed 1",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1020",
    damagedEggs: "48",
    mortality: "47%",
    weather: "Rainy"
  },
  {
    date: "2024-09-20",
    unitName: "YERRAM PALEM",
    shedName: "Shed 2",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "995",
    damagedEggs: "50",
    mortality: "52%",
    weather: "Rainy"
  },
  {
    date: "2024-09-20",
    unitName: "YERRAM PALEM",
    shedName: "Shed 3",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "980",
    damagedEggs: "52",
    mortality: "49%",
    weather: "Rainy"
  },
  {
    date: "2024-09-20",
    unitName: "YERRAM PALEM",
    shedName: "Shed 4",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1015",
    damagedEggs: "49",
    mortality: "48%",
    weather: "Rainy"
  },
  {
    date: "2024-09-20",
    unitName: "YERRAM PALEM",
    shedName: "Shed 5",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1008",
    damagedEggs: "54",
    mortality: "51%",
    weather: "Rainy"
  },
  {
    date: "2024-09-20",
    unitName: "YERRAM PALEM",
    shedName: "Shed 6",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1030",
    damagedEggs: "48",
    mortality: "47%",
    weather: "Rainy"
  },
  {
    date: "2024-09-20",
    unitName: "YERRAM PALEM",
    shedName: "Shed 7",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "990",
    damagedEggs: "51",
    mortality: "52%",
    weather: "Rainy"
  },
  {
    date: "2024-09-20",
    unitName: "YERRAM PALEM",
    shedName: "Shed 8",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1050",
    damagedEggs: "53",
    mortality: "49%",
    weather: "Rainy"
  },
  {
    date: "2024-09-20",
    unitName: "YERRAM PALEM",
    shedName: "Shed 9",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1010",
    damagedEggs: "50",
    mortality: "48%",
    weather: "Rainy"
  },
  {
    date: "2024-09-20",
    unitName: "YERRAM PALEM",
    shedName: "Shed 10",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1025",
    damagedEggs: "51",
    mortality: "49%",
    weather: "Rainy"
  },
  {
    date: "2024-09-20",
    unitName: "YERRAM PALEM",
    shedName: "Shed 11",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "985",
    damagedEggs: "48",
    mortality: "50%",
    weather: "Rainy"
  },
  {
    date: "2024-09-20",
    unitName: "YERRAM PALEM",
    shedName: "Shed 12",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "995",
    damagedEggs: "56",
    mortality: "53%",
    weather: "Rainy"
  },
  {
    date: "2024-09-20",
    unitName: "YERRAM PALEM",
    shedName: "Shed 13",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1015",
    damagedEggs: "52",
    mortality: "51%",
    weather: "Rainy"
  },
  {
    date: "2024-09-21",
    unitName: "YERRAM PALEM",
    shedName: "Shed 1",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1025",
    damagedEggs: "46",
    mortality: "47%",
    weather: "Rainy"
  },
  {
    date: "2024-09-21",
    unitName: "YERRAM PALEM",
    shedName: "Shed 2",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1002",
    damagedEggs: "52",
    mortality: "51%",
    weather: "Rainy"
  },
  {
    date: "2024-09-21",
    unitName: "YERRAM PALEM",
    shedName: "Shed 3",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "990",
    damagedEggs: "53",
    mortality: "49%",
    weather: "Rainy"
  },
  {
    date: "2024-09-21",
    unitName: "YERRAM PALEM",
    shedName: "Shed 4",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1018",
    damagedEggs: "51",
    mortality: "48%",
    weather: "Rainy"
  },
  {
    date: "2024-09-21",
    unitName: "YERRAM PALEM",
    shedName: "Shed 5",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1006",
    damagedEggs: "55",
    mortality: "50%",
    weather: "Rainy"
  },
  {
    date: "2024-09-21",
    unitName: "YERRAM PALEM",
    shedName: "Shed 6",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1032",
    damagedEggs: "49",
    mortality: "47%",
    weather: "Rainy"
  },
  {
    date: "2024-09-21",
    unitName: "YERRAM PALEM",
    shedName: "Shed 7",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "987",
    damagedEggs: "53",
    mortality: "51%",
    weather: "Rainy"
  },
  {
    date: "2024-09-21",
    unitName: "YERRAM PALEM",
    shedName: "Shed 8",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1045",
    damagedEggs: "52",
    mortality: "48%",
    weather: "Rainy"
  },
  {
    date: "2024-09-21",
    unitName: "YERRAM PALEM",
    shedName: "Shed 9",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1013",
    damagedEggs: "54",
    mortality: "49%",
    weather: "Rainy"
  },
  {
    date: "2024-09-21",
    unitName: "YERRAM PALEM",
    shedName: "Shed 10",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1028",
    damagedEggs: "50",
    mortality: "47%",
    weather: "Rainy"
  },
  {
    date: "2024-09-21",
    unitName: "YERRAM PALEM",
    shedName: "Shed 11",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "988",
    damagedEggs: "47",
    mortality: "50%",
    weather: "Rainy"
  },
  {
    date: "2024-09-21",
    unitName: "YERRAM PALEM",
    shedName: "Shed 12",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1001",
    damagedEggs: "58",
    mortality: "53%",
    weather: "Rainy"
  },
  {
    date: "2024-09-21",
    unitName: "YERRAM PALEM",
    shedName: "Shed 13",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1012",
    damagedEggs: "51",
    mortality: "49%",
    weather: "Rainy"
  },



  //unit-7

  {
    date: "2024-09-20",
    unitName: "Boyapalem",
    shedName: "Shed 1",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1005",
    damagedEggs: "45",
    mortality: "48%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-20",
    unitName: "Boyapalem",
    shedName: "Shed 2",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "980",
    damagedEggs: "55",
    mortality: "52%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-20",
    unitName: "Boyapalem",
    shedName: "Shed 3",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "990",
    damagedEggs: "53",
    mortality: "49%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-20",
    unitName: "Boyapalem",
    shedName: "Shed 4",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1020",
    damagedEggs: "48",
    mortality: "47%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-20",
    unitName: "Boyapalem",
    shedName: "Shed 5",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1008",
    damagedEggs: "50",
    mortality: "50%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-20",
    unitName: "Boyapalem",
    shedName: "Shed 6",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1025",
    damagedEggs: "49",
    mortality: "48%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-20",
    unitName: "Boyapalem",
    shedName: "Shed 7",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1003",
    damagedEggs: "52",
    mortality: "51%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-20",
    unitName: "Boyapalem",
    shedName: "Shed 8",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1018",
    damagedEggs: "51",
    mortality: "49%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-20",
    unitName: "Boyapalem",
    shedName: "Shed 9",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1020",
    damagedEggs: "54",
    mortality: "53%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-20",
    unitName: "Boyapalem",
    shedName: "Shed 10",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1002",
    damagedEggs: "48",
    mortality: "50%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-20",
    unitName: "Boyapalem",
    shedName: "Shed 11",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "990",
    damagedEggs: "46",
    mortality: "48%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-20",
    unitName: "Boyapalem",
    shedName: "Shed 12",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1004",
    damagedEggs: "51",
    mortality: "49%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-20",
    unitName: "Boyapalem",
    shedName: "Shed 13",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1012",
    damagedEggs: "55",
    mortality: "52%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-21",
    unitName: "Boyapalem",
    shedName: "Shed 1",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1000",
    damagedEggs: "47",
    mortality: "45%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-21",
    unitName: "Boyapalem",
    shedName: "Shed 2",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "993",
    damagedEggs: "54",
    mortality: "52%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-21",
    unitName: "Boyapalem",
    shedName: "Shed 3",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1008",
    damagedEggs: "50",
    mortality: "49%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-21",
    unitName: "Boyapalem",
    shedName: "Shed 4",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1015",
    damagedEggs: "53",
    mortality: "50%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-21",
    unitName: "Boyapalem",
    shedName: "Shed 5",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1022",
    damagedEggs: "52",
    mortality: "51%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-21",
    unitName: "Boyapalem",
    shedName: "Shed 6",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1005",
    damagedEggs: "50",
    mortality: "48%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-21",
    unitName: "Boyapalem",
    shedName: "Shed 7",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1017",
    damagedEggs: "51",
    mortality: "50%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-21",
    unitName: "Boyapalem",
    shedName: "Shed 8",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1030",
    damagedEggs: "52",
    mortality: "52%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-21",
    unitName: "Boyapalem",
    shedName: "Shed 9",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1025",
    damagedEggs: "55",
    mortality: "54%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-21",
    unitName: "Boyapalem",
    shedName: "Shed 10",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1006",
    damagedEggs: "49",
    mortality: "50%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-21",
    unitName: "Boyapalem",
    shedName: "Shed 11",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1012",
    damagedEggs: "53",
    mortality: "52%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-21",
    unitName: "Boyapalem",
    shedName: "Shed 12",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1031",
    damagedEggs: "56",
    mortality: "54%",
    weather: "Cloudy"
  },
  {
    date: "2024-09-21",
    unitName: "Boyapalem",
    shedName: "Shed 13",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1040",
    damagedEggs: "57",
    mortality: "55%",
    weather: "Cloudy"
  },


  //unit-8

  {
    date: "2024-09-20",
    unitName: "SHABAD",
    shedName: "Shed 1",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "995",
    damagedEggs: "48",
    mortality: "49%",
    weather: "Stormy"
  },
  {
    date: "2024-09-20",
    unitName: "SHABAD",
    shedName: "Shed 2",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1008",
    damagedEggs: "44",
    mortality: "47%",
    weather: "Stormy"
  },
  {
    date: "2024-09-20",
    unitName: "SHABAD",
    shedName: "Shed 3",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1020",
    damagedEggs: "49",
    mortality: "45%",
    weather: "Stormy"
  },
  {
    date: "2024-09-20",
    unitName: "SHABAD",
    shedName: "Shed 4",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "990",
    damagedEggs: "51",
    mortality: "50%",
    weather: "Stormy"
  },
  {
    date: "2024-09-20",
    unitName: "SHABAD",
    shedName: "Shed 5",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "980",
    damagedEggs: "56",
    mortality: "52%",
    weather: "Stormy"
  },
  {
    date: "2024-09-20",
    unitName: "SHABAD",
    shedName: "Shed 6",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1005",
    damagedEggs: "50",
    mortality: "49%",
    weather: "Stormy"
  },
  {
    date: "2024-09-20",
    unitName: "SHABAD",
    shedName: "Shed 7",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1022",
    damagedEggs: "53",
    mortality: "51%",
    weather: "Stormy"
  },
  {
    date: "2024-09-20",
    unitName: "SHABAD",
    shedName: "Shed 8",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1015",
    damagedEggs: "52",
    mortality: "50%",
    weather: "Stormy"
  },
  {
    date: "2024-09-20",
    unitName: "SHABAD",
    shedName: "Shed 9",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1023",
    damagedEggs: "56",
    mortality: "53%",
    weather: "Stormy"
  },
  {
    date: "2024-09-20",
    unitName: "SHABAD",
    shedName: "Shed 10",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "990",
    damagedEggs: "47",
    mortality: "48%",
    weather: "Stormy"
  },
  {
    date: "2024-09-20",
    unitName: "SHABAD",
    shedName: "Shed 11",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1002",
    damagedEggs: "54",
    mortality: "51%",
    weather: "Stormy"
  },
  {
    date: "2024-09-20",
    unitName: "SHABAD",
    shedName: "Shed 12",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1010",
    damagedEggs: "57",
    mortality: "52%",
    weather: "Stormy"
  },
  {
    date: "2024-09-20",
    unitName: "SHABAD",
    shedName: "Shed 13",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1110",
    damagedEggs: "58",
    mortality: "72%",
    weather: "Stormy"
  },
  {
    date: "2024-09-21",
    unitName: "SHABAD",
    shedName: "Shed 1",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1008",
    damagedEggs: "50",
    mortality: "49%",
    weather: "Stormy"
  },
  {
    date: "2024-09-21",
    unitName: "SHABAD",
    shedName: "Shed 2",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "980",
    damagedEggs: "48",
    mortality: "50%",
    weather: "Stormy"
  },
  {
    date: "2024-09-21",
    unitName: "SHABAD",
    shedName: "Shed 3",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "990",
    damagedEggs: "51",
    mortality: "52%",
    weather: "Stormy"
  },
  {
    date: "2024-09-21",
    unitName: "SHABAD",
    shedName: "Shed 4",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1005",
    damagedEggs: "52",
    mortality: "51%",
    weather: "Stormy"
  },
  {
    date: "2024-09-21",
    unitName: "SHABAD",
    shedName: "Shed 5",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1020",
    damagedEggs: "50",
    mortality: "52%",
    weather: "Stormy"
  },
  {
    date: "2024-09-21",
    unitName: "SHABAD",
    shedName: "Shed 6",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1012",
    damagedEggs: "54",
    mortality: "50%",
    weather: "Stormy"
  },
  {
    date: "2024-09-21",
    unitName: "SHABAD",
    shedName: "Shed 7",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1030",
    damagedEggs: "55",
    mortality: "53%",
    weather: "Stormy"
  },
  {
    date: "2024-09-21",
    unitName: "SHABAD",
    shedName: "Shed 8",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1025",
    damagedEggs: "57",
    mortality: "54%",
    weather: "Stormy"
  },
  {
    date: "2024-09-21",
    unitName: "SHABAD",
    shedName: "Shed 9",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1032",
    damagedEggs: "58",
    mortality: "56%",
    weather: "Stormy"
  },
  {
    date: "2024-09-21",
    unitName: "SHABAD",
    shedName: "Shed 10",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1010",
    damagedEggs: "53",
    mortality: "52%",
    weather: "Stormy"
  },
  {
    date: "2024-09-21",
    unitName: "SHABAD",
    shedName: "Shed 11",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1020",
    damagedEggs: "55",
    mortality: "53%",
    weather: "Stormy"
  },
  {
    date: "2024-09-21",
    unitName: "SHABAD",
    shedName: "Shed 12",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1007",
    damagedEggs: "51",
    mortality: "50%",
    weather: "Stormy"
  },
  {
    date: "2024-09-21",
    unitName: "SHABAD",
    shedName: "Shed 13",
    standardValues: {
      productionValue: "1000",
      damagedEggsValue: "50",
      mortalityValue: "50%"
    },
    production: "1010",
    damagedEggs: "57",
    mortality: "52%",
    weather: "Stormy"
  },

]
