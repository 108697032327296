import React, { useState } from 'react';
import { Select } from 'antd';
import './egg-and-body-weight.css';

const { Option } = Select;

export interface ChickWeightObj {
  unitName: string;
  shedName: string;
  minValue: string;
  maxValue: string;
  chickWeight: string;
}

export interface ChickWeightProps {
  chickWeightData: ChickWeightObj[];
}

const ChickWeight: React.FC<ChickWeightProps> = ({ chickWeightData }) => {
  const [selectedUnit, setSelectedUnit] = useState<string | undefined>(undefined);

  const unitAverages = chickWeightData.reduce((acc, shed) => {
    if (!acc[shed.unitName]) {
      acc[shed.unitName] = { totalWeight: 0, count: 0, minValue: parseFloat(shed.minValue), maxValue: parseFloat(shed.maxValue) };
    }
    acc[shed.unitName].totalWeight += parseFloat(shed.chickWeight);
    acc[shed.unitName].count += 1;
    return acc;
  }, {} as Record<string, { totalWeight: number; count: number; minValue: number; maxValue: number }>);

  const averageUnitData = Object.keys(unitAverages).map((unitName) => ({
    unitName,
    averageWeight: unitAverages[unitName].totalWeight / unitAverages[unitName].count,
    minValue: unitAverages[unitName].minValue,
    maxValue: unitAverages[unitName].maxValue,
  }));

  const filteredData = selectedUnit
    ? chickWeightData.filter((shed) => shed.unitName === selectedUnit)
    : averageUnitData.map((unit) => ({
        shedName: unit.unitName,
        chickWeight: unit.averageWeight.toFixed(2),
        minValue: unit.minValue.toFixed(2),
        maxValue: unit.maxValue.toFixed(2),
        unitName: unit.unitName,
      }));

  const shedsByColor = filteredData.reduce(
    (acc, shed) => {
      const chickWeight = parseFloat(shed.chickWeight);
      const minValue = parseFloat(shed.minValue);
      const maxValue = parseFloat(shed.maxValue);

      if (chickWeight <= minValue) {
        acc.red.push(shed.shedName);
      } else if (chickWeight >= maxValue) {
        acc.green.push(shed.shedName);
      } else {
        acc.yellow.push(shed.shedName);
      }

      return acc;
    },
    { red: [] as string[], yellow: [] as string[], green: [] as string[] }
  );

  const uniqueUnits = Array.from(new Set(chickWeightData.map((unit) => unit.unitName)));

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Select
        placeholder="Select a unit"
        style={{ width: 200, marginBottom: 20 }}
        onChange={(value) => setSelectedUnit(value)}
        allowClear
      >
        <Option value={undefined}>All Units</Option>
        {uniqueUnits.map((unit) => (
          <Option key={unit} value={unit}>
            {unit}
          </Option>
        ))}
      </Select>

      <div className="funnel">
        <div className="section-red">
          {shedsByColor.red.map((shedName, index) => (
            <div key={index} className="chick-shed-name">
              {shedName}
            </div>
          ))}
        </div>
        <div className="section-yellow">
          {shedsByColor.yellow.map((shedName, index) => (
            <div key={index} className="chick-shed-name">
              {shedName}
            </div>
          ))}
        </div>
        <div className="section-green">
          {shedsByColor.green.map((shedName, index) => (
            <div key={index} className="chick-shed-name">
              {shedName}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChickWeight;
