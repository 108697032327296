import React, { useEffect, useState, ChangeEvent } from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import "./UnitExcelUpload.css";
import { LoadingOutlined } from '@ant-design/icons';
import { message, Spin } from 'antd';
import { poultry_standard } from "../../../config";
import { saveAs } from 'file-saver';

interface UnitData {
  [key: string]: {
    [key: string]: [any, number] | null;
  };
}


interface MappedData {
  weekStart: Date;
  weekEnd: Date;
}

interface CityRecord {
  id: number;
  branch: string;
  date: string;
  data: any;
}

interface CityNamesResponse {
  code: number;
  data: Array<{ unit_code: string }>;
  internalMessage: string;
  success: boolean;
}
interface PoultryProductionData {
  ccCode: string;
  date: Date;
  branch: string;
  houseNumber: string;
  batchNumber: string;
  breed: string;
  age: number;
  birdCount: number;
  mortality: number;
  production: number;
  productionDifference: number;
  damagedEggs: number;
  soiledEggs: number;
  jumboEggs: number;
  feedType: string;
  totalFeedConsumed: number;
  bodyWeight: number;
  birdValue: number;
}

const UnitExcelUpload: React.FC = () => {

  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState<string[]>([]);
  const [unitData, setUnitData] = useState<UnitData>({});
  const [selectedUnit, setSelectedUnit] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const formatDate = (date: Date): string => {
    return date.toISOString().split('T')[0];
  };
  const [selectedMonth, setSelectedMonth] = useState<number>(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
  const [selectedWeek, setSelectedWeek] = useState<number | null>(null);
  const [jsonData, setJsonData] = useState<any[] | null>(null);
  const [headers, setHeaders] = useState<string[]>([]);
  const [dates, setDates] = useState<string[]>([]);
  const [viewData, setViewData] = useState<any[] | null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [totalMatches, setTotalMatches] = useState<number>(0);

  useEffect(() => {
    const startOfMonth = new Date(selectedYear, selectedMonth, 2);
    const endOfMonth = new Date(selectedYear, selectedMonth + 1, 0);
    const today = new Date();
    const newDates: string[] = [];
    let weekRanges: MappedData[] = [];

    // Calculate the week ranges within the selected month
    for (let i = 0; i < 4; i++) {
      const weekStart = new Date(selectedYear, selectedMonth, 2 + i * 7);
      const weekEnd = new Date(
        selectedYear,
        selectedMonth,
        Math.min((i + 1) * 7, endOfMonth.getDate())
      );
      weekRanges.push({ weekStart, weekEnd });
    }
    if (selectedWeek) {
      const { weekStart, weekEnd } = weekRanges[selectedWeek - 1];
      for (let date = new Date(weekStart); date <= weekEnd; date.setDate(date.getDate() + 1)) {
        // Ensure the date is within the current month and today or earlier
        if (date.getMonth() === selectedMonth && date <= today) {
          newDates.push(formatDate(date));
        }
      }
    } else {
      for (let i = startOfMonth.getDate(); i <= endOfMonth.getDate(); i++) {
        const date = new Date(selectedYear, selectedMonth, i);
        // Ensure the date is within the current month and today or earlier
        if (date.getMonth() === selectedMonth && date <= today) {
          newDates.push(formatDate(date));
        }
      }
    }

    setDates(newDates);
  }, [selectedMonth, selectedWeek, selectedYear, selectedDate]);



  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch city names
        const cityResponse = await axios.get<CityNamesResponse>(`${poultry_standard}/unit/getAllUnits`);
        const cityNames = cityResponse.data.data.map((city) => city.unit_code);
        setCities(cityNames);

        // Fetch data records
        const dataResponse = await axios.get<any>(`${poultry_standard}/poultry-production-data/getAllPoultryProductionData`);
        const data = dataResponse.data.data;
        // console.log(data.data);

        // Check if `data` is an array
        if (!Array.isArray(data)) {
          throw new Error('Data is not an array');
        }

        // Assuming CityRecord is the correct type
        const typedData: CityRecord[] = data;

        // Index data for faster lookups
        const indexedData = typedData.reduce((acc, item) => {
          if (!acc[item.branch]) {
            acc[item.branch] = {};
          }
          acc[item.branch][item.date] = [item.data, item.id];
          return acc;
        }, {} as Record<string, Record<string, [any, number]>>);

        // Map data
        const mappedData: UnitData = {};
        let matchCount = 0;
        let totalMatchCount = 0;

        cityNames.forEach((city) => {
          mappedData[city] = {};
          dates.forEach((date) => {
            totalMatchCount++;
            const record = indexedData[city]?.[date] || null;
            if (record) {
              matchCount++;
            }
            mappedData[city][date] = record;
          });
        });

        // Update state
        setUnitData(mappedData);
        setCount(matchCount);
        setTotalMatches(totalMatchCount);

      } catch (error) {
        console.error("Error fetching data:", error);
        message.error("Error while fetching branch names")
      }
    };

    fetchData();
  }, [dates, jsonData, selectedMonth]); // Ensure dependencies are accurate




  const handleMonthChange = (monthIndex: number) => {
    setSelectedMonth(monthIndex);
    setSelectedWeek(null); // Reset week selection when month changes
  };
  const getBackgroundColor = () => {
    const percentage = (count / totalMatches) * 100;
    const green = Math.min(255, Math.floor((percentage / 100) * 255));
    const red = 255 - green;
    return `rgb(${red}, ${green}, 0)`;
  };
  const handleYearChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedYear(parseInt(event.target.value));
    setSelectedWeek(null); // Reset week selection when year changes
  };

  const handleWeekChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedWeek(parseInt(event.target.value));
  };

  const handleUploadClick = (city: string, date: string) => {
    setSelectedUnit(city);
    setSelectedDate(new Date(date));
    setJsonData(null);
    const ele = document.querySelector('.upload-section') as HTMLElement
    if (ele) ele.style.display = 'flex';
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0];
    const reader = new FileReader();

    reader.onload = async (e) => {
      const arrayBuffer = e.target?.result as ArrayBuffer;
      const workbook = XLSX.read(arrayBuffer, { type: "array" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];

      // Convert sheet to JSON with raw option to preserve dates as numbers
      let json: any = XLSX.utils.sheet_to_json(worksheet, { raw: true });

      // Manually process the date column to ensure it's in YYYY-MM-DD format
      json = json.map((row: any) => {
        if (row["Date"]) { // Adjust "Date" to match your actual date column name
          row["Date"] = XLSX.SSF.format("yyyy-mm-dd", row["Date"]); // Ensure YYYY-MM-DD format
        }
        return row;
      });

      setJsonData(json);

      if (json.length > 0) {
        setHeaders(Object.keys(json[0]));
      } else {
        setHeaders([]);
      }

      const fileInput = document.querySelector('.upload input[type="file"]') as HTMLInputElement;
      if (fileInput) {
        fileInput.value = ''; // Clear the file input
        const ele = document.querySelector('.submit-btn') as HTMLElement;
        if (ele) ele.style.display = 'flex';
      }
    };
    reader.readAsArrayBuffer(file);
  };

  // Utility functions
  function trimObjectKeys(obj: any): any {
    return Object.keys(obj).reduce((acc, key) => {
      const trimmedKey = key.trim();
      acc[trimmedKey] = obj[key];
      return acc;
    }, {} as any);
  }
  function mapDataArray(dataArray: any[]): PoultryProductionData[] {
    const mappedData: PoultryProductionData[] = [];
  
    try {
      dataArray.forEach(data => {
        const trimmedData = trimObjectKeys(data);
        const branch = trimmedData.Branch || "-";
        const date = trimmedData.Date;
  
        if (branch !== selectedUnit) {
          message.error(`Branch mismatch: The selected branch "${selectedUnit}" does not match the branch "${branch}" in the file.`);
          throw new Error("Branch mismatch");
        }
  
        if (date !== formatDate(selectedDate)) {
          message.error(`Date mismatch: The selected date "${formatDate(selectedDate)}" does not match the date "${date}" in the file.`);
          throw new Error("Date mismatch");
        }
  
        const mappedItem: PoultryProductionData = Object.keys(headerKeyMap).reduce((acc, header) => {
          const mappedKey = headerKeyMap[header];
          const value = trimmedData[header] || getDefaultValue(mappedKey);
          
          return { ...acc, [mappedKey]: value };
        }, {} as PoultryProductionData);
  
        mappedData.push(mappedItem);
      });
    } catch (error) {
      console.error('Error mapping data:', error);
      return mappedData;
    }
  
    return mappedData;
  }
  
  function getDefaultValue(key: keyof PoultryProductionData): any {
    switch (key) {
      case 'ccCode':
      case 'branch':
      case 'houseNumber':
      case 'batchNumber':
      case 'breed':
      case 'feedType':
        return "-";
      case 'age':
      case 'birdCount':
      case 'mortality':
      case 'production':
      case 'productionDifference':
      case 'damagedEggs':
      case 'soiledEggs':
      case 'jumboEggs':
      case 'totalFeedConsumed':
      case 'bodyWeight':
      case 'birdValue':
        return 0;
      case 'date':
        return formatDate(selectedDate);
      default:
        return null;
    }
  }
  

  // Function to hide the upload section
  const hideUploadSection = () => {
    const uploadSection = document.querySelector('.upload-section') as HTMLElement;
    if (uploadSection ) {
      uploadSection.style.display = 'none';
      
    }
    document.body.classList.remove('no-scroll');
    const fileInput = document.querySelector('.upload input[type="file"]') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
    setJsonData(null);
    const submitBtn = document.querySelector('.submit-btn') as HTMLElement;
    if (submitBtn) {
      submitBtn.style.display = 'none';
    }
  };

  // Function to handle form submission
  const handleSubmit = async () => {
    setLoading(true);
    const payload: PoultryProductionData[] = mapDataArray(jsonData || []);
    console.log(payload);
if(payload.length>0){
  try {
      const res = await axios.post(`${poultry_standard}/poultry-production-data/createPoultryProductionData`, payload);
      if (res.data.internalMessage === "Records successfully created.") {
        hideUploadSection();
        message.success(res.data.internalMessage);
        // console.log(res);

      } else {
        message.error(res.data.internalMessage)

      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.internalMessage || "An error occurred. Please try again.";
      message.error(errorMessage);
    } 
  }
  setLoading(false);
  };
  const handleUpdate = async () => {
    setLoading(true); // Start loading state
    
    // Map JSON data to the payload structure
    const payload: PoultryProductionData[] = mapDataArray(jsonData || []);
    
    try {
      // Step 1: Attempt to delete existing records based on branch and date
      const deleteResponse = await axios.delete(`${poultry_standard}/poultry-production-data/deleteByBranchAndDate`, {
        params: {
          branch: selectedUnit,
          date: formatDate(selectedDate),
        },
      });
  
      // Check if the deletion was successful
      if (deleteResponse.data.internalMessage === "Records successfully deleted.") {
        
        try {
          // Step 2: If deletion was successful, proceed to create/update records
          const res = await axios.post(`${poultry_standard}/poultry-production-data/createPoultryProductionData`, payload);
          
          // Check if the update was successful
          if (res.data.internalMessage === "Records successfully created.") {
            closeEditSection(); // Hide the upload section on success
            message.success("Records successfully updated."); // Show success message
          } else {
            message.error(res.data.internalMessage); // Show error message if update failed
          }
        } catch (error: any) {
          const errorMessage = error.response?.data?.internalMessage || "An error occurred. Please try again.";
          message.error(errorMessage); // Show error message
        }
      } else {
        // Handle errors if deletion failed
        message.error(deleteResponse.data.internalMessage); // Show error message
      }
    } catch (error) {
      // Handle errors during the deletion operation
      console.error("Error deleting data:", error);
      message.error("Failed to delete data. Please try again."); // Show error message
    } finally {
      // End loading state
      setLoading(false);
    }
  };
  


  const headerKeyMap: { [key: string]: keyof PoultryProductionData } = {
    'CC Code': 'ccCode',
    'Date': 'date',
    'Branch': 'branch',
    'House Number': 'houseNumber',
    'Batch Number': 'batchNumber',
    'Breed': 'breed',
    'Age': 'age',
    'Bird Count': 'birdCount',
    'Mortality': 'mortality',
    'Production': 'production',
    'Production Difference': 'productionDifference',
    'Damaged Eggs': 'damagedEggs',
    'Soiled Eggs': 'soiledEggs',
    'Jumbo Eggs': 'jumboEggs',
    'Feed Type': 'feedType',
    'Total Feed Consumed': 'totalFeedConsumed',
    'Body Weight': 'bodyWeight',
    'Bird Value': 'birdValue',
  };
  const excelHeaders = Object.keys(headerKeyMap);
  const handleViewData = async (selCity: string, selDate: string) => {
    setSelectedUnit(selCity);
    setSelectedDate(new Date(selDate));
    setLoading(true);
    // Define a mapping between header names and data keys


    try {
      const dataResponse = await axios.get(`${poultry_standard}/poultry-production-data/getAllPoultryProductionData`);
      const data = dataResponse.data.data;
      console.log("Fetched Data:", data);

      // Filter data for the selected city and date
      const formattedDate = formatDate(new Date(selDate));
      const recordData = data.filter(
        (item: { branch: string; date: string }) =>
          item.branch === selCity && formatDate(new Date(item.date)) === formattedDate
      );

      if (recordData.length > 0) {
        // Map data to include sequential IDs
        const modifiedData = recordData.map((item: any, index: number) => ({
          ...item,
          id: index + 1,
        }));

        // Set headers based on the mapping keys
        setHeaders(Object.keys(headerKeyMap));

        // Set jsonData to the modified data
        setJsonData(modifiedData);
      } else {
        setJsonData([]);
        setHeaders([]);
      }

      setViewData(recordData);

      // Display the view section
      setTimeout(() => {
        const viewSection = document.querySelector('.view-section') as HTMLElement;
        if (viewSection) {
          viewSection.style.display = 'flex';
        }
      }, 0);
    } catch (error) {
      console.error("Error fetching view data:", error);
      message.error("Failed to fetch data. Please try again.");
    } finally {
      setLoading(false);
    }
  };



  const handleDownloadData = () => {
    try {
      if (!jsonData || jsonData.length === 0) {
        message.warning('No data available to download.');
        return;
      }

      const headers = Object.keys(jsonData[0]);
      const worksheet = XLSX.utils.json_to_sheet(jsonData, { header: headers });
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
      const blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = 'data.xlsx';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      message.success('File is ready to download!');
    } catch (error) {
      message.error('Failed to download the file. Please try again.');
      console.error('Download error:', error); // Optional: log error for debugging
    }
  };

  const TempHandleDownload = () => {
    const data = [excelHeaders];
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(dataBlob, 'PoultryDataTemplate.xlsx');
  };




  const handleEditData = (city: string, date: string) => {
    setSelectedUnit(city);
    setSelectedDate(new Date(date));
    setIsEditing(true);
    setTimeout(() => {
      const editSection = document.querySelector('.edit-section') as HTMLElement;
      if (editSection) {
        editSection.style.display = 'flex';
      }
    }, 0);
  };

  const closeViewSection = () => {
    const viewSection = document.querySelector('.view-section') as HTMLElement;
    if (viewSection) {
      viewSection.style.display = 'none';
    }
    setViewData(null);
    setJsonData(null);
  };

  const closeEditSection = () => {
    const editSection = document.querySelector('.edit-section') as HTMLElement;
    if (editSection) {
      editSection.style.display = 'none';
    }
    setJsonData(null);
    setIsEditing(false);
  };

  const months = Array.from({ length: 12 }, (_, i) => ({
    index: i,
    name: new Date(0, i).toLocaleString("en", { month: "long" })
  }));

  const customIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;
  return (

    <div>
      {loading && (
        <div className="fullscreen-spin">
          <Spin indicator={customIcon} />
        </div>
      )}

      <div>
        <div className="filters">
          <div className="monthCon">
            {months.map(({ index, name }) => (
              <div
                key={index}
                onClick={() => handleMonthChange(index)}
                className="monthDiv"
                style={{
                  border: selectedMonth === index ? "2px solid blue" : "1px solid gray",
                  backgroundColor: selectedMonth === index ? getBackgroundColor() : "#f9f9f9",
                }}
              >
                {name}<div>{selectedMonth === index && `(${count} / ${totalMatches})`}</div>
              </div>
            ))}
          </div>
          <div className="dateCon">
            <label htmlFor="year-select">
              Select Year:
              <select id="year-select" value={selectedYear} onChange={handleYearChange}>
                {Array.from({ length: 5 }, (_, i) => (
                  <option key={i} value={new Date().getFullYear() - i}>
                    {new Date().getFullYear() - i}
                  </option>
                ))}
              </select>
            </label>
            <label htmlFor="week-select">
              Select Week (Optional):
              <select
                id="week-select"
                value={selectedWeek || ""}
                onChange={handleWeekChange}
              >
                <option value="">All weeks</option>
                <option value="1">1st Week</option>
                <option value="2">2nd Week</option>
                <option value="3">3rd Week</option>
                <option value="4">4th Week</option>
              </select>
            </label>
            <button onClick={TempHandleDownload} className="dateCon">
              Download Template
            </button>
          </div>
        </div>

        <div className="table-container">
          <table className="table_satya">
            <thead>
              <tr>
                <th>Date/City</th>
                {cities.map((city, index) => (
                  <th key={index}>{city}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dates.map((date, rowIndex) => (
                <tr key={rowIndex}>

                  <td className="dateNames">{date}</td>
                  {cities.map((city, colIndex) => (
                    <td key={colIndex}>
                      {unitData[city] && unitData[city][date] ? (
                        <div className="icon-container">



                          <button
                            className="icon-btn view-btn"
                            onClick={() => handleViewData(city, date)}
                          ></button>

                          <button
                            className="icon-btn edit-btn"
                            onClick={() => handleEditData(city, date)}
                          ></button>
                        </div>
                      ) : (
                        <button
                          onClick={() => handleUploadClick(city, date)}
                          className="upload-btn"
                        ></button>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>

        </div>

      </div>

      {viewData && (
        <div className="view-section">
          <button className="close-btn cancel-btn" onClick={closeViewSection}></button>

          <h3>
            Data for {selectedUnit} on {formatDate(selectedDate)}
          </h3>

          {jsonData && jsonData.length > 0 ? (
            <div className="convData">
              <table className="table_satya">
                <thead>
                  <tr>
                    {headers.map((header, index) => (
                      <th key={index}>{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {jsonData.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {headers.map((header, cellIndex) => (
                        <td key={cellIndex}>{row[headerKeyMap[header]]}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p>No data available for this selection.</p>
          )}
          <button className="icon-btn download-btn" onClick={handleDownloadData}></button>
        </div>
      )}


      <div className="upload-section">
        {selectedUnit && selectedDate && (
          <div className="upload">
            <button className="close-btn cancel-btn" onClick={hideUploadSection}></button>
            <p>Unit Name: {selectedUnit}</p>
            <p>Date: {formatDate(selectedDate)}</p>
            <input type="file" onChange={handleFileChange} className="file-input" />
            {jsonData && (
              <div className="convData">
                <table className="table_satya">
                  <thead>
                    <tr>
                      {headers.map((header, index) => (
                        <th key={index}>{header}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {jsonData.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {headers.map((header, cellIndex) => (
                          <td key={cellIndex}>{row[header]}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            <button
              className="submit-btn"
              style={{ display: jsonData ? "flex" : "none" }}
              onClick={isEditing ? handleUpdate : handleSubmit}
            >
              {isEditing ? "Update Data" : "Upload Data"}
            </button>
          </div>
        )}
      </div>

      {isEditing && (
        <div className="edit-section">
          <button className="close-btn cancel-btn" onClick={closeEditSection}></button>
          <h3>
            Editing Data for  {selectedUnit} on {selectedDate.toDateString()} {/* Use toDateString() to convert the Date object */}
          </h3>

          <input type="file" onChange={handleFileChange} className="file-input" />
          {jsonData && (
            <div className="convData">
              <table className="table_satya">
                <thead>
                  <tr>
                    {headers.map((header, index) => (
                      <th key={index}>{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {jsonData.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {headers.map((header, cellIndex) => (
                        <td key={cellIndex}>{row[header]}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          <button
            className="submit-btn"
            style={{ display: jsonData ? "flex" : "none" }}
            onClick={handleUpdate}
          >
            Update Data
          </button>
        </div>
      )}
    </div>


  );
};

export default UnitExcelUpload;

