export const data = [
    {
        "id": 1,
        "name": "Egg Weight Decrease",
        "notificationText": "The egg weight has decreased by 5% compared to the standard value. Immediate attention required to address possible feed or environmental issues.",
        "severityLevel": "High",
        "severityRate": 8
    },
    {
        "id": 2,
        "name": "Body Weight Increase",
        "notificationText": "The body weight of the birds has increased by 10% above the standard value. Ensure that the feed formulation is adjusted to prevent overgrowth.",
        "severityLevel": "Medium",
        "severityRate": 6
    },
    {
        "id": 3,
        "name": "Mortality Rate High",
        "notificationText": "The mortality rate has exceeded the acceptable threshold by 3%. Investigate the cause and take corrective measures to prevent further losses.",
        "severityLevel": "Medium",
        "severityRate": 9
    },
    {
        "id": 4,
        "name": "Feed Intake Below Standard",
        "notificationText": "The feed intake has dropped by 7% below the standard value. This could indicate health issues or feed quality problems.",
        "severityLevel": "High",
        "severityRate": 7
    },
    {
        "id": 5,
        "name": "Egg Weight Increase",
        "notificationText": "The egg weight has increased by 3% above the standard value. Review feed and management practices to ensure optimal egg production.",
        "severityLevel": "Low",
        "severityRate": 4
    },
    {
        "id": 6,
        "name": "Body Weight Decrease",
        "notificationText": "The body weight of the birds has decreased by 4% below the standard value. Check for possible nutritional deficiencies or health issues.",
        "severityLevel": "Medium",
        "severityRate": 5
    },
    {
        "id": 7,
        "name": "Mortality Rate Normal",
        "notificationText": "The mortality rate is within the acceptable range. Continue to monitor regularly to maintain flock health.",
        "severityLevel": "High",
        "severityRate": 2
    },
    {
        "id": 8,
        "name": "Feed Intake Increase",
        "notificationText": "The feed intake has increased by 5% above the standard value. Ensure that this increase is not due to underlying health issues or feed wastage.",
        "severityLevel": "Medium",
        "severityRate": 6
    }
]








export const data2 = [
    {
      "id": 1,
      "name": "Egg Weight Alert",
      "notificationText": "Egg weight is at the minimum of 56 gms in week 40 at ARJUNDA (Hy-line Brown), 2 gms below standard.",
      "severityLevel": "High",
      "severityRate": 80
    },
    {
      "id": 2,
      "name": "Egg Weight Observation",
      "notificationText": "Egg weight is at the maximum of 63 gms in week 60 at YERRAM PALEM (BABCOCK), 2 gms below standard.",
      "severityLevel": "Medium",
      "severityRate": 85
    },
    {
      "id": 3,
      "name": "Feed Intake Warning",
      "notificationText": "Feed intake is at the minimum of 135 gms in week 40 at ARJUNDA (Hy-line Brown), 10 gms below standard.",
      "severityLevel": "High",
      "severityRate": 75
    },
    {
      "id": 4,
      "name": "Feed Intake Observation",
      "notificationText": "Feed intake is at the maximum of 165 gms in week 60 at YERRAM PALEM (BABCOCK), 5 gms below standard.",
      "severityLevel": "Medium",
      "severityRate": 90
    },
    {
      "id": 5,
      "name": "Body Weight Alert",
      "notificationText": "Body weight is at the minimum of 2.3 kg in weeks 30 and 40 at BHADRAPALI (Bovans) and ARJUNDA (Hy-line Brown), 0.1 kg below standard.",
      "severityLevel": "High",
      "severityRate": 80
    },
    {
      "id": 6,
      "name": "Body Weight Observation",
      "notificationText": "Body weight is at the maximum of 2.7 kg in week 60 at YERRAM PALEM (BABCOCK), 0.1 kg below standard.",
      "severityLevel": "Medium",
      "severityRate": 85
    }
  ] 