export const weekBreedAnalysisData = [
  {
    "unitName": "FARM A",
    "shedName": "1",
    "shedColor": "#cf505d",
    "breed": {
      "breedName": "Boiler Chicken",
      "breedData": [
        {
          "week": "1",
          "feedIntake": {
            "feedIntakeValue": "100 gms",
            "feedIntakeStandardValue": "125 gms"
          },
          "eggWeight": {
            "eggWeightValue": "54 gms",
            "eggWeightStandardValue": "56 gms"
          },
          "bodyWeight": {
            "bodyWeightValue": "2107 gms",
            "bodyWeightStandardValue": "2200 gms"
          }
        },
        {
          "week": "25",
          "feedIntake": {
            "feedIntakeValue": "120 gms",
            "feedIntakeStandardValue": "125 gms"
          },
          "eggWeight": {
            "eggWeightValue": "54 gms",
            "eggWeightStandardValue": "56 gms"
          },
          "bodyWeight": {
            "bodyWeightValue": "2087 gms",
            "bodyWeightStandardValue": "2200 gms"
          }
        },
        {
          "week": "30",
          "feedIntake": {
            "feedIntakeValue": "130 gms",
            "feedIntakeStandardValue": "135 gms"
          },
          "eggWeight": {
            "eggWeightValue": "56 gms",
            "eggWeightStandardValue": "58 gms"
          },
          "bodyWeight": {
            "bodyWeightValue": "2301 gms",
            "bodyWeightStandardValue": "2200 gms"
          }
        },
        {
          "week": "35",
          "feedIntake": {
            "feedIntakeValue": "140 gms",
            "feedIntakeStandardValue": "145 gms"
          },
          "eggWeight": {
            "eggWeightValue": "58 gms",
            "eggWeightStandardValue": "60 gms"
          },
          "bodyWeight": {
            "bodyWeightValue": "2125 gms",
            "bodyWeightStandardValue": "2200 gms"
          }
        }
      ]
    }
  },

  {
    "unitName": "FARM A",
    "shedName": "2",
    "shedColor": "#d6ad53",
    "breed": {
      "breedName": "Boiler Chicken",
      "breedData": [
        {
          "week": "40",
          "feedIntake": {
            "feedIntakeValue": "150 gms",
            "feedIntakeStandardValue": "155 gms"
          },
          "eggWeight": {
            "eggWeightValue": "60 gms",
            "eggWeightStandardValue": "62 gms"
          },
          "bodyWeight": {
            "bodyWeightValue": "1794 gms",
            "bodyWeightStandardValue": "2200 gms"
          }
        },
        {
          "week": "45",
          "feedIntake": {
            "feedIntakeValue": "155 gms",
            "feedIntakeStandardValue": "160 gms"
          },
          "eggWeight": {
            "eggWeightValue": "62 gms",
            "eggWeightStandardValue": "64 gms"
          },
          "bodyWeight": {
            "bodyWeightValue": "1976 gms",
            "bodyWeightStandardValue": "2200 gms"
          }
        },
        {
          "week": "50",
          "feedIntake": {
            "feedIntakeValue": "160 gms",
            "feedIntakeStandardValue": "165 gms"
          },
          "eggWeight": {
            "eggWeightValue": "64 gms",
            "eggWeightStandardValue": "66 gms"
          },
          "bodyWeight": {
            "bodyWeightValue": "2007 gms",
            "bodyWeightStandardValue": "2200 gms"
          }
        }
      ]
    }
  },

  {
    "unitName": "FARM B",
    "shedName": "3",
    "shedColor": "#57adba",
    "breed": {
      "breedName": "Boiler Chicken",
      "breedData": [
        {
          "week": "20",
          "feedIntake": {
            "feedIntakeValue": "110 gms",
            "feedIntakeStandardValue": "115 gms"
          },
          "eggWeight": {
            "eggWeightValue": "50 gms",
            "eggWeightStandardValue": "52 gms"
          },
          "bodyWeight": {
            "bodyWeightValue": "2004 gms",
            "bodyWeightStandardValue": "2200 gms"
          }
        },
        {
          "week": "25",
          "feedIntake": {
            "feedIntakeValue": "120 gms",
            "feedIntakeStandardValue": "125 gms"
          },
          "eggWeight": {
            "eggWeightValue": "52 gms",
            "eggWeightStandardValue": "54 gms"
          },
          "bodyWeight": {
            "bodyWeightValue": "2200 gms",
            "bodyWeightStandardValue": "2200 gms"
          }
        },
        {
          "week": "30",
          "feedIntake": {
            "feedIntakeValue": "130 gms",
            "feedIntakeStandardValue": "135 gms"
          },
          "eggWeight": {
            "eggWeightValue": "54 gms",
            "eggWeightStandardValue": "56 gms"
          },
          "bodyWeight": {
            "bodyWeightValue": "2187 gms",
            "bodyWeightStandardValue": "2200 gms"
          }
        }
      ]
    }
  },

  {
    "unitName": "FARM B",
    "shedName": "4",
    "shedColor": "#cf505d",
    "breed": {
      "breedName": "Boiler Chicken",
      "breedData": [
        {
          "week": "30",
          "feedIntake": {
            "feedIntakeValue": "140 gms",
            "feedIntakeStandardValue": "145 gms"
          },
          "eggWeight": {
            "eggWeightValue": "58 gms",
            "eggWeightStandardValue": "60 gms"
          },
          "bodyWeight": {
            "bodyWeightValue": "2601 gms",
            "bodyWeightStandardValue": "2200 gms"
          }
        },
        {
          "week": "35",
          "feedIntake": {
            "feedIntakeValue": "145 gms",
            "feedIntakeStandardValue": "150 gms"
          },
          "eggWeight": {
            "eggWeightValue": "60 gms",
            "eggWeightStandardValue": "62 gms"
          },
          "bodyWeight": {
            "bodyWeightValue": "2164 gms",
            "bodyWeightStandardValue": "2200 gms"
          }
        },
        {
          "week": "40",
          "feedIntake": {
            "feedIntakeValue": "150 gms",
            "feedIntakeStandardValue": "155 gms"
          },
          "eggWeight": {
            "eggWeightValue": "62 gms",
            "eggWeightStandardValue": "64 gms"
          },
          "bodyWeight": {
            "bodyWeightValue": "2741 gms",
            "bodyWeightStandardValue": "2200 gms"
          }
        }
      ]
    }
  },

  {
    "unitName": "FARM C",
    "shedName": "5",
    "shedColor": "#57adba",
    "breed": {
      "breedName": "Broiler Chicken",
      "breedData": [
        {
          "week": "1",
          "feedIntake": {
            "feedIntakeValue": "105 gms",
            "feedIntakeStandardValue": "120 gms"
          },
          "eggWeight": {
            "eggWeightValue": "50 gms",
            "eggWeightStandardValue": "52 gms"
          },
          "bodyWeight": {
            "bodyWeightValue": "2000 gms",
            "bodyWeightStandardValue": "2100 gms"
          }
        },
        {
          "week": "20",
          "feedIntake": {
            "feedIntakeValue": "130 gms",
            "feedIntakeStandardValue": "135 gms"
          },
          "eggWeight": {
            "eggWeightValue": "54 gms",
            "eggWeightStandardValue": "56 gms"
          },
          "bodyWeight": {
            "bodyWeightValue": "2300 gms",
            "bodyWeightStandardValue": "2400 gms"
          }
        }
      ]
    }
  },

  {
    "unitName": "FARM C",
    "shedName": "6",
    "shedColor": "#cf505d",
    "breed": {
      "breedName": "Layer Chicken",
      "breedData": [
        {
          "week": "1",
          "feedIntake": {
            "feedIntakeValue": "95 gms",
            "feedIntakeStandardValue": "105 gms"
          },
          "eggWeight": {
            "eggWeightValue": "47 gms",
            "eggWeightStandardValue": "51 gms"
          },
          "bodyWeight": {
            "bodyWeightValue": "1750 gms",
            "bodyWeightStandardValue": "1850 gms"
          }
        },
        {
          "week": "15",
          "feedIntake": {
            "feedIntakeValue": "110 gms",
            "feedIntakeStandardValue": "115 gms"
          },
          "eggWeight": {
            "eggWeightValue": "49 gms",
            "eggWeightStandardValue": "53 gms"
          },
          "bodyWeight": {
            "bodyWeightValue": "1950 gms",
            "bodyWeightStandardValue": "2050 gms"
          }
        }
      ]
    }
  },

  {
    "unitName": "FARM D",
    "shedName": "7",
    "shedColor": "#d6ad53",
    "breed": {
      "breedName": "Turkeys",
      "breedData": [
        {
          "week": "1",
          "feedIntake": {
            "feedIntakeValue": "200 gms",
            "feedIntakeStandardValue": "210 gms"
          },
          "eggWeight": {
            "eggWeightValue": "60 gms",
            "eggWeightStandardValue": "65 gms"
          },
          "bodyWeight": {
            "bodyWeightValue": "3000 gms",
            "bodyWeightStandardValue": "3100 gms"
          }
        },
        {
          "week": "30",
          "feedIntake": {
            "feedIntakeValue": "250 gms",
            "feedIntakeStandardValue": "260 gms"
          },
          "eggWeight": {
            "eggWeightValue": "65 gms",
            "eggWeightStandardValue": "70 gms"
          },
          "bodyWeight": {
            "bodyWeightValue": "4000 gms",
            "bodyWeightStandardValue": "4100 gms"
          }
        }
      ]
    }
  },

  {
    "unitName": "FARM D",
    "shedName": "8",
    "shedColor": "#d6ad53",
    "breed": {
      "breedName": "Ducks",
      "breedData": [
        {
          "week": "1",
          "feedIntake": {
            "feedIntakeValue": "150 gms",
            "feedIntakeStandardValue": "160 gms"
          },
          "eggWeight": {
            "eggWeightValue": "55 gms",
            "eggWeightStandardValue": "58 gms"
          },
          "bodyWeight": {
            "bodyWeightValue": "2200 gms",
            "bodyWeightStandardValue": "2300 gms"
          }
        },
        {
          "week": "15",
          "feedIntake": {
            "feedIntakeValue": "180 gms",
            "feedIntakeStandardValue": "190 gms"
          },
          "eggWeight": {
            "eggWeightValue": "58 gms",
            "eggWeightStandardValue": "62 gms"
          },
          "bodyWeight": {
            "bodyWeightValue": "2500 gms",
            "bodyWeightStandardValue": "2600 gms"
          }
        }
      ]
    }
  },

  {
    "unitName": "FARM E",
    "shedName": "9",
    "shedColor": "#cf505d",
    "breed": {
      "breedName": "Quail",
      "breedData": [
        {
          "week": "1",
          "feedIntake": {
            "feedIntakeValue": "70 gms",
            "feedIntakeStandardValue": "80 gms"
          },
          "eggWeight": {
            "eggWeightValue": "20 gms",
            "eggWeightStandardValue": "22 gms"
          },
          "bodyWeight": {
            "bodyWeightValue": "200 gms",
            "bodyWeightStandardValue": "210 gms"
          }
        },
        {
          "week": "8",
          "feedIntake": {
            "feedIntakeValue": "90 gms",
            "feedIntakeStandardValue": "100 gms"
          },
          "eggWeight": {
            "eggWeightValue": "22 gms",
            "eggWeightStandardValue": "24 gms"
          },
          "bodyWeight": {
            "bodyWeightValue": "250 gms",
            "bodyWeightStandardValue": "260 gms"
          }
        }
      ]
    }
  },

  {
    "unitName": "FARM E",
    "shedName": "10",
    "shedColor": "#57adba",
    "breed": {
      "breedName": "Guinea Fowl",
      "breedData": [
        {
          "week": "1",
          "feedIntake": {
            "feedIntakeValue": "110 gms",
            "feedIntakeStandardValue": "120 gms"
          },
          "eggWeight": {
            "eggWeightValue": "50 gms",
            "eggWeightStandardValue": "55 gms"
          },
          "bodyWeight": {
            "bodyWeightValue": "2400 gms",
            "bodyWeightStandardValue": "2500 gms"
          }
        },
        {
          "week": "12",
          "feedIntake": {
            "feedIntakeValue": "140 gms",
            "feedIntakeStandardValue": "150 gms"
          },
          "eggWeight": {
            "eggWeightValue": "55 gms",
            "eggWeightStandardValue": "60 gms"
          },
          "bodyWeight": {
            "bodyWeightValue": "2700 gms",
            "bodyWeightStandardValue": "2800 gms"
          }
        }
      ]
    }
  }
];
