// export const rootCauseAnalysisData = [
//     {
//         "unitName": "BHADRAPALI",
//         "vaccination": {
//             "VaccinationDeviationRate": "15%",
//             "vaccinationStandardValue": "23%"
//         },
//         "mortality": {
//             "mortalityRate": "1.2%",
//             "mortalityStandardValue": "1%"
//         },
//         "damage": {
//             "damageRate": "5%",
//             "damageStandardValue": "4.5%"
//         },
//         "soiled": {
//             "soiledRate": "3%",
//             "soiledStandardValue": "2.5%"
//         },
//         "feedIntake": {
//             "feedIntakeRate": "70%",
//             "feedIntakeStandardValue": "75%"
//         }
//     },
//     {
//         "unitName": "ARJUNDA",
//         "vaccination": {
//             "VaccinationDeviationRate": "20%",
//             "vaccinationStandardValue": "23%"
//         },
//         "mortality": {
//             "mortalityRate": "1.1%",
//             "mortalityStandardValue": "1%"
//         },
//         "damage": {
//             "damageRate": "4.5%",
//             "damageStandardValue": "4%"
//         },
//         "soiled": {
//             "soiledRate": "2.8%",
//             "soiledStandardValue": "2.5%"
//         },
//         "feedIntake": {
//             "feedIntakeRate": "72%",
//             "feedIntakeStandardValue": "76%"
//         }
//     },
//     {
//         "unitName": "YERRAM PALEM",
//         "vaccination": {
//             "VaccinationDeviationRate": "18%",
//             "vaccinationStandardValue": "23%"
//         },
//         "mortality": {
//             "mortalityRate": "1.3%",
//             "mortalityStandardValue": "1%"
//         },
//         "damage": {
//             "damageRate": "5.2%",
//             "damageStandardValue": "4.5%"
//         },
//         "soiled": {
//             "soiledRate": "3.2%",
//             "soiledStandardValue": "3%"
//         },
//         "feedIntake": {
//             "feedIntakeRate": "68%",
//             "feedIntakeStandardValue": "70%"
//         }
//     },
//     {
//         "unitName": "Beach farm",
//         "vaccination": {
//             "VaccinationDeviationRate": "18%",
//             "vaccinationStandardValue": "23%"
//         },
//         "mortality": {
//             "mortalityRate": "1%",
//             "mortalityStandardValue": "0.9%"
//         },
//         "damage": {
//             "damageRate": "4.8%",
//             "damageStandardValue": "4.5%"
//         },
//         "soiled": {
//             "soiledRate": "2.9%",
//             "soiledStandardValue": "2.5%"
//         },
//         "feedIntake": {
//             "feedIntakeRate": "71%",
//             "feedIntakeStandardValue": "73%"
//         }
//     },

//     {
//         unitName: "Unit 6",
//         vaccination: { VaccinationDeviationRate: "14.8%", vaccinationStandardValue: "23.0%" },
//         mortality: { mortalityRate: "1.2%", mortalityStandardValue: "1%" },
//         damage: { damageRate: "6.7%", damageStandardValue: "4.5%" },
//         soiled: { soiledRate: "4.5%", soiledStandardValue: "2.5%" },
//         feedIntake: { feedIntakeRate: "10.2%", feedIntakeStandardValue: "70.0%" },
//     },
//     {
//         unitName: "Unit 7",
//         vaccination: { VaccinationDeviationRate: "7.2%", vaccinationStandardValue: "23.0%" },
//         mortality: { mortalityRate: "1.4%", mortalityStandardValue: "1%" },
//         damage: { damageRate: "3.2%", damageStandardValue: "4.5%" },
//         soiled: { soiledRate: "2.2%", soiledStandardValue: "2.5%" },
//         feedIntake: { feedIntakeRate: "10.2%", feedIntakeStandardValue: "70.0%" },
//     },
//     {
//         unitName: "Unit 8",
//         vaccination: { VaccinationDeviationRate: "20.5%", vaccinationStandardValue: "23.0%" },
//         mortality: { mortalityRate: "2.6%", mortalityStandardValue: "1%" },
//         damage: { damageRate: "1.3%", damageStandardValue: "4.5%" },
//         soiled: { soiledRate: "3.5%", soiledStandardValue: "2.5%" },
//         feedIntake: { feedIntakeRate: "10.2%", feedIntakeStandardValue: "70.0%" },
//     },
//     {
//         unitName: "Unit 9",
//         vaccination: { VaccinationDeviationRate: "4.3%", vaccinationStandardValue: "23.0%" },
//         mortality: { mortalityRate: "0.8%", mortalityStandardValue: "1%" },
//         damage: { damageRate: "3.4%", damageStandardValue: "4.5%" },
//         soiled: { soiledRate: "1.5%", soiledStandardValue: "2.5%" },
//         feedIntake: { feedIntakeRate: "10.2%", feedIntakeStandardValue: "70.0%" },
//     },
//     {
//         unitName: "Unit 10",
//         vaccination: { VaccinationDeviationRate: "12.0%", vaccinationStandardValue: "23.0%" },
//         mortality: { mortalityRate: "1.2%", mortalityStandardValue: "1%" },
//         damage: { damageRate: "7.0%", damageStandardValue: "4.5%" },
//         soiled: { soiledRate: "1.8%", soiledStandardValue: "2.5%" },
//         feedIntake: { feedIntakeRate: "10.2%", feedIntakeStandardValue: "100.0%" },
//     },
// ]
// {
//     unitName: "Unit 11",
//     vaccination: { VaccinationDeviationRate: "9.9%", vaccinationStandardValue: "16.0%" },
//     mortality: { mortalityRate: "16.7%", mortalityStandardValue: "18.0%" },
//     damage: { damageRate: "11.1%", damageStandardValue: "24.0%" },
//     soiled: { soiledRate: "2.3%", soiledStandardValue: "10.0%" },
//     feedIntake: { feedIntakeRate: "10.2%", feedIntakeStandardValue: "100.0%" },
// },
// {
//     unitName: "Unit 12",
//     vaccination: { VaccinationDeviationRate: "7.5%", vaccinationStandardValue: "16.0%" },
//     mortality: { mortalityRate: "10.9%", mortalityStandardValue: "18.0%" },
//     damage: { damageRate: "8.3%", damageStandardValue: "24.0%" },
//     soiled: { soiledRate: "3.3%", soiledStandardValue: "10.0%" },
//     feedIntake: { feedIntakeRate: "10.2%", feedIntakeStandardValue: "100.0%" },
// },
// {
//     unitName: "Unit 13",
//     vaccination: { VaccinationDeviationRate: "6.8%", vaccinationStandardValue: "16.0%" },
//     mortality: { mortalityRate: "18.0%", mortalityStandardValue: "18.0%" },
//     damage: { damageRate: "13.6%", damageStandardValue: "24.0%" },
//     soiled: { soiledRate: "2.1%", soiledStandardValue: "10.0%" },
//     feedIntake: { feedIntakeRate: "10.2%", feedIntakeStandardValue: "100.0%" },
// },
// {
//     unitName: "Unit 14",
//     vaccination: { VaccinationDeviationRate: "4.9%", vaccinationStandardValue: "16.0%" },
//     mortality: { mortalityRate: "13.3%", mortalityStandardValue: "18.0%" },
//     damage: { damageRate: "9.4%", damageStandardValue: "24.0%" },
//     soiled: { soiledRate: "2.4%", soiledStandardValue: "10.0%" },
//     feedIntake: { feedIntakeRate: "10.2%", feedIntakeStandardValue: "100.0%" },
// },
// {
//     unitName: "Unit 16",
//     vaccination: { VaccinationDeviationRate: "0.7%", vaccinationStandardValue: "16.0%" },
//     mortality: { mortalityRate: "16.9%", mortalityStandardValue: "18.0%" },
//     damage: { damageRate: "10.7%", damageStandardValue: "24.0%" },
//     soiled: { soiledRate: "1.7%", soiledStandardValue: "10.0%" },
//     feedIntake: { feedIntakeRate: "10.2%", feedIntakeStandardValue: "100.0%" },
// },
// {
//     unitName: "Unit 16",
//     vaccination: { VaccinationDeviationRate: "0.1%", vaccinationStandardValue: "16.0%" },
//     mortality: { mortalityRate: "16.5%", mortalityStandardValue: "18.0%" },
//     damage: { damageRate: "12.2%", damageStandardValue: "24.0%" },
//     soiled: { soiledRate: "1.2%", soiledStandardValue: "10.0%" },
//     feedIntake: { feedIntakeRate: "10.2%", feedIntakeStandardValue: "100.0%" },
// },
// {
//     unitName: "Unit 17",
//     vaccination: { VaccinationDeviationRate: "0.2%", vaccinationStandardValue: "16.0%" },
//     mortality: { mortalityRate: "11.7%", mortalityStandardValue: "18.0%" },
//     damage: { damageRate: "8.6%", damageStandardValue: "24.0%" },
//     soiled: { soiledRate: "3.5%", soiledStandardValue: "10.0%" },
//     feedIntake: { feedIntakeRate: "10.2%", feedIntakeStandardValue: "100.0%" },
// },
// {
//     unitName: "Unit 18",
//     vaccination: { VaccinationDeviationRate: "8.7%", vaccinationStandardValue: "16.0%" },
//     mortality: { mortalityRate: "17.1%", mortalityStandardValue: "18.0%" },
//     damage: { damageRate: "13.8%", damageStandardValue: "24.0%" },
//     soiled: { soiledRate: "1.4%", soiledStandardValue: "10.0%" },
//     feedIntake: { feedIntakeRate: "10.2%", feedIntakeStandardValue: "100.0%" },
// },
// {
//     unitName: "Unit 19",
//     vaccination: { VaccinationDeviationRate: "0.8%", vaccinationStandardValue: "16.0%" },
//     mortality: { mortalityRate: "12.8%", mortalityStandardValue: "18.0%" },
//     damage: { damageRate: "9.5%", damageStandardValue: "24.0%" },
//     soiled: { soiledRate: "3.9%", soiledStandardValue: "10.0%" },
//     feedIntake: { feedIntakeRate: "10.2%", feedIntakeStandardValue: "100.0%" },
// },
// {
//     unitName: "Unit 20",
//     vaccination: { VaccinationDeviationRate: "5.0%", vaccinationStandardValue: "16.0%" },
//     mortality: { mortalityRate: "14.3%", mortalityStandardValue: "18.0%" },
//     damage: { damageRate: "7.7%", damageStandardValue: "24.0%" },
//     soiled: { soiledRate: "3.0%", soiledStandardValue: "10.0%" },
//     feedIntake: { feedIntakeRate: "10.2%", feedIntakeStandardValue: "100.0%" },
// },
// {
//     unitName: "Unit 21",
//     vaccination: { VaccinationDeviationRate: "0.3%", vaccinationStandardValue: "16.0%" },
//     mortality: { mortalityRate: "17.6%", mortalityStandardValue: "18.0%" },
//     damage: { damageRate: "11.4%", damageStandardValue: "24.0%" },
//     soiled: { soiledRate: "2.5%", soiledStandardValue: "10.0%" },
//     feedIntake: { feedIntakeRate: "10.2%", feedIntakeStandardValue: "100.0%" },
// }





































export const rootCauseAnalysisData = [
    {
        unitName: "Unit 1",
        vaccination: {
            VaccinationDeviationRate: "5%",
            vaccinationStandardValue: "23%",
            vaccinationMinValue: "20%",
            vaccinationMaxValue: "30%"
        },
        mortality: {
            mortalityRate: "1.5%",
            mortalityStandardValue: "3%",
            mortalityMinValue: "2%",
            mortalityMaxValue: "5%"
        },
        damage: {
            damageRate: "3.2%",
            damageStandardValue: "5%",
            damageMinValue: "4%",
            damageMaxValue: "7%"
        },
        feedIntake: {
            feedIntakeRate: "5.5%",
            feedIntakeStandardValue: "8%",
            feedIntakeMinValue: "6%",
            feedIntakeMaxValue: "9%"
        }
    },
    {
        unitName: "Unit 2",
        vaccination: {
            VaccinationDeviationRate: "12%",
            vaccinationStandardValue: "23%",
            vaccinationMinValue: "20%",
            vaccinationMaxValue: "25%"
        },
        mortality: {
            mortalityRate: "1.8%",
            mortalityStandardValue: "3%",
            mortalityMinValue: "2%",
            mortalityMaxValue: "4%"
        },
        damage: {
            damageRate: "3.5%",
            damageStandardValue: "5%",
            damageMinValue: "4%",
            damageMaxValue: "6%"
        },
        feedIntake: {
            feedIntakeRate: "7.8%",
            feedIntakeStandardValue: "8%",
            feedIntakeMinValue: "7%",
            feedIntakeMaxValue: "8.5%"
        }
    },
    {
        unitName: "Unit 3",
        vaccination: {
            VaccinationDeviationRate: "18%",
            vaccinationStandardValue: "23%",
            vaccinationMinValue: "22%",
            vaccinationMaxValue: "30%"
        },
        mortality: {
            mortalityRate: "3.4%",
            mortalityStandardValue: "3%",
            mortalityMinValue: "2%",
            mortalityMaxValue: "4%"
        },
        damage: {
            damageRate: "7.1%",
            damageStandardValue: "5%",
            damageMinValue: "5%",
            damageMaxValue: "8%"
        },
        feedIntake: {
            feedIntakeRate: "6.5%",
            feedIntakeStandardValue: "8%",
            feedIntakeMinValue: "6%",
            feedIntakeMaxValue: "7.5%"
        }
    },
    {
        unitName: "Unit 4",
        vaccination: {
            VaccinationDeviationRate: "16%",
            vaccinationStandardValue: "23%",
            vaccinationMinValue: "21%",
            vaccinationMaxValue: "29%"
        },
        mortality: {
            mortalityRate: "5.1%",
            mortalityStandardValue: "3%",
            mortalityMinValue: "3%",
            mortalityMaxValue: "6%"
        },
        damage: {
            damageRate: "4.8%",
            damageStandardValue: "5%",
            damageMinValue: "4%",
            damageMaxValue: "6%"
        },
        feedIntake: {
            feedIntakeRate: "8.2%",
            feedIntakeStandardValue: "8%",
            feedIntakeMinValue: "7%",
            feedIntakeMaxValue: "9%"
        }
    },
    {
        unitName: "Unit 5",
        vaccination: {
            VaccinationDeviationRate: "14%",
            vaccinationStandardValue: "23%",
            vaccinationMinValue: "20%",
            vaccinationMaxValue: "27%"
        },
        mortality: {
            mortalityRate: "3.6%",
            mortalityStandardValue: "3%",
            mortalityMinValue: "2%",
            mortalityMaxValue: "4%"
        },
        damage: {
            damageRate: "5.9%",
            damageStandardValue: "5%",
            damageMinValue: "4%",
            damageMaxValue: "6%"
        },
        feedIntake: {
            feedIntakeRate: "7.1%",
            feedIntakeStandardValue: "8%",
            feedIntakeMinValue: "6%",
            feedIntakeMaxValue: "8%"
        }
    },
    {
        unitName: "Unit 6",
        vaccination: {
            VaccinationDeviationRate: "27%",
            vaccinationStandardValue: "23%",
            vaccinationMinValue: "22%",
            vaccinationMaxValue: "30%"
        },
        mortality: {
            mortalityRate: "4.0%",
            mortalityStandardValue: "3%",
            mortalityMinValue: "2%",
            mortalityMaxValue: "5%"
        },
        damage: {
            damageRate: "6.0%",
            damageStandardValue: "5%",
            damageMinValue: "4%",
            damageMaxValue: "7%"
        },
        feedIntake: {
            feedIntakeRate: "8.0%",
            feedIntakeStandardValue: "8%",
            feedIntakeMinValue: "7%",
            feedIntakeMaxValue: "9%"
        }
    },
    {
        unitName: "Unit 7",
        vaccination: {
            VaccinationDeviationRate: "29%",
            vaccinationStandardValue: "23%",
            vaccinationMinValue: "25%",
            vaccinationMaxValue: "30%"
        },
        mortality: {
            mortalityRate: "3.1%",
            mortalityStandardValue: "3%",
            mortalityMinValue: "2%",
            mortalityMaxValue: "4%"
        },
        damage: {
            damageRate: "5.4%",
            damageStandardValue: "5%",
            damageMinValue: "4%",
            damageMaxValue: "6%"
        },
        feedIntake: {
            feedIntakeRate: "7.3%",
            feedIntakeStandardValue: "8%",
            feedIntakeMinValue: "6%",
            feedIntakeMaxValue: "8%"
        }
    },
    {
        unitName: "Unit 8",
        vaccination: {
            VaccinationDeviationRate: "21%",
            vaccinationStandardValue: "23%",
            vaccinationMinValue: "20%",
            vaccinationMaxValue: "24%"
        },
        mortality: {
            mortalityRate: "4.7%",
            mortalityStandardValue: "3%",
            mortalityMinValue: "3%",
            mortalityMaxValue: "5%"
        },
        damage: {
            damageRate: "6.5%",
            damageStandardValue: "5%",
            damageMinValue: "5%",
            damageMaxValue: "7%"
        },
        feedIntake: {
            feedIntakeRate: "8.1%",
            feedIntakeStandardValue: "8%",
            feedIntakeMinValue: "7%",
            feedIntakeMaxValue: "9%"
        }
    },
    {
        unitName: "Unit 9",
        vaccination: {
            VaccinationDeviationRate: "23%",
            vaccinationStandardValue: "23%",
            vaccinationMinValue: "21%",
            vaccinationMaxValue: "26%"
        },
        mortality: {
            mortalityRate: "2.9%",
            mortalityStandardValue: "3%",
            mortalityMinValue: "2%",
            mortalityMaxValue: "4%"
        },
        damage: {
            damageRate: "4.7%",
            damageStandardValue: "5%",
            damageMinValue: "4%",
            damageMaxValue: "6%"
        },
        feedIntake: {
            feedIntakeRate: "7.6%",
            feedIntakeStandardValue: "8%",
            feedIntakeMinValue: "7%",
            feedIntakeMaxValue: "8.5%"
        }
    },
    {
        unitName: "Unit 10",
        vaccination: {
            VaccinationDeviationRate: "25%",
            vaccinationStandardValue: "23%",
            vaccinationMinValue: "22%",
            vaccinationMaxValue: "28%"
        },
        mortality: {
            mortalityRate: "3.3%",
            mortalityStandardValue: "3%",
            mortalityMinValue: "2%",
            mortalityMaxValue: "4%"
        },
        damage: {
            damageRate: "6.8%",
            damageStandardValue: "5%",
            damageMinValue: "5%",
            damageMaxValue: "7%"
        },
        feedIntake: {
            feedIntakeRate: "8.4%",
            feedIntakeStandardValue: "8%",
            feedIntakeMinValue: "7%",
            feedIntakeMaxValue: "9%"
        }
    },
    {
        unitName: "Unit 11",
        vaccination: {
            VaccinationDeviationRate: "27%",
            vaccinationStandardValue: "23%",
            vaccinationMinValue: "23%",
            vaccinationMaxValue: "30%"
        },
        mortality: {
            mortalityRate: "4.8%",
            mortalityStandardValue: "3%",
            mortalityMinValue: "3%",
            mortalityMaxValue: "5%"
        },
        damage: {
            damageRate: "5.6%",
            damageStandardValue: "5%",
            damageMinValue: "4%",
            damageMaxValue: "6%"
        },
        feedIntake: {
            feedIntakeRate: "7.9%",
            feedIntakeStandardValue: "8%",
            feedIntakeMinValue: "7%",
            feedIntakeMaxValue: "8.5%"
        }
    },
    {
        unitName: "Unit 12",
        vaccination: {
            VaccinationDeviationRate: "20%",
            vaccinationStandardValue: "23%",
            vaccinationMinValue: "19%",
            vaccinationMaxValue: "22%"
        },
        mortality: {
            mortalityRate: "4.2%",
            mortalityStandardValue: "3%",
            mortalityMinValue: "3%",
            mortalityMaxValue: "5%"
        },
        damage: {
            damageRate: "6.0%",
            damageStandardValue: "5%",
            damageMinValue: "5%",
            damageMaxValue: "7%"
        },
        feedIntake: {
            feedIntakeRate: "7.7%",
            feedIntakeStandardValue: "8%",
            feedIntakeMinValue: "7%",
            feedIntakeMaxValue: "8.5%"
        }
    },
    {
        unitName: "Unit 13",
        vaccination: {
            VaccinationDeviationRate: "28%",
            vaccinationStandardValue: "23%",
            vaccinationMinValue: "25%",
            vaccinationMaxValue: "30%"
        },
        mortality: {
            mortalityRate: "3.0%",
            mortalityStandardValue: "3%",
            mortalityMinValue: "2%",
            mortalityMaxValue: "4%"
        },
        damage: {
            damageRate: "5.7%",
            damageStandardValue: "5%",
            damageMinValue: "4%",
            damageMaxValue: "6%"
        },
        feedIntake: {
            feedIntakeRate: "8.3%",
            feedIntakeStandardValue: "8%",
            feedIntakeMinValue: "7%",
            feedIntakeMaxValue: "9%"
        }
    },
    {
        unitName: "Unit 14",
        vaccination: {
            VaccinationDeviationRate: "26%",
            vaccinationStandardValue: "23%",
            vaccinationMinValue: "22%",
            vaccinationMaxValue: "28%"
        },
        mortality: {
            mortalityRate: "4.4%",
            mortalityStandardValue: "3%",
            mortalityMinValue: "3%",
            mortalityMaxValue: "5%"
        },
        damage: {
            damageRate: "6.4%",
            damageStandardValue: "5%",
            damageMinValue: "5%",
            damageMaxValue: "7%"
        },
        feedIntake: {
            feedIntakeRate: "7.5%",
            feedIntakeStandardValue: "8%",
            feedIntakeMinValue: "6%",
            feedIntakeMaxValue: "8.5%"
        }
    },
    {
        unitName: "Unit 15",
        vaccination: {
            VaccinationDeviationRate: "28%",
            vaccinationStandardValue: "23%",
            vaccinationMinValue: "21%",
            vaccinationMaxValue: "26%"
        },
        mortality: {
            mortalityRate: "4.9%",
            mortalityStandardValue: "3%",
            mortalityMinValue: "3%",
            mortalityMaxValue: "5%"
        },
        damage: {
            damageRate: "6.1%",
            damageStandardValue: "5%",
            damageMinValue: "5%",
            damageMaxValue: "7%"
        },
        feedIntake: {
            feedIntakeRate: "7.8%",
            feedIntakeStandardValue: "8%",
            feedIntakeMinValue: "7%",
            feedIntakeMaxValue: "8.5%"
        }
    },
];
