import React from 'react';
import './BirdInfoCard.css';

export interface BirdInfo {
  breedName: string;
  breedImg: string;
  stockingDate: string;
  birdAge: string;
  shedName: string;
  hatchedDate: string;
}

export interface BirdInfoProps {
  birdInfoData: BirdInfo[];
}

const BirdInfoCard: React.FC<BirdInfoProps> = ({ birdInfoData }) => {
  return (
    <div className='birdInfoCard-container' >
      <p style={{ fontSize: "18px",fontWeight:"700", textAlign: "center", marginTop: "-7px" }} >
        Breed Info
      </p>
      <div className='birdInfoContainer' >
        {birdInfoData.map((info, index) => (
          <div key={index} className="breed-card">
            <div className="card-header">
              <img src={info.breedImg} alt="Chicken Icon" className="icon" />
              <div className="breed-details">
                <div className="breed-title">Breed Name</div>
                <div className="breed-name">{info.breedName}</div>
              </div>
            </div>
            <div className="card-body">
              <div className="info-row">
                <div className="info-title">Stocking Date</div>
                <div className="info-title">Shed Name</div>
              </div>
              <div className="info-row">
                <div className="info-value">{info.stockingDate}</div>
                <div className="info-value">{info.shedName}</div>
              </div>
              <div className="info-row">
                <div className="info-title">Bird's Age</div>
                <div className="info-title">Hatched Date</div>
              </div>
              <div className="info-row">
                <div className="info-value">{info.birdAge}</div>
                <div className="info-value">{info.hatchedDate}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};


export default BirdInfoCard;
