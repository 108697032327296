export const dashboardData = {
  Day: {
    netProfit: {
      amount: "10,000",
      icon: "/Images/BusinessStaticIcons/net-profit.png",
    },
    expenditures: {
      amount: "10,000",
      icon: "/Images/BusinessStaticIcons/spending.png",
    },
    operationalProfit: {
      amount: "10,000",
      icon: "/Images/BusinessStaticIcons/financial-operation.png",
    },
    revenue: {
      amount: "10,000",
      icon: "/Images/BusinessStaticIcons/income-stream.png",
    },
    workingCapitalExpenditure: {
      amount: "10,000",
      icon: "/Images/BusinessStaticIcons/financial-operation.png",
    },
    expenses: [
      { name: "Workers Salary", amount: 72000 },
      { name: "PAYE", amount: 56000 },
      { name: "Maintenance", amount: 74000 },
      { name: "Utility", amount: 35000 },
      { name: "Transportation", amount: 35000 },
      { name: "Veterinary Service Fee", amount: 88000 },
      { name: "Security", amount: 45000 },
    ],
  },
  Week: {
    netProfit: {
      amount: 0,
      icon: "/Images/BusinessStaticIcons/net-profit.png",
    },
    expenditures: {
      amount: 0,
      icon: "/Images/BusinessStaticIcons/spending.png",
    },
    operationalProfit: {
      amount: 0,
      icon: "/Images/BusinessStaticIcons/financial-operation.png",
    },
    revenue: {
      amount: 0,
      icon: "/Images/BusinessStaticIcons/income-stream.png",
    },
    workingCapitalExpenditure: {
      amount: 0,
      icon: "/Images/BusinessStaticIcons/financial-operation.png",
    },
    expenses: [
      { name: "Workers Salary", amount: 72000 },
      { name: "PAYE", amount: 56000 },
      { name: "Maintenance", amount: 74000 },
      { name: "Utility", amount: 35000 },
      { name: "Transportation", amount: 35000 },
      { name: "Veterinary Service Fee", amount: 88000 },
      { name: "Security", amount: 45000 },
    ],
  },
  Monthly: {
    netProfit: {
      amount: 11919000,
      icon: "/Images/BusinessStaticIcons/net-profit.png",
    },
    expenditures: {
      amount: 47024000,
      icon: "/Images/BusinessStaticIcons/spending.png",
    },
    operationalProfit: {
      amount: 11456000,
      icon: "/Images/BusinessStaticIcons/financial-operation.png",
    },
    revenue: {
      amount: 58999000,
      icon: "/Images/BusinessStaticIcons/income-stream.png",
    },
    workingCapitalExpenditure: {
      amount: 1683909,
      icon: "/Images/BusinessStaticIcons/financial-operation.png",
    },
    expenses: [
      { name: "Workers Salary", amount: 72000 },
      { name: "PAYE", amount: 56000 },
      { name: "Maintenance", amount: 74000 },
      { name: "Utility", amount: 35000 },
      { name: "Transportation", amount: 35000 },
      { name: "Veterinary Service Fee", amount: 88000 },
      { name: "Security", amount: 45000 },
    ],
  },
  Yearly: {
    netProfit: {
      amount: 1111111,
      icon: "/Images/BusinessStaticIcons/net-profit.png",
    },
    expenditures: {
      amount: 1111111,
      icon: "/Images/BusinessStaticIcons/spending.png",
    },
    operationalProfit: {
      amount: 1111111,
      icon: "/Images/BusinessStaticIcons/financial-operation.png",
    },
    revenue: {
      amount: 1111111,
      icon: "/Images/BusinessStaticIcons/income-stream.png",
    },
    workingCapitalExpenditure: {
      amount: 1111111,
      icon: "/Images/BusinessStaticIcons/financial-operation.png",
    },
    expenses: [
      { name: "Workers Salary", amount: 72000 },
      { name: "PAYE", amount: 56000 },
      { name: "Maintenance", amount: 74000 },
      { name: "Utility", amount: 35000 },
      { name: "Transportation", amount: 35000 },
      { name: "Veterinary Service Fee", amount: 88000 },
      { name: "Security", amount: 45000 },
    ],
  },
};
