export const polutryStandardsCapturingData = [
  {
    PoultryData: [
      {
        weekNumber: 1,
        BreedName: "Cobb500",
        feedType: "Starter",
        feedInTakeInGms: 150,
        production: "High Production",
        standardProperties: [
          {
            standardProperty: "BodyWeight",
            standardValue: 200,
            maxValue: 220,
            minValue: 180,
            uom: "gms",
          },
          {
            standardProperty: "EggWeight",
            standardValue: 45,
            maxValue: 50,
            minValue: 40,
            uom: "gms",
          },
          {
            standardProperty: "Mortality",
            standardValue: 75,
            maxValue: 90,
            minValue: 70,
            uom: "gms",

          }
        ],
      },
      {
        weekNumber: 1,
        BreedName: "Cobb501",
        feedType: "Starter",
        feedInTakeInGms: 150,
         production: "low Production",
        standardProperties: [
          {
            standardProperty: "BodyWeight",
            standardValue: 200,
            maxValue: 220,
            minValue: 180,
            uom: "gms",
          },
          {
            standardProperty: "EggWeight",
            standardValue: 45,
            maxValue: 50,
            minValue: 40,
            uom: "gms",
          },
          {
            standardProperty: "Mortality",
            standardValue: 175,
            maxValue: 180,
            minValue: 100,
            uom: "gms",

          }
        ],
      },
      {
        weekNumber: 2,
        BreedName: "Cobb500",
        feedType: "Starter",
        feedInTakeInGms: 180,
         production: "High Production",
        standardProperties: [
          {
            standardProperty: "BodyWeight",
            standardValue: 350,
            maxValue: 370,
            minValue: 330,
            uom: "gms",
          },
          {
            standardProperty: "EggWeight",
            standardValue: 50,
            maxValue: 55,
            minValue: 45,
            uom: "gms",
          },
          {
            standardProperty: "Mortality",
            standardValue: 85,
            maxValue: 180,
            minValue: 90,
            uom: "gms",

          }
        ],
      },
      {
        weekNumber: 3,
        BreedName: "Lohmann Brown",
        feedType: "Grower",
        feedInTakeInGms: 210,
         production: "High Production",
        standardProperties: [
          {
            standardProperty: "BodyWeight",
            standardValue: 500,
            maxValue: 520,
            minValue: 480,
            uom: "gms",
          },
          {
            standardProperty: "EggWeight",
            standardValue: 55,
            maxValue: 60,
            minValue: 50,
            uom: "gms",
          },
          {
            standardProperty: "Mortality",
            standardValue: 60,
            maxValue: 50,
            minValue: 90,
            uom: "gms",

          }
        ],
      },
      {
        weekNumber: 4,
        BreedName: "Hubbard Classic",
        feedType: "Finisher",
        feedInTakeInGms: 240, production: "High Production",

        standardProperties: [
          {
            standardProperty: "BodyWeight",
            standardValue: 650,
            maxValue: 680,
            minValue: 620,
            uom: "gms",
          },
          {
            standardProperty: "EggWeight",
            standardValue: 60,
            maxValue: 65,
            minValue: 55,
            uom: "gms",
          },
          {
            standardProperty: "Mortality",
            standardValue: 17,
            maxValue: 70,
            minValue: 2,
            uom: "gms",

          }
        ],
      },
    ],
  },
];











