// import React, { useEffect, useRef } from 'react';
// import { Line } from '@antv/g2plot';
// import './week-breed-analysis.css';

// export interface WeekBreedAnalysisObj {
//   unitName: string;
//   shedName: string;
//   breed: {
//     breedName: string;
//     breedData: {
//       week: string;
//       feedIntake: {
//         feedIntakeValue: string;
//         feedIntakeStandardValue: string;
//       };
//       eggWeight: {
//         eggWeightValue: string;
//         eggWeightStandardValue: string;
//       };
//       bodyWeight: {
//         bodyWeightValue: string;
//         bodyWeightStandardValue: string;
//       };
//     }[];
//   };
// }

// export interface WeekBreedAnalysisProps {
//   weekBreedAnalysisData: WeekBreedAnalysisObj[];
// }

// const WeekBreedAnalysis: React.FC<WeekBreedAnalysisProps> = ({ weekBreedAnalysisData }) => {
//   const containerRef = useRef<HTMLDivElement>(null);

//   const chartData = weekBreedAnalysisData.flatMap(item =>
//     item.breed.breedData.flatMap(dataPoint => [
//       {
//         week: parseInt(dataPoint.week, 10),
//         value: parseFloat(dataPoint.feedIntake.feedIntakeValue),
//         standardValue: parseFloat(dataPoint.feedIntake.feedIntakeStandardValue),
//         category: 'Feed Intake (g) Actual',
//         unitName: item.unitName,
//         breedName: item.breed.breedName,
//       },
//       {
//         week: parseInt(dataPoint.week, 10),
//         value: parseFloat(dataPoint.feedIntake.feedIntakeStandardValue),
//         standardValue: parseFloat(dataPoint.feedIntake.feedIntakeStandardValue),
//         category: 'Feed Intake (g) Standard',
//         unitName: item.unitName,
//         breedName: item.breed.breedName,
//       },
//       {
//         week: parseInt(dataPoint.week, 10),
//         value: parseFloat(dataPoint.eggWeight.eggWeightValue),
//         standardValue: parseFloat(dataPoint.eggWeight.eggWeightStandardValue),
//         category: 'Egg Weight (g) Actual',
//         unitName: item.unitName,
//         breedName: item.breed.breedName,
//       },
//       {
//         week: parseInt(dataPoint.week, 10),
//         value: parseFloat(dataPoint.eggWeight.eggWeightStandardValue),
//         standardValue: parseFloat(dataPoint.eggWeight.eggWeightStandardValue),
//         category: 'Egg Weight (g) Standard',
//         unitName: item.unitName,
//         breedName: item.breed.breedName,
//       },
//       {
//         week: parseInt(dataPoint.week, 10),
//         value: parseFloat(dataPoint.bodyWeight.bodyWeightValue) * 1000,
//         standardValue: parseFloat(dataPoint.bodyWeight.bodyWeightStandardValue) * 1000,
//         category: 'Body Weight (g) Actual',
//         unitName: item.unitName,
//         breedName: item.breed.breedName,
//       },
//       {
//         week: parseInt(dataPoint.week, 10),
//         value: parseFloat(dataPoint.bodyWeight.bodyWeightStandardValue) * 1000,
//         standardValue: parseFloat(dataPoint.bodyWeight.bodyWeightStandardValue) * 1000,
//         category: 'Body Weight (g) Standard',
//         unitName: item.unitName,
//         breedName: item.breed.breedName,
//       },
//     ])
//   );

//   useEffect(() => {
//     if (!containerRef.current) return;

//     const line = new Line(containerRef.current, {
//       data: chartData,
//       xField: 'week',
//       yField: 'value',
//       seriesField: 'category',
//       xAxis: {
//         title: { text: 'Week' },
//       },
//       yAxis: {
//         title: { text: 'Value in grams' },
//       },
//       tooltip: {
//         shared: true,
//         showMarkers: false,
//         formatter: (datum) => {
//           const baseCategory = datum.category.replace(' Actual', '').replace(' Standard', '');
//           const isStandard = datum.category.includes('Standard');
//           return {
//             name: baseCategory,
//             value: `${datum.value} (${isStandard ? 'Standard' : 'Actual'})`,
//           };
//         },
//       },
//       legend: {
//         position: 'top',
//         itemName: {
//           formatter: (text) => text.replace(' Actual', '').replace(' Standard', ''),
//         },
//       },
//       smooth: true,
//       animation: {
//         appear: {
//           animation: 'path-in',
//           duration: 1000,
//         },
//       },
//       lineStyle: (datum) => {
//         const isStandard = datum.category.includes('Standard');
//         return {
//           lineDash: isStandard ? [4, 4] : [],
//           opacity: isStandard ? 0.7 : 1,
//         };
//       },
//       color: ['#1979C9', '#D62A0D', '#FAA219'],
//     });

//     line.render();

//     return () => {
//       line.destroy();
//     };
//   }, [chartData]);

//   return (
//     <div>
//       <p className="line-card-title">Batch Analysis</p>
//       <div className="line-card">
//         <div className="line-card-header">
//           <p>
//             BatchName:<strong><span style={{ color: '#f2a93b' }}>Batch 1</span></strong>
//           </p>
//           <p>
//             ShedName:<strong><span style={{ color: 'green' }}>Shed A</span></strong>
//           </p>
//         </div>
//         <div className="line-card-body">
//           <div className="line-card-content" ref={containerRef} />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default WeekBreedAnalysis;











// export interface WeekBreedAnalysisObj {
//   unitName: string;
//   shedName: string;
//   breed: {
//     breedName: string;
//     breedData: {
//       week: string;
//       feedIntake: {
//         feedIntakeValue: string;
//         feedIntakeStandardValue: string;
//         feedIntakeMinValue?:string;
//         feedIntakeMaxValue?:string;
//       };
//       eggWeight: {
//         eggWeightValue: string;
//         eggWeightStandardValue: string;
//         eggWeightMinValue?:string;
//         eggWeightMaxValue?:string;
//       };
//       bodyWeight: {
//         bodyWeightValue: string;
//         bodyWeightStandardValue: string;
//         bodyWeightMinValue?:string;
//         bodyWeightMaxValue?:string;
//       };
//     }[];
//   };
// }
// this interface belongs to the below code





import React, { useState, useEffect, useRef } from "react";
import { Line } from '@antv/g2plot';
import { Card, Typography, List } from "antd";
import './week-breed-analysis.css'

const { Title } = Typography;

export interface WeekBreedAnalysisObj {
  unitName: string;
  shedName: string;
  shedColor: string;
  breed: {
    breedName: string;
    breedData: {
      week: string;
      feedIntake: {
        feedIntakeValue: string;
        feedIntakeStandardValue: string;
      };
      eggWeight: {
        eggWeightValue: string;
        eggWeightStandardValue: string;
      };
      bodyWeight: {
        bodyWeightValue: string;
        bodyWeightStandardValue: string;
      };
    }[];
  };
}

export interface WeekBreedAnalysisProps {
  weekBreedAnalysisData: WeekBreedAnalysisObj[];
}

const WeekBreedAnalysis: React.FC<WeekBreedAnalysisProps> = ({ weekBreedAnalysisData }) => {

  const [selectedShed, setSelectedShed] = useState<WeekBreedAnalysisObj>(weekBreedAnalysisData[0]);
  const [highestBodyWeight, setHighestBodyWeight] = useState<number | null>(null);
  const [lowestBodyWeight, setLowestBodyWeight] = useState<number | null>(null);
  const [averageBodyWeight, setAverageBodyWeight] = useState<number | null>(null);
  const [highestWeek, setHighestWeek] = useState<string | null>(null);
  const [lowestWeek, setLowestWeek] = useState<string | null>(null);
  const chartContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectedShed) {
      const bodyWeightValues = selectedShed.breed.breedData.map(entry => parseFloat(entry.bodyWeight.bodyWeightValue) || 0);
      const highest = Math.max(...bodyWeightValues);
      const lowest = Math.min(...bodyWeightValues);
      const average = bodyWeightValues.reduce((a, b) => a + b, 0) / bodyWeightValues.length;

      setHighestBodyWeight(highest);
      setLowestBodyWeight(lowest);
      setAverageBodyWeight(average);

      const highestIndex = bodyWeightValues.indexOf(highest);
      const lowestIndex = bodyWeightValues.indexOf(lowest);

      setHighestWeek(selectedShed.breed.breedData[highestIndex]?.week || null);
      setLowestWeek(selectedShed.breed.breedData[lowestIndex]?.week || null);
    }
  }, [selectedShed]);

  useEffect(() => {
    if (chartContainerRef.current && selectedShed) {
      const chartData = selectedShed.breed.breedData.map((entry) => ({
        week: entry.week,
        type: "Body Weight",
        value: parseFloat(entry.bodyWeight.bodyWeightValue) || 0,
      })).concat(
        selectedShed.breed.breedData.map((entry) => ({
          week: entry.week,
          type: "Body Weight Standard",
          value: parseFloat(entry.bodyWeight.bodyWeightStandardValue) || 0,
        })),
        selectedShed.breed.breedData.map((entry) => ({
          week: entry.week,
          type: "Feed Intake",
          value: parseFloat(entry.feedIntake.feedIntakeValue) || 0,
        })),
        selectedShed.breed.breedData.map((entry) => ({
          week: entry.week,
          type: "Feed Intake Standard",
          value: parseFloat(entry.feedIntake.feedIntakeStandardValue) || 0,
        })),
        selectedShed.breed.breedData.map((entry) => ({
          week: entry.week,
          type: "Egg Weight",
          value: parseFloat(entry.eggWeight.eggWeightValue) || 0,
        })),
        selectedShed.breed.breedData.map((entry) => ({
          week: entry.week,
          type: "Egg Weight Standard",
          value: parseFloat(entry.eggWeight.eggWeightStandardValue) || 0,
        }))
      );

      const config: any = {
        data: chartData,
        xField: "week",
        yField: "value",
        seriesField: "type",
        yAxis: {
          label: {
            formatter: (v: number) => `${v}g`,
          },
        },
        smooth: true,
        color: ['#888888', '#E8684A', '#228B22', '#FFD700', '#1E90FF', '#FF6347'],
        legend: {
          position: "top",
        },
        point: {
          size: 5,
          shape: 'diamond',
        },
        tooltip: {
          showMarkers: true,
        },
      };

      const linePlot = new Line(chartContainerRef.current, config);
      linePlot.render();

      return () => {
        linePlot.destroy();
      };
    }
  }, [selectedShed, chartContainerRef.current]);

  return (
    <div style={{ display: "flex" }}>
      <div style={{ width: "20%", padding: "10px 0px 10px 0px" }}>
        <h2> Sheds </h2>
        <hr />
        <div>
          <List
            className="shed_selection_list"
            dataSource={weekBreedAnalysisData}
            renderItem={(item) => (
              <List.Item
                onClick={() => setSelectedShed(item)}
                className="shed_selection"
                style={{
                  backgroundColor: item.shedColor,
                  transform: selectedShed.shedName === item.shedName ? "translateX(2px)" : " translateX(0px)",
                  boxShadow: selectedShed.shedName === item.shedName ? "rgb(0, 0, 0) 4px 5px 0px -1px" : "",
                  filter: selectedShed.shedName === item.shedName ? "greyscale(0%)" : "greyscale(50%)",
                }}
              >
                {item.shedName}
              </List.Item>
            )}
          />
        </div>
      </div>

      <div style={{ width: "80%", padding: "10px" }}>
        <Card className="batch_info_chart">
          <Title level={3}>
            {selectedShed.unitName} - {selectedShed.shedName}
          </Title>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <strong>Breed:</strong> {selectedShed.breed.breedName}
            </div>
            <div>
              <strong>Highest Body Weight:</strong> {highestBodyWeight ? `${highestBodyWeight}g` : "N/A"} <br />
              <span>{highestWeek ? `Week: ${highestWeek}` : "N/A"}</span>
            </div>
            <div>
              <strong>Lowest Body Weight:</strong> {lowestBodyWeight ? `${lowestBodyWeight}g` : "N/A"} <br />
              <span>{lowestWeek ? `Week: ${lowestWeek}` : "N/A"}</span>
            </div>
            <div>
              <strong>Average Body Weight:</strong> {averageBodyWeight ? `${averageBodyWeight.toFixed(2)}g` : "N/A"} <br />
              {/* <span>Week: N/A</span> Average won't correspond to a specific week */}
            </div>
          </div>
          <div ref={chartContainerRef} />
        </Card>
      </div>
    </div>
  );
};

export default WeekBreedAnalysis;
