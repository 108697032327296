
import React, { useState, useEffect } from "react";
import { Form, InputNumber, Button, Select, Table, message, Input } from "antd";
import "./PoultryStandardsCapturing.css";
import { polutryStandardsCapturingData } from "./PolutryStandardsCapturingData";

const { Option } = Select;

interface PoultryStandardsCapturingProps {
  onDataSubmit: (data: any) => void;
}

interface StandardProperty {
  standardProperty: string;
  standardValue: number;
  minValue: number;
  maxValue: number;
}

interface PoultryDataItem {
  BreedName: string;
  production: string;
  standardProperties: StandardProperty[];
  feedInTakeInGms: number;
  feedType: string;
  weekNumber: number;
}


const PoultryStandardsCapturing: React.FC<PoultryStandardsCapturingProps> = ({
  onDataSubmit,
}) => {
  const [form] = Form.useForm();
  const [comparison, setComparison] = useState<Record<string, string>>({});
  const [selectedWeek, setSelectedWeek] = useState<string>("");
  const [filteredData, setFilteredData] = useState<PoultryDataItem[]>([]);
  const [columns, setColumns] = useState<any[]>([]);

  const handleWeekChange = (value: string) => {
    setSelectedWeek(value);
    const weekNumber = parseInt(value.split(" ")[1]);
    const weekData = polutryStandardsCapturingData[0].PoultryData.filter(
      (data: PoultryDataItem) => data.weekNumber === weekNumber
    );
    setFilteredData(weekData);

    form.resetFields();
  };

  useEffect(() => {
    if (filteredData.length > 0) {
      const dynamicColumns = [
        {
          title: "Breed",
          dataIndex: "BreedName",
          key: "BreedName",
          render: (text: string) => <span>{text}</span>,
        },
        ...filteredData[0].standardProperties.map((property: StandardProperty) => ({
          title: property.standardProperty,
          dataIndex: property.standardProperty,
          key: property.standardProperty,
          render: (_: any, record: any) => {
            const propertyKey = property.standardProperty.toLowerCase();

            return (
              <div className="input-group-vertical">
                <Form.Item
                  name={`${propertyKey}_${record.key}`}
                  rules={[
                    {
                      required: true,
                      message: `Please enter the ${property.standardProperty.toLowerCase()}!`,
                    },
                  ]}
                >
                  <InputNumber
                    min={property.minValue}
                    max={property.maxValue}
                    step={0.1}
                    onChange={(value: any) =>
                      handleValueChange(
                        { [`${propertyKey}_${record.key}`]: value },
                        form.getFieldsValue()
                      )
                    }
                  />
                </Form.Item>

                <div className="small-inputs">
                  <Form.Item
                    name={`${propertyKey}_additional1_${record.key}`}
                    rules={[
                      {
                        required: true,
                        message: `Please enter the first additional value for ${property.standardProperty.toLowerCase()}!`,
                      },
                    ]}
                  >
                    <InputNumber
                      min={0}
                      step={0.1}
                      className="small-input"
                      onChange={(value: any) =>
                        handleValueChange(
                          {
                            [`${propertyKey}_additional1_${record.key}`]: value,
                          },
                          form.getFieldsValue()
                        )
                      }
                    />
                  </Form.Item>
                  <span style={{ paddingRight: "10px" }} />

                  <Form.Item
                    name={`${propertyKey}_additional2_${record.key}`}
                    rules={[
                      {
                        required: true,
                        message: `Please enter the second additional value for ${property.standardProperty.toLowerCase()}!`,
                      },
                    ]}
                  >
                    <InputNumber
                      min={0}
                      step={0.1}
                      className="small-input"
                      onChange={(value: any) =>
                        handleValueChange(
                          {
                            [`${propertyKey}_additional2_${record.key}`]: value,
                          },
                          form.getFieldsValue()
                        )
                      }
                    />
                  </Form.Item>
                </div>
              </div>
            );
          },
        })),
        {
          title: "Feed Intake (Grams)",
          dataIndex: "feedInTakeInGms",
          key: "feedInTakeInGms",
          render: (_: any, record: any) => (
            <Form.Item
              name={`feedInTakeInGms_${record.key}`}
              rules={[
                {
                  required: true,
                  message: "Please enter the feed intake!",
                },
              ]}
            >
              <InputNumber
                min={0}
                step={0.1}
                className="main-input"
                style={{ width: "100%" }}
              />
            </Form.Item>
          ),
        },
        {
          title: "Feed Type",
          dataIndex: "feedType",
          key: "feedType",
          render: (_: any, record: any) => (
            <Form.Item
              name={`feedType_${record.key}`}
              rules={[
                {
                  required: true,
                  message: "Please enter the feed type!",
                },
              ]}
            >
              <Input className="main-input" />
            </Form.Item>
          ),
        },
        {
          title: "Production",
          dataIndex: "production",
          key: "production",
          render: (text: string) => <span>{text || "N/A"}</span>,
        },
      ];
      setColumns(dynamicColumns);

      const initialValues = filteredData.reduce((acc: any, item: PoultryDataItem, index: number) => {
        item.standardProperties.forEach((property: StandardProperty) => {
          const propertyKey = property.standardProperty.toLowerCase();
          acc[`${propertyKey}_${index}`] = property.standardValue;
          acc[`${propertyKey}_additional1_${index}`] = property.minValue;
          acc[`${propertyKey}_additional2_${index}`] = property.maxValue;
        });
        acc[`feedInTakeInGms_${index}`] = item.feedInTakeInGms;
        acc[`feedType_${index}`] = item.feedType;
        return acc;
      }, {});
      form.setFieldsValue(initialValues);
    }
  }, [filteredData, form]);

  const handleValueChange = (changedValues: Record<string, any>, allValues: Record<string, any>) => {
    const updatedComparison: Record<string, string> = { ...comparison };

    Object.keys(changedValues).forEach((key) => {
      const value = changedValues[key];
      const propertyKey = key.split('_')[0];
      const index = parseInt(key.split('_')[2], 10);
      const minValue = filteredData[index]?.standardProperties.find(
        (prop: StandardProperty) => prop.standardProperty.toLowerCase() === propertyKey
      )?.minValue;
      const maxValue = filteredData[index]?.standardProperties.find(
        (prop: StandardProperty) => prop.standardProperty.toLowerCase() === propertyKey
      )?.maxValue;

      updatedComparison[key] = getComparison(value, minValue, maxValue);
    });

    setComparison(updatedComparison);

    // Update form values to reflect changes
    form.setFieldsValue(allValues);
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const submissionData: {
          week: string;
          standards: any[];
        } = {
          week: selectedWeek,
          standards: filteredData.map((item: PoultryDataItem, index: number) => ({
            BreedName: item.BreedName,
            Production: item.production || "N/A",
            ...item.standardProperties.reduce((acc: any, property: StandardProperty) => {
              const propertyKey = property.standardProperty.toLowerCase();
              acc[propertyKey] = values[`${propertyKey}_${index}`];
              acc[`${propertyKey}Min`] = values[`${propertyKey}_additional1_${index}`];
              acc[`${propertyKey}Max`] = values[`${propertyKey}_additional2_${index}`];
              return acc;
            }, {}),
            FeedIntakeInGrams: values[`feedInTakeInGms_${index}`],
            FeedType: values[`feedType_${index}`],
          })),
        };

        onDataSubmit(submissionData);
        message.success("Data submitted successfully!");
      })
      .catch((info) => {
        console.log("Validation failed:", info);
      });
  };

  const getComparison = (
    value: number | undefined,
    minValue: number | undefined,
    maxValue: number | undefined
  ) => {
    if (value === undefined || minValue === undefined || maxValue === undefined)
      return "";
    if (value < minValue) return "<";
    if (value > maxValue) return ">";
    if (value >= minValue && value <= maxValue) return "=";
    return "";
  };

  return (
    <div className="poultry-standards-capturing-container">
      <Form form={form} layout="vertical" onValuesChange={handleValueChange}>
        <Form.Item
          label="Select Week"
          rules={[{ required: true, message: "Please select a week!" }]}
        >
          <Select value={selectedWeek} onChange={handleWeekChange}>
            <Option value="Week 1">Week 1</Option>
            <Option value="Week 2">Week 2</Option>
            <Option value="Week 3">Week 3</Option>
            <Option value="Week 4">Week 4</Option>
          </Select>
        </Form.Item>

        <Table
          dataSource={filteredData.map((item, index) => ({ ...item, key: index }))}
          columns={columns}
          pagination={false}
        />

        <Form.Item>
          <Button type="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PoultryStandardsCapturing;















