export const tempVsMortalityData = [

    //unit-1 , week-1

    {
        breedName: 'Layer Chicken',
        unitCode: 'LC001',
        breedAgeWiseTempVariation: [{
            birdAgeInWeek: 1,
            tempVariation: {
                temparature: 20,
                mortalityRate: 2.5,
                mortalityStandard: 10,
                mortalityMin: 1.2,
                mortalityMax: 3.2,
                production: 0
            }
        },
        {
            birdAgeInWeek: 1,
            tempVariation: {
                temparature: 21,
                mortalityRate: 1.4,
                mortalityStandard: 10,
                mortalityMin: 1.1,
                mortalityMax: 3.1,
                production: 0
            }
        },
        {
            birdAgeInWeek: 1,
            tempVariation: {
                temparature: 22,
                mortalityRate: 2.3,
                mortalityStandard: 10,
                mortalityMin: 1.0,
                mortalityMax: 3.0,
                production: 0
            }
        },
        {
            birdAgeInWeek: 1,
            tempVariation: {
                temparature: 23,
                mortalityRate: 4.2,
                mortalityStandard: 10,
                mortalityMin: 0.9,
                mortalityMax: 2.9,
                production: 0
            }
        },
        {
            birdAgeInWeek: 1,
            tempVariation: {
                temparature: 24,
                mortalityRate: 5.1,
                mortalityStandard: 10,
                mortalityMin: 0.8,
                mortalityMax: 2.8,
                production: 1
            }
        },
        {
            birdAgeInWeek: 1,
            tempVariation: {
                temparature: 25,
                mortalityRate: 2.0,
                mortalityStandard: 10,
                mortalityMin: 0.8,
                mortalityMax: 2.7,
                production: 1
            }
        },
        {
            birdAgeInWeek: 1,
            tempVariation: {
                temparature: 26,
                mortalityRate: 3.9,
                mortalityStandard: 10,
                mortalityMin: 0.7,
                mortalityMax: 2.6,
                production: 1
            }
        },
        {
            birdAgeInWeek: 1,
            tempVariation: {
                temparature: 27,
                mortalityRate: 1.8,
                mortalityStandard: 10,
                mortalityMin: 0.6,
                mortalityMax: 2.5,
                production: 1
            }
        },
        {
            birdAgeInWeek: 1,
            tempVariation: {
                temparature: 28,
                mortalityRate: 4.7,
                mortalityStandard: 10,
                mortalityMin: 0.5,
                mortalityMax: 2.4,
                production: 2
            }
        },
        {
            birdAgeInWeek: 1,
            tempVariation: {
                temparature: 29,
                mortalityRate: 3.6,
                mortalityStandard: 10,
                mortalityMin: 0.4,
                mortalityMax: 2.3,
                production: 2
            }
        },
        {
            birdAgeInWeek: 1,
            tempVariation: {
                temparature: 30,
                mortalityRate: 7.5,
                mortalityStandard: 10,
                mortalityMin: 0.4,
                mortalityMax: 2.2,
                production: 2
            }
        },
        {
            birdAgeInWeek: 1,
            tempVariation: {
                temparature: 31,
                mortalityRate: 1.4,
                mortalityStandard: 10,
                mortalityMin: 0.3,
                mortalityMax: 2.1,
                production: 2
            }
        },
        {
            birdAgeInWeek: 1,
            tempVariation: {
                temparature: 32,
                mortalityRate: 11.3,
                mortalityStandard: 10,
                mortalityMin: 0.3,
                mortalityMax: 2.0,
                production: 3
            }
        },
        {
            birdAgeInWeek: 1,
            tempVariation: {
                temparature: 33,
                mortalityRate: 1.2,
                mortalityStandard: 10,
                mortalityMin: 0.2,
                mortalityMax: 1.9,
                production: 3
            }
        },
        {
            birdAgeInWeek: 1,
            tempVariation: {
                temparature: 34,
                mortalityRate: 1.1,
                mortalityStandard: 10,
                mortalityMin: 0.2,
                mortalityMax: 1.8,
                production: 3
            }
        },
        {
            birdAgeInWeek: 1,
            tempVariation: {
                temparature: 35,
                mortalityRate: 2.0,
                mortalityStandard: 10,
                mortalityMin: 0.1,
                mortalityMax: 1.7,
                production: 3
            }
        },
        {
            birdAgeInWeek: 1,
            tempVariation: {
                temparature: 36,
                mortalityRate: 0.9,
                mortalityStandard: 10,
                mortalityMin: 0.1,
                mortalityMax: 1.6,
                production: 4
            }
        }
        ]
    },

    //unit-1 , week- 2.

    {
        breedName: 'Layer Chicken',
        unitCode: 'LC001',
        breedAgeWiseTempVariation: [{
            birdAgeInWeek: 2,
            tempVariation: {
                temparature: 20,
                mortalityRate: 2.4,
                mortalityStandard: 10,
                mortalityMin: 1.1,
                mortalityMax: 3.1,
                production: 0
            }
        },
        {
            birdAgeInWeek: 2,
            tempVariation: {
                temparature: 21,
                mortalityRate: 2.3,
                mortalityStandard: 10,
                mortalityMin: 1.1,
                mortalityMax: 3.0,
                production: 0
            }
        },
        {
            birdAgeInWeek: 2,
            tempVariation: {
                temparature: 22,
                mortalityRate: 2.2,
                mortalityStandard: 10,
                mortalityMin: 1.0,
                mortalityMax: 2.9,
                production: 0
            }
        },
        {
            birdAgeInWeek: 2,
            tempVariation: {
                temparature: 23,
                mortalityRate: 2.1,
                mortalityStandard: 10,
                mortalityMin: 1.0,
                mortalityMax: 2.8,
                production: 1
            }
        },
        {
            birdAgeInWeek: 2,
            tempVariation: {
                temparature: 24,
                mortalityRate: 1.8,
                mortalityStandard: 10,
                mortalityMin: 0.9,
                mortalityMax: 2.7,
                production: 1
            }
        },
        {
            birdAgeInWeek: 2,
            tempVariation: {
                temparature: 25,
                mortalityRate: 1.7,
                mortalityStandard: 10,
                mortalityMin: 0.8,
                mortalityMax: 2.6,
                production: 1
            }
        },
        {
            birdAgeInWeek: 2,
            tempVariation: {
                temparature: 26,
                mortalityRate: 1.6,
                mortalityStandard: 10,
                mortalityMin: 0.8,
                mortalityMax: 2.5,
                production: 1
            }
        },
        {
            birdAgeInWeek: 2,
            tempVariation: {
                temparature: 27,
                mortalityRate: 1.5,
                mortalityStandard: 10,
                mortalityMin: 0.7,
                mortalityMax: 2.4,
                production: 2
            }
        },
        {
            birdAgeInWeek: 2,
            tempVariation: {
                temparature: 28,
                mortalityRate: 1.4,
                mortalityStandard: 10,
                mortalityMin: 0.7,
                mortalityMax: 2.3,
                production: 2
            }
        },
        {
            birdAgeInWeek: 2,
            tempVariation: {
                temparature: 29,
                mortalityRate: 1.3,
                mortalityStandard: 10,
                mortalityMin: 0.6,
                mortalityMax: 2.2,
                production: 2
            }
        },
        {
            birdAgeInWeek: 2,
            tempVariation: {
                temparature: 30,
                mortalityRate: 1.2,
                mortalityStandard: 10,
                mortalityMin: 0.6,
                mortalityMax: 2.1,
                production: 2
            }
        },
        {
            birdAgeInWeek: 2,
            tempVariation: {
                temparature: 31,
                mortalityRate: 1.1,
                mortalityStandard: 10,
                mortalityMin: 0.5,
                mortalityMax: 2.0,
                production: 3
            }
        },
        {
            birdAgeInWeek: 2,
            tempVariation: {
                temparature: 32,
                mortalityRate: 1.0,
                mortalityStandard: 10,
                mortalityMin: 0.5,
                mortalityMax: 1.9,
                production: 3
            }
        },
        {
            birdAgeInWeek: 2,
            tempVariation: {
                temparature: 33,
                mortalityRate: 0.9,
                mortalityStandard: 10,
                mortalityMin: 0.4,
                mortalityMax: 1.8,
                production: 3
            }
        },
        {
            birdAgeInWeek: 2,
            tempVariation: {
                temparature: 34,
                mortalityRate: 0.8,
                mortalityStandard: 10,
                mortalityMin: 0.4,
                mortalityMax: 1.7,
                production: 3
            }
        },
        {
            birdAgeInWeek: 2,
            tempVariation: {
                temparature: 35,
                mortalityRate: 0.7,
                mortalityStandard: 10,
                mortalityMin: 0.3,
                mortalityMax: 1.6,
                production: 4
            }
        },
        {
            birdAgeInWeek: 2,
            tempVariation: {
                temparature: 36,
                mortalityRate: 0.6,
                mortalityStandard: 10,
                mortalityMin: 0.3,
                mortalityMax: 1.5,
                production: 4
            }
        }
        ]
    },

    //unit-1, week-3

    {
        breedName: 'Layer Chicken',
        unitCode: 'LC001',
        breedAgeWiseTempVariation: [{
            birdAgeInWeek: 3,
            tempVariation: {
                temparature: 20,
                mortalityRate: 2.0,
                mortalityStandard: 10,
                mortalityMin: 1.0,
                mortalityMax: 3.0,
                production: 1
            }
        },
        {
            birdAgeInWeek: 3,
            tempVariation: {
                temparature: 21,
                mortalityRate: 1.9,
                mortalityStandard: 10,
                mortalityMin: 0.9,
                mortalityMax: 2.9,
                production: 1
            }
        },
        {
            birdAgeInWeek: 3,
            tempVariation: {
                temparature: 22,
                mortalityRate: 1.8,
                mortalityStandard: 10,
                mortalityMin: 0.8,
                mortalityMax: 2.8,
                production: 1
            }
        },
        {
            birdAgeInWeek: 3,
            tempVariation: {
                temparature: 23,
                mortalityRate: 1.7,
                mortalityStandard: 10,
                mortalityMin: 0.8,
                mortalityMax: 2.7,
                production: 1
            }
        },
        {
            birdAgeInWeek: 3,
            tempVariation: {
                temparature: 24,
                mortalityRate: 1.5,
                mortalityStandard: 10,
                mortalityMin: 0.7,
                mortalityMax: 2.6,
                production: 2
            }
        },
        {
            birdAgeInWeek: 3,
            tempVariation: {
                temparature: 25,
                mortalityRate: 1.4,
                mortalityStandard: 10,
                mortalityMin: 0.6,
                mortalityMax: 2.5,
                production: 2
            }
        },
        {
            birdAgeInWeek: 3,
            tempVariation: {
                temparature: 26,
                mortalityRate: 1.3,
                mortalityStandard: 10,
                mortalityMin: 0.6,
                mortalityMax: 2.4,
                production: 2
            }
        },
        {
            birdAgeInWeek: 3,
            tempVariation: {
                temparature: 27,
                mortalityRate: 1.2,
                mortalityStandard: 10,
                mortalityMin: 0.5,
                mortalityMax: 2.3,
                production: 2
            }
        },
        {
            birdAgeInWeek: 3,
            tempVariation: {
                temparature: 28,
                mortalityRate: 1.1,
                mortalityStandard: 10,
                mortalityMin: 0.5,
                mortalityMax: 2.2,
                production: 3
            }
        },
        {
            birdAgeInWeek: 3,
            tempVariation: {
                temparature: 29,
                mortalityRate: 1.0,
                mortalityStandard: 10,
                mortalityMin: 0.4,
                mortalityMax: 2.1,
                production: 3
            }
        },
        {
            birdAgeInWeek: 3,
            tempVariation: {
                temparature: 30,
                mortalityRate: 0.9,
                mortalityStandard: 10,
                mortalityMin: 0.4,
                mortalityMax: 2.0,
                production: 3
            }
        },
        {
            birdAgeInWeek: 3,
            tempVariation: {
                temparature: 31,
                mortalityRate: 0.8,
                mortalityStandard: 10,
                mortalityMin: 0.3,
                mortalityMax: 1.9,
                production: 3
            }
        },
        {
            birdAgeInWeek: 3,
            tempVariation: {
                temparature: 32,
                mortalityRate: 0.7,
                mortalityStandard: 10,
                mortalityMin: 0.3,
                mortalityMax: 1.8,
                production: 4
            }
        },
        {
            birdAgeInWeek: 3,
            tempVariation: {
                temparature: 33,
                mortalityRate: 0.6,
                mortalityStandard: 10,
                mortalityMin: 0.2,
                mortalityMax: 1.7,
                production: 4
            }
        },
        {
            birdAgeInWeek: 3,
            tempVariation: {
                temparature: 34,
                mortalityRate: 0.5,
                mortalityStandard: 10,
                mortalityMin: 0.2,
                mortalityMax: 1.6,
                production: 4
            }
        },
        {
            birdAgeInWeek: 3,
            tempVariation: {
                temparature: 35,
                mortalityRate: 0.4,
                mortalityStandard: 10,
                mortalityMin: 0.1,
                mortalityMax: 1.5,
                production: 4
            }
        },
        {
            birdAgeInWeek: 3,
            tempVariation: {
                temparature: 36,
                mortalityRate: 0.3,
                mortalityStandard: 10,
                mortalityMin: 0.1,
                mortalityMax: 1.4,
                production: 4
            }
        }
        ]
    },

    // unit-2, week-1

    {
        breedName: 'Layer Chicken',
        unitCode: 'LC002',
        breedAgeWiseTempVariation: [{
            birdAgeInWeek: 1,
            tempVariation: {
                temparature: 20,
                mortalityRate: 12.5,
                mortalityStandard: 10,
                mortalityMin: 1.2,
                mortalityMax: 3.4,
                production: 0
            }
        }, {
            birdAgeInWeek: 1,
            tempVariation: {
                temparature: 21,
                mortalityRate: 2.4,
                mortalityStandard: 10,
                mortalityMin: 1.2,
                mortalityMax: 3.3,
                production: 0
            }
        }, {
            birdAgeInWeek: 1,
            tempVariation: {
                temparature: 22,
                mortalityRate: 5.3,
                mortalityStandard: 10,
                mortalityMin: 1.1,
                mortalityMax: 3.2,
                production: 0
            }
        }, {
            birdAgeInWeek: 1,
            tempVariation: {
                temparature: 23,
                mortalityRate: 1.2,
                mortalityStandard: 10,
                mortalityMin: 1.1,
                mortalityMax: 3.2,
                production: 0
            }
        }, {
            birdAgeInWeek: 1,
            tempVariation: {
                temparature: 24,
                mortalityRate: 2.1,
                mortalityStandard: 10,
                mortalityMin: 1.0,
                mortalityMax: 3.1,
                production: 0
            }
        }, {
            birdAgeInWeek: 1,
            tempVariation: {
                temparature: 25,
                mortalityRate: 5.0,
                mortalityStandard: 10,
                mortalityMin: 1.0,
                mortalityMax: 3.0,
                production: 0
            }
        }, {
            birdAgeInWeek: 1,
            tempVariation: {
                temparature: 26,
                mortalityRate: 1.9,
                mortalityStandard: 10,
                mortalityMin: 0.9,
                mortalityMax: 2.9,
                production: 0
            }
        }, {
            birdAgeInWeek: 1,
            tempVariation: {
                temparature: 27,
                mortalityRate: 11.8,
                mortalityStandard: 10,
                mortalityMin: 0.9,
                mortalityMax: 2.8,
                production: 0
            }
        }, {
            birdAgeInWeek: 1,
            tempVariation: {
                temparature: 28,
                mortalityRate: 1.7,
                mortalityStandard: 10,
                mortalityMin: 0.8,
                mortalityMax: 2.7,
                production: 0
            }
        }, {
            birdAgeInWeek: 1,
            tempVariation: {
                temparature: 29,
                mortalityRate: 6.6,
                mortalityStandard: 10,
                mortalityMin: 0.8,
                mortalityMax: 2.6,
                production: 0
            }
        }, {
            birdAgeInWeek: 1,
            tempVariation: {
                temparature: 30,
                mortalityRate: 1.5,
                mortalityStandard: 10,
                mortalityMin: 0.7,
                mortalityMax: 2.5,
                production: 0
            }
        }, {
            birdAgeInWeek: 1,
            tempVariation: {
                temparature: 31,
                mortalityRate: 7.4,
                mortalityStandard: 10,
                mortalityMin: 0.7,
                mortalityMax: 2.4,
                production: 0
            }
        }, {
            birdAgeInWeek: 1,
            tempVariation: {
                temparature: 32,
                mortalityRate: 1.3,
                mortalityStandard: 10,
                mortalityMin: 0.6,
                mortalityMax: 2.3,
                production: 0
            }
        }, {
            birdAgeInWeek: 1,
            tempVariation: {
                temparature: 33,
                mortalityRate: 10.2,
                mortalityStandard: 10,
                mortalityMin: 0.6,
                mortalityMax: 2.2,
                production: 1
            }
        }, {
            birdAgeInWeek: 1,
            tempVariation: {
                temparature: 34,
                mortalityRate: 1.1,
                mortalityStandard: 10,
                mortalityMin: 0.5,
                mortalityMax: 2.1,
                production: 1
            }
        }, {
            birdAgeInWeek: 1,
            tempVariation: {
                temparature: 35,
                mortalityRate: 1.0,
                mortalityStandard: 10,
                mortalityMin: 0.5,
                mortalityMax: 2.0,
                production: 1
            }
        }, {
            birdAgeInWeek: 1,
            tempVariation: {
                temparature: 36,
                mortalityRate: 5.9,
                mortalityStandard: 10,
                mortalityMin: 0.4,
                mortalityMax: 1.9,
                production: 1
            }
        }
        ]
    },

    //unit-2 week-2

    {
        breedName: 'Layer Chicken',
        unitCode: 'LC002',
        breedAgeWiseTempVariation: [{
            birdAgeInWeek: 2,
            tempVariation: {
                temparature: 20,
                mortalityRate: 2.5,
                mortalityStandard: 10,
                mortalityMin: 1.2,
                mortalityMax: 3.4,
                production: 0
            }
        },
        {
            birdAgeInWeek: 2,
            tempVariation: {
                temparature: 21,
                mortalityRate: 2.4,
                mortalityStandard: 10,
                mortalityMin: 1.2,
                mortalityMax: 3.3,
                production: 0
            }
        },
        {
            birdAgeInWeek: 2,
            tempVariation: {
                temparature: 22,
                mortalityRate: 2.3,
                mortalityStandard: 10,
                mortalityMin: 1.1,
                mortalityMax: 3.2,
                production: 0
            }
        },
        {
            birdAgeInWeek: 2,
            tempVariation: {
                temparature: 23,
                mortalityRate: 2.2,
                mortalityStandard: 10,
                mortalityMin: 1.1,
                mortalityMax: 3.1,
                production: 0
            }
        },
        {
            birdAgeInWeek: 2,
            tempVariation: {
                temparature: 24,
                mortalityRate: 2.1,
                mortalityStandard: 10,
                mortalityMin: 1.0,
                mortalityMax: 3.0,
                production: 0
            }
        },
        {
            birdAgeInWeek: 2,
            tempVariation: {
                temparature: 25,
                mortalityRate: 2.0,
                mortalityStandard: 10,
                mortalityMin: 1.0,
                mortalityMax: 2.9,
                production: 0
            }
        },
        {
            birdAgeInWeek: 2,
            tempVariation: {
                temparature: 26,
                mortalityRate: 1.9,
                mortalityStandard: 10,
                mortalityMin: 0.9,
                mortalityMax: 2.8,
                production: 1
            }
        },
        {
            birdAgeInWeek: 2,
            tempVariation: {
                temparature: 27,
                mortalityRate: 1.8,
                mortalityStandard: 10,
                mortalityMin: 0.9,
                mortalityMax: 2.7,
                production: 1
            }
        },
        {
            birdAgeInWeek: 2,
            tempVariation: {
                temparature: 28,
                mortalityRate: 1.7,
                mortalityStandard: 10,
                mortalityMin: 0.8,
                mortalityMax: 2.6,
                production: 1
            }
        },
        {
            birdAgeInWeek: 2,
            tempVariation: {
                temparature: 29,
                mortalityRate: 1.6,
                mortalityStandard: 10,
                mortalityMin: 0.8,
                mortalityMax: 2.5,
                production: 1
            }
        },
        {
            birdAgeInWeek: 2,
            tempVariation: {
                temparature: 30,
                mortalityRate: 1.5,
                mortalityStandard: 10,
                mortalityMin: 0.7,
                mortalityMax: 2.4,
                production: 1
            }
        },
        {
            birdAgeInWeek: 2,
            tempVariation: {
                temparature: 31,
                mortalityRate: 1.4,
                mortalityStandard: 10,
                mortalityMin: 0.7,
                mortalityMax: 2.3,
                production: 1
            }
        },
        {
            birdAgeInWeek: 2,
            tempVariation: {
                temparature: 32,
                mortalityRate: 1.3,
                mortalityStandard: 10,
                mortalityMin: 0.6,
                mortalityMax: 2.2,
                production: 2
            }
        },
        {
            birdAgeInWeek: 2,
            tempVariation: {
                temparature: 33,
                mortalityRate: 1.2,
                mortalityStandard: 10,
                mortalityMin: 0.6,
                mortalityMax: 2.1,
                production: 2
            }
        },
        {
            birdAgeInWeek: 2,
            tempVariation: {
                temparature: 34,
                mortalityRate: 1.1,
                mortalityStandard: 10,
                mortalityMin: 0.5,
                mortalityMax: 2.0,
                production: 2
            }
        },
        {
            birdAgeInWeek: 2,
            tempVariation: {
                temparature: 35,
                mortalityRate: 1.0,
                mortalityStandard: 10,
                mortalityMin: 0.5,
                mortalityMax: 1.9,
                production: 2
            }
        },
        {
            birdAgeInWeek: 2,
            tempVariation: {
                temparature: 36,
                mortalityRate: 0.9,
                mortalityStandard: 10,
                mortalityMin: 0.4,
                mortalityMax: 1.8,
                production: 2
            }
        }
        ]
    },

    //unit-2 week-3

    {
        breedName: 'Layer Chicken',
        unitCode: 'LC002',
        breedAgeWiseTempVariation: [{
            birdAgeInWeek: 3,
            tempVariation: {
                temparature: 20,
                mortalityRate: 3.5,
                mortalityStandard: 10,
                mortalityMin: 1.2,
                mortalityMax: 3.4,
                production: 0
            }
        },
        {
            birdAgeInWeek: 3,
            tempVariation: {
                temparature: 21,
                mortalityRate: 2.4,
                mortalityStandard: 10,
                mortalityMin: 1.2,
                mortalityMax: 3.3,
                production: 0
            }
        },
        {
            birdAgeInWeek: 3,
            tempVariation: {
                temparature: 22,
                mortalityRate: 5.3,
                mortalityStandard: 10,
                mortalityMin: 1.1,
                mortalityMax: 3.2,
                production: 0
            }
        },
        {
            birdAgeInWeek: 3,
            tempVariation: {
                temparature: 23,
                mortalityRate: 2.2,
                mortalityStandard: 10,
                mortalityMin: 1.1,
                mortalityMax: 3.1,
                production: 0
            }
        },
        {
            birdAgeInWeek: 3,
            tempVariation: {
                temparature: 24,
                mortalityRate: 3.1,
                mortalityStandard: 10,
                mortalityMin: 1.0,
                mortalityMax: 3.0,
                production: 0
            }
        },
        {
            birdAgeInWeek: 3,
            tempVariation: {
                temparature: 25,
                mortalityRate: 2.0,
                mortalityStandard: 10,
                mortalityMin: 1.0,
                mortalityMax: 2.9,
                production: 0
            }
        },
        {
            birdAgeInWeek: 3,
            tempVariation: {
                temparature: 26,
                mortalityRate: 2.9,
                mortalityStandard: 10,
                mortalityMin: 0.9,
                mortalityMax: 2.8,
                production: 1
            }
        },
        {
            birdAgeInWeek: 3,
            tempVariation: {
                temparature: 27,
                mortalityRate: 1.8,
                mortalityStandard: 10,
                mortalityMin: 0.9,
                mortalityMax: 2.6,
                production: 1
            }
        },
        {
            birdAgeInWeek: 3,
            tempVariation: {
                temparature: 28,
                mortalityRate: 4.7,
                mortalityStandard: 10,
                mortalityMin: 0.8,
                mortalityMax: 2.5,
                production: 1
            }
        },
        {
            birdAgeInWeek: 3,
            tempVariation: {
                temparature: 29,
                mortalityRate: 1.6,
                mortalityStandard: 10,
                mortalityMin: 0.8,
                mortalityMax: 2.4,
                production: 1
            }
        },
        {
            birdAgeInWeek: 3,
            tempVariation: {
                temparature: 30,
                mortalityRate: 3.5,
                mortalityStandard: 10,
                mortalityMin: 0.7,
                mortalityMax: 2.3,
                production: 1
            }
        },
        {
            birdAgeInWeek: 3,
            tempVariation: {
                temparature: 31,
                mortalityRate: 1.4,
                mortalityStandard: 10,
                mortalityMin: 0.7,
                mortalityMax: 2.2,
                production: 1
            }
        },
        {
            birdAgeInWeek: 3,
            tempVariation: {
                temparature: 32,
                mortalityRate: 1.3,
                mortalityStandard: 10,
                mortalityMin: 0.6,
                mortalityMax: 2.1,
                production: 1
            }
        },
        {
            birdAgeInWeek: 3,
            tempVariation: {
                temparature: 33,
                mortalityRate: 1.2,
                mortalityStandard: 10,
                mortalityMin: 0.6,
                mortalityMax: 2.0,
                production: 1
            }
        },
        {
            birdAgeInWeek: 3,
            tempVariation: {
                temparature: 34,
                mortalityRate: 2.1,
                mortalityStandard: 10,
                mortalityMin: 0.5,
                mortalityMax: 1.9,
                production: 2
            }
        },
        {
            birdAgeInWeek: 3,
            tempVariation: {
                temparature: 35,
                mortalityRate: 1.0,
                mortalityStandard: 10,
                mortalityMin: 0.5,
                mortalityMax: 1.8,
                production: 2
            }
        },
        {
            birdAgeInWeek: 3,
            tempVariation: {
                temparature: 36,
                mortalityRate: 1.9,
                mortalityStandard: 10,
                mortalityMin: 0.4,
                mortalityMax: 1.7,
                production: 2
            }
        }
        ]
    }
];
