import { Table, Typography } from 'antd';
import React from 'react';

interface MortalityReportProps {
  report: any;
}

const MortalityReport: React.FC<MortalityReportProps> = ({ report }) => {
const columns=[
    {
        title:"Current Mortality Rate",
        dataIndex:"CurrentMortalityRate",
        key :"CurrentMortalityRate"
    },
    {
        title:"Standard Mortality Rate",
        dataIndex:"StandardMortalityRate",
        key :"StandardMortalityRate"
    },
    {
        title:"Minimum Mortality Rate",
        dataIndex:"MinimumMortalityRate",
        key :"MinimumMortalityRate"
    },
    {
        title:"Maximum Mortality Rate",
        dataIndex:"MaximumMortalityRate",
        key :"MaximumMortalityRate",
    },
];

const data =[
    {
        key: '1',
        CurrentMortalityRate:`${report.currentMortalityRate.toFixed(2)}%`,
        StandardMortalityRate:`${report.standardMortalityRate.toFixed(2)}%`,
        MinimumMortalityRate:`${report.minMortalityRate.toFixed(2)}% in 
        ${report.minWeek}`,
        MaximumMortalityRate:`${report.maxMortalityRate.toFixed(2)}% in 
        ${report.maxWeek}`,

    },
 ] ;  

  return (
    <>
      <Typography.Title level={4}>Mortality Report</Typography.Title>
      <Table columns={columns} dataSource={data} pagination={false} />
    </>
  );
};
export default MortalityReport;