import React, { useState } from 'react';
import { Select } from 'antd';
import './egg-and-body-weight.css';

export interface UnitData {
  unitName: string;
  shedName: string;
  minValue: string;
  maxValue: string;
  eggWeight: string;
}

export interface EggWeightProps {
  eggWeightData: UnitData[];
}

const { Option } = Select;

const EggWeight: React.FC<EggWeightProps> = ({ eggWeightData }) => {
  const [selectedUnit, setSelectedUnit] = useState<string>('');

  const getWeightCategory = (
    weight: number,
    minValue: number,
    maxValue: number
  ) => {
    if (weight <= minValue) return 'red';
    if (weight > minValue && weight < maxValue) return 'yellow';
    return 'green';
  };

  const handleUnitChange = (unitName: string) => {
    setSelectedUnit(unitName);
  };

  const calculateAverageWeight = (data: UnitData[]) => {
    const totalWeight = data.reduce(
      (sum, unit) => sum + parseFloat(unit.eggWeight),
      0
    );
    return data.length ? totalWeight / data.length : 0;
  };

  const uniqueUnits = Array.from(new Set(eggWeightData.map((unit) => unit.unitName)));

  const getUnitAverages = () => {
    const unitGroups = eggWeightData.reduce(
      (acc: Record<string, UnitData[]>, unit) => {
        if (!acc[unit.unitName]) {
          acc[unit.unitName] = [];
        }
        acc[unit.unitName].push(unit);
        return acc;
      },
      {}
    );

    return Object.entries(unitGroups).map(([unitName, units]) => ({
      unitName,
      averageEggWeight: calculateAverageWeight(units),
      minValue: parseFloat(units[0].minValue), 
      maxValue: parseFloat(units[0].maxValue), 
    }));
  };

  const filteredData = selectedUnit
    ? eggWeightData.filter((unit) => unit.unitName === selectedUnit)
    : eggWeightData;

  const redSheds = filteredData.filter(
    (unit) =>
      getWeightCategory(
        parseFloat(unit.eggWeight),
        parseFloat(unit.minValue),
        parseFloat(unit.maxValue)
      ) === 'red'
  );
  const yellowSheds = filteredData.filter(
    (unit) =>
      getWeightCategory(
        parseFloat(unit.eggWeight),
        parseFloat(unit.minValue),
        parseFloat(unit.maxValue)
      ) === 'yellow'
  );
  const greenSheds = filteredData.filter(
    (unit) =>
      getWeightCategory(
        parseFloat(unit.eggWeight),
        parseFloat(unit.minValue),
        parseFloat(unit.maxValue)
      ) === 'green'
  );

  const unitAverages = selectedUnit === '' ? getUnitAverages() : [];

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div className='filter-container'>
        <Select
          id='unitFilter'
          value={selectedUnit}
          onChange={handleUnitChange}
          style={{ width: 200 }}
          placeholder='Select Unit'
        >
          <Option value=''>All Units</Option>
          {uniqueUnits.map((unit, index) => (
            <Option key={index} value={unit}>
              {unit}
            </Option>
          ))}
        </Select>
      </div>

      <div className='unit'>
        {selectedUnit === '' ? (
          <>
            <div className='egg-section red'>
              <div className='red-section'>
                {unitAverages
                  .filter(
                    (unit) =>
                      getWeightCategory(
                        unit.averageEggWeight,
                        unit.minValue,
                        unit.maxValue
                      ) === 'red'
                  )
                  .map((unit, index) => (
                    <div key={index} className='egg-unit-data'>
                      <div>{unit.unitName}</div>
                    </div>
                  ))}
              </div>
            </div>
            <div className='egg-section yellow'>
              <div className='yellow-section'>
                {unitAverages
                  .filter(
                    (unit) =>
                      getWeightCategory(
                        unit.averageEggWeight,
                        unit.minValue,
                        unit.maxValue
                      ) === 'yellow'
                  )
                  .map((unit, index) => (
                    <div key={index} className='egg-unit-data'>
                      <div>{unit.unitName}</div>
                    </div>
                  ))}
              </div>
            </div>
            <div className='egg-section green'>
              <div className='green-section'>
                {unitAverages
                  .filter(
                    (unit) =>
                      getWeightCategory(
                        unit.averageEggWeight,
                        unit.minValue,
                        unit.maxValue
                      ) === 'green'
                  )
                  .map((unit, index) => (
                    <div key={index} className='egg-unit-data'>
                      <div>{unit.unitName}</div>
                    </div>
                  ))}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className='egg-section red'>
              <div className='red-section'>
                {redSheds.map((unit, index) => (
                  <div key={index} className='egg-data'>
                    <div>{unit.shedName}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className='egg-section yellow'>
              <div className='yellow-section'>
                {yellowSheds.map((unit, index) => (
                  <div key={index} className='egg-data'>
                    <div>{unit.shedName}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className='egg-section green'>
              <div className='green-section'>
                {greenSheds.map((unit, index) => (
                  <div key={index} className='egg-data'>
                    <div>{unit.shedName}</div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default EggWeight;
