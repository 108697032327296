export const dualLineChart = [
  { day: "2024-01-01", expenditures: 3000, revenue: 5000 },
  { day: "2024-01-02", expenditures: 3500, revenue: 5500 },
  { day: "2024-01-03", expenditures: 3200, revenue: 5800 },
  { day: "2024-01-04", expenditures: 3100, revenue: 6000 },
  { day: "2024-01-05", expenditures: 3400, revenue: 5900 },
  { day: "2024-01-06", expenditures: 3300, revenue: 6200 },
  { day: "2024-01-07", expenditures: 3100, revenue: 6300 },
  { day: "2024-01-08", expenditures: 3200, revenue: 6400 },
  { day: "2024-01-09", expenditures: 3500, revenue: 6500 },
  { day: "2024-01-10", expenditures: 3600, revenue: 6600 },
  { day: "2024-01-10", expenditures: 3600, revenue: 6600 },
  { day: "2024-01-10", expenditures: 3600, revenue: 6600 },
  { day: "2024-01-10", expenditures: 3600, revenue: 6600 },
  { day: "2024-01-10", expenditures: 3600, revenue: 6600 },
  { day: "2024-01-10", expenditures: 3600, revenue: 6600 },
  { day: "2024-01-10", expenditures: 3600, revenue: 6600 },
  { day: "2024-01-10", expenditures: 3600, revenue: 6600 },
];
