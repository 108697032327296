import React, { useState } from 'react'
import PoultryStandardsCapturing from './PolutryStandardsCapturing';

interface StandardProperty {
    standardProperty: string;
    standardValue: number;
    maxValue: number;
    minValue: number;
    uom: string;
}

interface PoultryStandard {
    weekNumber: number;
    BreedName: string;
    production: string;
    feedType: string;
    feedInTakeInGms: number;
    standardProperties: StandardProperty[];
}

const PoultryStandardsPage: React.FC = () => {
    const [dataList, setDataList] = useState<PoultryStandard[]>([]);

    const handleDataSubmit = (data: PoultryStandard) => {
        setDataList([...dataList, data]); // Add new data to the list
    };
    console.log((dataList));

    return (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
            <div style={{ width: '45%' }}>
                <PoultryStandardsCapturing onDataSubmit={handleDataSubmit} />
            </div>
        </div>
    );
};

export default PoultryStandardsPage;
