import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Button, CircularProgress, SelectChangeEvent } from '@mui/material';

interface DropdownsProps {
  data: any;
  generateReport: (branch: string, breed: string, weekNumber: string) => void;
  loading: boolean;
}

const Dropdowns: React.FC<DropdownsProps> = ({ data, generateReport, loading }) => {
  const [branches, setBranches] = useState<string[]>([]);
  const [breeds, setBreeds] = useState<string[]>([]);
  const [weeks, setWeeks] = useState<string[]>([]);
  const [selectedBranch, setSelectedBranch] = useState('');
  const [selectedBreed, setSelectedBreed] = useState('');
  const [selectedWeek, setSelectedWeek] = useState('');

  useEffect(() => {
    if (data) {
      const uniqueBranches = Object.keys(data);
      setBranches(uniqueBranches);
    }
  }, [data]);

  const handleBranchChange = (event: SelectChangeEvent) => {
    const branch = event.target.value;
    setSelectedBranch(branch);

    if (data && branch) {
      const branchBreeds = Object.keys(data[branch]);
      setBreeds(branchBreeds);
    } else {
      setBreeds([]);
    }
    setSelectedBreed('');
    setSelectedWeek('');
  };

  const handleBreedChange = (event: SelectChangeEvent) => {
    const breed = event.target.value;
    setSelectedBreed(breed);

    if (data && selectedBranch && breed) {
      const branchBreedData = data[selectedBranch][breed];
      const uniqueWeeks = Object.keys(branchBreedData);
      setWeeks(uniqueWeeks);
    } else {
      setWeeks([]);
    }
    setSelectedWeek('');
  };

  const handleWeekChange = (event: SelectChangeEvent) => {
    setSelectedWeek(event.target.value);
  };

  return (
    <>
      <FormControl fullWidth margin="normal">
        <InputLabel>Branch</InputLabel>
        <Select
          value={selectedBranch}
          onChange={handleBranchChange}
          label="Branch"
        >
          {branches.map((branch) => (
            <MenuItem key={branch} value={branch}>
              {branch}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth margin="normal">
        <InputLabel>Breed</InputLabel>
        <Select
          value={selectedBreed}
          onChange={handleBreedChange}
          label="Breed"
          disabled={!selectedBranch}
        >
          {breeds.map((breed) => (
            <MenuItem key={breed} value={breed}>
              {breed}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth margin="normal">
        <InputLabel>Week</InputLabel>
        <Select
          value={selectedWeek}
          onChange={handleWeekChange}
          label="Week"
          disabled={!selectedBreed}
        >
          {weeks.map((week) => (
            <MenuItem key={week} value={week}>
              {week}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Button
        variant="contained"
        onClick={() => generateReport(selectedBranch, selectedBreed, selectedWeek)}
        disabled={loading || !selectedBranch || !selectedBreed || !selectedWeek}
        style={{ marginTop: '20px' }}
      >
        {loading ? <CircularProgress size={24} /> : 'Generate Report'}
      </Button>
    </>
  );
};

export default Dropdowns;