import React, { useState } from 'react';
import { Calendar, Row, Select } from 'antd';
import type { CalendarProps } from 'antd';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import './Calendar2.css'

export interface CalendarUnitDataObj {
  date: string;
  unitName: string;
  shedName: string;
  standardValues: {
    productionValue: string;
    damagedEggsValue: string;
    mortalityValue: string;
  };
  production: string;
  damagedEggs: string;
  mortality: string;
  weather: string;
}

export interface CalendarComponentProps {
  calendarUnitData: CalendarUnitDataObj[];
}

function getMonthData(value: Dayjs) {
  if (value.month() === 8) {
    return '';
  }
  return null;
}

const CalendarComponent2: React.FC<CalendarComponentProps> = ({ calendarUnitData }) => {
  const [value, setValue] = useState<Dayjs>(dayjs());
  const [selectedUnit, setSelectedUnit] = useState<string>('All Units');
  const [selectedShed, setSelectedShed] = useState<string | undefined>(undefined);

  const handleUnitChange = (unitName: string) => {
    setSelectedUnit(unitName);
    setSelectedShed('All Sheds');
  };

  const handleShedChange = (shedName: string) => {
    setSelectedShed(shedName);
  };

  const filteredEvents = calendarUnitData.filter(dataItem => {
    const unitMatch = selectedUnit ? dataItem.unitName === selectedUnit : true;
    const shedMatch = selectedShed && selectedShed !== 'All Sheds' ? dataItem.shedName === selectedShed : true;
    return unitMatch && shedMatch;
  });

  const onPanelChange = (newValue: Dayjs) => {
    setValue(newValue);
  };

  const calculateAverages = (events: CalendarUnitDataObj[]) => {
    const totalSheds = events.length;
    if (totalSheds === 0) return null;

    const totalProduction = events.reduce((sum, event) => sum + parseInt(event.production), 0);
    const totalDamagedEggs = events.reduce((sum, event) => sum + parseInt(event.damagedEggs), 0);
    const totalMortality = events.reduce((sum, event) => sum + parseFloat(event.mortality), 0);

    return {
      averageProduction: (totalProduction / totalSheds).toFixed(1),
      averageDamagedEggs: (totalDamagedEggs / totalSheds).toFixed(1),
      averageMortality: (totalMortality / totalSheds).toFixed(1),
    };
  };

  const dateCellRender = (value: Dayjs) => {
    const currentDate = value.format('YYYY-MM-DD');
    const eventsForDate = calendarUnitData.filter(item => item.date === currentDate);

    if (eventsForDate.length === 0) {
      return null;
    }

    const weatherIcons: { [key: string]: string } = {
      Sunny: '☀️',
      Cloudy: '☁️',
      Rainy: '🌧️',
      Stormy: '⛈️',
    };

    let content = null;

    if (selectedUnit === 'All Units') {
      const averages = calculateAverages(eventsForDate);
      if (averages) {
        content = (
          <div style={{ textAlign: 'center', fontSize: '12px', marginBottom: '2px', display: "flex", flexDirection:'column', alignItems:'center' }}>
            <div style={{ borderRadius: "10px", backgroundColor: "pink", margin: "2px 2px", width: "55px", height: "20px" }}>
              <span style={{ marginRight: "2px", color: "Red" }}>•</span>{averages.averageProduction}
            </div>
            <div style={{display:"flex"}} >
              <div style={{ borderRadius: "10px", backgroundColor: "pink", margin: "0px 1px", width: "40px" }}>
                <span style={{ marginRight: "2px", color: "Yellow" }}>•</span>{averages.averageDamagedEggs}
              </div>
              <div style={{ borderRadius: "10px", backgroundColor: "pink", margin: "0px 1px", width: "40px" }}>
                <span style={{ marginRight: "2px", color: "green" }}>•</span>{averages.averageMortality}
              </div>
            </div>

          </div>
        );
      }
    } else if (selectedShed === 'All Sheds' && selectedUnit) {
      const filteredEventsForUnit = eventsForDate.filter(item => item.unitName === selectedUnit);
      const averages = calculateAverages(filteredEventsForUnit);
      if (averages) {
        content = (
          <div style={{ textAlign: 'center', fontSize: '12px', marginBottom: '2px', display: "flex", flexDirection:'column', alignItems:'center' }}>
            <div style={{ borderRadius: "10px", backgroundColor: "pink", margin: "2px 2px", width: "50px", height: "20px" }}>
              <span style={{ marginRight: "5px", color: "Red" }}>•</span>{averages.averageProduction}
            </div>
            <div style={{display:"flex"}} >
              <div style={{ borderRadius: "10px", backgroundColor: "pink", margin: "0px 1px", width: "35px" }}>
                <span style={{ marginRight: "5px", color: "Yellow" }}>•</span>{averages.averageDamagedEggs}
              </div>
              <div style={{ borderRadius: "10px", backgroundColor: "pink", margin: "0px 1px", width: "35px" }}>
                <span style={{ marginRight: "5px", color: "green" }}>•</span>{averages.averageMortality}
              </div>
            </div>

          </div>
        );
      }
    } else {
      const filteredEventsForDate = filteredEvents.filter(item => item.date === currentDate);
      if (filteredEventsForDate.length > 0) {
        content = filteredEventsForDate.map((item, index) => {
          const productionColor = parseInt(item.production) < parseInt(item.standardValues.productionValue) ? 'red' : 'green';
          const damagedEggsColor = parseInt(item.damagedEggs) > parseInt(item.standardValues.damagedEggsValue) ? 'red' : 'green';
          const mortalityColor = parseFloat(item.mortality) > parseFloat(item.standardValues.mortalityValue) ? 'red' : 'green';

          return (
            <div key={index} style={{ textAlign: 'center', fontSize: '12px', marginBottom: '2px', display: "flex", flexDirection: "column", alignItems: "center" }}>
              <div style={{ marginRight: '', display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
                {weatherIcons[item.weather] || '❓'}
                <div style={{ borderRadius: "50%", backgroundColor: productionColor, margin: "0px 3px", width: "30px", height: "25px", alignContent: "center" }}>
                  <span style={{ marginRight: "5px", color: "Red" }}>•</span>{item.production}</div>
              </div>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }} >
                <div style={{ borderRadius: "50%", backgroundColor: damagedEggsColor, margin: "0px 3px", width: "25px", height: "25px", alignContent: "center" }}>
                  <span style={{ marginRight: "5px", color: "yellow" }}>•</span>{item.damagedEggs}</div>
                <div style={{ borderRadius: "50%", backgroundColor: mortalityColor, margin: "0px 3px", width: "25px", height: "25px", alignContent: "center" }}>
                  <span style={{ marginRight: "5px", color: "green" }}>•</span>{item.mortality}</div>
              </div>
            </div>
          );
        });
      }
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '53px',
          width: '100%',
          overflow: 'scroll',
          scrollbarWidth: "none",
          whiteSpace: 'nowrap',
        }}
      >
        {content}
      </div>
    );
  };


  const cellRender: CalendarProps<Dayjs>['dateCellRender'] = (current) => {
    return dateCellRender(current);
  };

  const uniqueUnits = Array.from(new Set(calendarUnitData.map(item => item.unitName)));
  const uniqueSheds = selectedUnit && selectedUnit !== 'All Units' ? Array.from(new Set(calendarUnitData.filter(item => item.unitName === selectedUnit).map(item => item.shedName))) : [];

  return (
    <div className='unit-calendar' style={{  }}>
      <Row className='filter-legend' style={{
       
      }}>
        <Select
        className='filter-legend-inside'
        style={{width:140, marginRight:"5px"}}
          value={selectedUnit}
          onChange={handleUnitChange}
          placeholder="Select Unit"
        >
          <Select.Option key="All Units" value="All Units">
            All Units
          </Select.Option>
          {uniqueUnits.map(unitName => (
            <Select.Option key={unitName} value={unitName}>
              {unitName}
            </Select.Option>
          ))}
        </Select>

        <Select
          value={selectedShed}
          onChange={handleShedChange}
          placeholder="Select Shed"
          className='filter-legend-inside'
          style={{width:140, marginRight:"5px"}}
          disabled={!selectedUnit || selectedUnit === 'All Units'}
        >
          <Select.Option key="All Sheds" value="All Sheds">
            All Sheds
          </Select.Option>
          {uniqueSheds.map(shedName => (
            <Select.Option key={shedName} value={shedName}>
              {shedName}
            </Select.Option>
          ))}
        </Select>
        <div className='legend-details-div' style={{ }} >
          <div   className='legend-details' >
            {/* <span style={{ marginRight: "5px", color: "Red" }}>•</span> */}
            Production
          </div>
          <div   className='legend-details' >
            {/* <span style={{ marginRight: "5px", color: "Yellow" }}>•</span> */}
            Damages Eggs
          </div>
          <div   className='legend-details' >
            {/* <span style={{ marginRight: "5px", color: "Green" }}>•</span> */}
            Mortality
          </div>

        </div>

      </Row >
      <Calendar
        className="calendar"
        style={{ backgroundColor: '#93c5ed', }}
        value={value}
        onPanelChange={onPanelChange}
        dateCellRender={cellRender}
        monthCellRender={getMonthData}
      />
    </div >
  );
};

export default CalendarComponent2;
