import { Row, Col } from "antd";
import BusinessStatic from "../BusinessStatics/BusinessStatic";
import DualLineChart from "../LineChart/lineChart";
import { dualLineChart } from "../LineChart/LineChartData";

export const BusinessDashboard = () => {
    return (
        <div className="dashboard_container">
          <Row style={{ background: "" }} gutter={16}>
            <Col span={24} style={{ background: "" }}>
              <BusinessStatic />
            </Col>
    
            <Col span={24} style={{ background: "" }}>
              <DualLineChart dualLineChartData={dualLineChart} />
            </Col>
          </Row>
        </div>
      );
}