import React from 'react';
import { Button } from 'antd';
import './rca-observations.css';
import EggAndBodyWeight from '../egg-and-body-weight/egg-and-body-weight';

export interface RCAObservationsObj {
  id: number;
  name: string;
  notificationText: string;
  severityLevel: string;
  severityRate: number;
}

export interface RCAObservationsProps {
  data: RCAObservationsObj[];
}

const getCircleColor = (severityLevel: string) => {
  switch (severityLevel) {
    case 'High':
      return 'red';
    case 'Medium':
      return 'yellow';
    case 'Low':
      return 'green';
    default:
      return 'default';
  }
};

const eggWeightChart = < EggAndBodyWeight />


const RCAObservations: React.FC<RCAObservationsProps> = ({ data }) => {
  return (
    <div className='rca-observations-container' >
      <div style={{background:"", borderRadius:"", display:'flex', alignItems:'center', justifyContent:'center', marginTop:"-9px"}} >
        <img style={{ width: "30px", height: "30px", marginRight:"5px",  }} src='/Images/New folder/creative.gif' />
        <p style={{ fontSize: "18px", fontWeight: "700", textAlign: "center", }} > Business Insights </p>
      </div>

      <div className="container">

        {/* <div className="filter-buttons">
        <Button className="filter-btn all">All</Button>
        <Button className="filter-btn less-than-50">Less Than 50%</Button>
        <Button className="filter-btn fifty-to-eighty">50% - 80%</Button>
        <Button className="filter-btn eighty-to-hundred">80% - 100%</Button>
      </div> */}
        {data.map((item) => (
          <div key={item.id} className="card">
            <div className={`circle ${getCircleColor(item.severityLevel)}`}>{item.severityRate}</div>
            <div className="content">
              <div className="name">{item.name}</div>
              <div>
                {item.notificationText}
              </div>
            </div>
            <div>
              <Button type="primary" className="analyze-button">Let's Analyze</Button>
            </div>
          </div>
        ))}
      </div>
    </div>

  );
};



export default RCAObservations;
