import React, { useState } from "react";
import { Form, Input, Spin, List } from "antd";
import axios from "axios";

interface AddressFieldProps {
  value?: string;
  onChange: (value: string) => void;
  onSelect: (address: any) => void;
  latitude: string;
  longitude: string;
}

export const AddressField: React.FC<AddressFieldProps> = ({
  value,
  onChange,
  onSelect,
  latitude,
  longitude,
}) => {
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    onChange(inputValue);

    if (inputValue.length > 3) {
      setLoading(true);
      try {
        const response = await axios.get(
          "https://nominatim.openstreetmap.org/search",
          {
            params: {
              q: inputValue,
              format: "json",
              addressdetails: 1,
              limit: 5,
              "accept-language": "en",
            },
          }
        );
        setSuggestions(response.data);
      } catch (error) {
        console.error("Error fetching suggestions", error);
      } finally {
        setLoading(false);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleSelect = (address: any) => {
    const { lat, lon } = address;
    const formattedLat = parseFloat(lat).toFixed(8);
    const formattedLon = parseFloat(lon).toFixed(8);

    onSelect({
      address: address.display_name,
      latitude: formattedLat,
      longitude: formattedLon,
    });
    setSuggestions([]);
  };

  return (
    <div>
      <Form.Item
        name="address"
        label="Address"
        rules={[{ required: true, message: "Missing Address!" }]}
      >
        <div style={{ position: "relative" }}>
          <Input
            type="text"
            value={value}
            onChange={handleInputChange}
            placeholder="Search Address..."
            style={{ width: "100%", padding: "6px", fontSize: "14px" }}
          />
          {loading && (
            <Spin
              size="small"
              style={{ position: "absolute", right: 10, top: 10 }}
            />
          )}
          {suggestions.length > 0 && (
            <List
              style={{
                listStyleType: "none",
                padding: 0,
                margin: 0,
                position: "absolute",
                backgroundColor: "white",
                width: "100%",
                zIndex: 1,
              }}
              dataSource={suggestions}
              renderItem={(suggestion) => (
                <List.Item
                  key={suggestion.place_id}
                  onClick={() => handleSelect(suggestion)}
                  style={{
                    padding: "10px",
                    borderBottom: "1px solid #ccc",
                    cursor: "pointer",
                    backgroundColor: "#fff",
                  }}
                >
                  {suggestion.display_name}
                </List.Item>
              )}
            />
          )}
        </div>
      </Form.Item>
      <Form.Item name="latitude" label="Latitude">
        <Input
          type="number"
          step="any"
          readOnly
          value={latitude}
          placeholder="Enter Latitude"
        />
      </Form.Item>
      <Form.Item name="longitude" label="Longitude">
        <Input
          type="number"
          step="any"
          readOnly
          value={longitude}
          placeholder="Enter Longitude"
        />
      </Form.Item>
    </div>
  );
};
