import { Table, Typography } from 'antd';
import React from 'react';

interface BodyWeightReportProps {
  report: any;
}

const BodyWeightReport: React.FC<BodyWeightReportProps> = ({ report }) => {

    const columns = [
        {
          title: 'Current Body Weight',
          dataIndex: 'CurrentAverageBodyWeight',
          key: 'CurrentAverageBodyWeight',
        },
        {
          title: 'Standard Body Weight',
          dataIndex: 'StandardAverageBodyWeight',
          key: 'StandardAverageBodyWeight',
        },
        {
          title: 'Minimum Body Weight',
          dataIndex: 'MinimumBodyWeight',
          key: 'MinimumBodyWeight',
        },
        {
          title: 'Maximum Body Weight',
          dataIndex: 'MaximumBodyWeight',
          key: 'MaximumBodyWeight',
        },
      ];

      const data=[
        {
            key:"1",
            CurrentAverageBodyWeight:`${report.currentAvgBodyWeight.toFixed(2)} g`,
            StandardAverageBodyWeight:`${report.standardAvgBodyWeight.toFixed(2)} g`,
            MinimumBodyWeight:`${report.minBodyWeight.toFixed(2)} g in 
            ${report.minWeek}`,
            MaximumBodyWeight:`${report.maxBodyWeight.toFixed(2)} g  in 
            ${report.maxWeek}`
        }

      ]
      return (
        <>
          <Typography.Title level={4}>Body Weight Report</Typography.Title>
          <Table columns={columns} dataSource={data} pagination={false} />
        </>
      );
};

export default BodyWeightReport;