export const birdInfoData = [
    {
        breedName: "Bovans",
        breedImg: "Images/chick-3.png",
        stockingDate: "2024-05-01",
        birdAge: "12 weeks",
        shedName: "Shed A",
        hatchedDate: "2024-02-01"
    },
    {
        breedName: "Hy-line Brown",
        breedImg: "Images/chick-3.png",
        stockingDate: "2024-04-15",
        birdAge: "8 weeks",
        shedName: "Shed B",
        hatchedDate: "2024-06-01"
    },
    {
        breedName: "BABCOCK",
        breedImg: "Images/chick-3.png",
        stockingDate: "2024-03-20",
        birdAge: "16 weeks",
        shedName: "Shed C",
        hatchedDate: "2023-11-01"
    },
    {
        breedName: "Sky-lark",
        breedImg: "Images/chick-3.png",
        stockingDate: "2024-06-10",
        birdAge: "6 weeks",
        shedName: "Shed D",
        hatchedDate: "2024-07-01"
    }
]