import React, { useState } from 'react';
import axios from 'axios';
import { Button, Container, Typography, CircularProgress } from '@mui/material';
import Dropdowns from './Dropdowns';
import ReportDialog from './ReportDialog';

interface UploadProps {
  setRecommendations: (recommendations: any) => void;
}

const Upload: React.FC<UploadProps> = ({ setRecommendations }) => {
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [report, setReport] = useState<any>(null);

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const onFileUpload = async () => {
    if (!file) {
      alert('Please upload a file first.');
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post('http://143.198.233.137:5007/api/api/analysis/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const parsedData = response.data;
      setData(parsedData);

      setLoading(false);
      alert('File uploaded and parsed successfully!');

    } catch (error) {
      console.error('Error uploading file:', error);
      setLoading(false);
      alert('Error uploading and parsing file.');
    }
  };

  const generateReport = async (branch: string, breed: string, weekNumber: string) => {
    setLoading(true);

    try {
      const response = await axios.post('http://143.198.233.137:5007/api/api/analysis/generate', {
        branch,
        breed,
        weekNumber,
        data,
      });

      setReport(response.data);
      setLoading(false);
      setOpenDialog(true);

    } catch (error) {
      console.error('Error generating report:', error);
      setLoading(false);
      alert('Error generating report.');
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setReport(null);
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Upload Excel File
      </Typography>
      <input type="file" onChange={onFileChange} />
      <Button
        variant="contained"
        color="primary"
        onClick={onFileUpload}
        disabled={loading}
        style={{ marginTop: '10px' }}
      >
        {loading ? <CircularProgress size={24} /> : 'Upload & Parse'}
      </Button>

      {data && (
        <Dropdowns
          data={data}
          generateReport={generateReport}
          loading={loading}
        />
      )}

      <ReportDialog
        open={openDialog}
        onClose={handleCloseDialog}
        report={report}
      />
    </Container>
  );
};

export default Upload;