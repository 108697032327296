import React from "react";
import "./dashboard.css";
import { Col, Row } from "antd";

import PoultryShed from "./poultryShed/poultryShed";
import { poultryShedData } from "./poultryShed/poultryShedData";

import RootCauseAnalysisChart from "./root-cause_analysis/root-cause-analysis-chart";
import { rootCauseAnalysisData } from "./root-cause_analysis/root-cause-analysis-data";
import BirdInfoCard from "./breedData/BirdInfoCard";
import { birdInfoData } from "./breedData/BirdInfoData";
import RCAObservations from "./RCA-Observations/rca-observations";
import { data } from "./RCA-Observations/rca-observations-data";

import BatchInfoCard from "./batchData/batchInfoCard";
import { batchInfoData } from "./batchData/batchInfoData";
import WeekBreedAnalysis from "./batch-analysis-chart/week-breed-analysis";
import { weekBreedAnalysisData } from "./batch-analysis-chart/week-breed-analysis-data";
import TempVsMortality from "./temp-vs-mortality/temp-vs-mortality-chart";
import { tempVsMortalityData } from "./temp-vs-mortality/temp-vs-mortality-chart-data";

import Calendar2 from './Calendar/Calendar2'
import { calendarUnitData } from './Calendar/Calendar2Data'
import WeatherApp from "./weather-app/weather-app";

const Dashboard = () => {
    return (
        <div className="dashboard_container">
            <Row style={{ background: "" }} gutter={16}>
                <Col span={24} style={{ background: "" }}>
                    <PoultryShed poultryShedData={poultryShedData} />
                </Col>
                <div className="rca_col">
                    <div style={{ display: "flex" }}>
                        <Col span={12}>
                            <p style={{ marginTop: "10px", marginBottom: "-10px", fontSize: "18px", fontWeight: "700", textAlign: "center" }} >Root Cause Analysis</p>
                            <RootCauseAnalysisChart rootCauseAnalysisData={rootCauseAnalysisData}  />
                        </Col>
                        <Col span={5}>
                            <BirdInfoCard birdInfoData={birdInfoData} />
                        </Col>
                        <Col span={7}>
                            <div>
                                <RCAObservations data={data} />
                            </div>
                        </Col>
                    </div>
                </div>

                <div className="rca_col2"  >
                    <Col className="rca-col2-inner-col" >
                        <Col className="week_breed_analysis" span={15} >
                        <p style={{ marginTop: "1px",fontSize: "18px", fontWeight: "700", textAlign: "center" }} >Batch Info</p>
                            <WeekBreedAnalysis weekBreedAnalysisData={weekBreedAnalysisData} />
                        </Col>
                        <Col className="tempVsMortality" span={9}>
                            <TempVsMortality tempVsMortalityData={tempVsMortalityData} />
                        </Col>
                    </Col>
                </div>

                <div className="rca_col"  >
                    <Col className="rca-col2-inner-col">
                        <Col className="unit-calendar_" span={15}>
                            <Calendar2 calendarUnitData={calendarUnitData} />
                        </Col>
                        <Col className="weather-widget" span={9}>
                        <WeatherApp/>
                            {/* <img className="weather-app__" src="./Images/weather-v2.jpg" /> */}
                        </Col>
                    </Col>
                </div>
            </Row>
        </div>
    );
};

export default Dashboard;
