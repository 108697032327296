import { Table, Typography } from 'antd';
import React from 'react';


interface EggWeightReportProps {
  report: any;
}

const EggWeightReport: React.FC<EggWeightReportProps> = ({ report }) => {
    const columns=[
    {
        title:"Current Egg Weight",
        dataIndex:"CurrentAverageEggWeight",
        key :"CurrentAverageEggWeight"
    },
    {
        title:"Standard Egg Weight",
        dataIndex:"StandardAverageEggWeight",
        key :"StandardAverageEggWeight"
    },
    {
        title:"Minimum Egg Weight",
        dataIndex:"MinimumEggWeight",
        key :"MinimumEggWeight"
    },
    {
        title:"Maximum Egg Weight",
        dataIndex:"MaximumEggWeight",
        key :"MaximumEggWeight",
    },
];

const data= [
    {
        key:'1',
        CurrentAverageEggWeight:`${report.currentAvgEggWeight.toFixed(2)} g`,
        StandardAverageEggWeight:`${report.standardAvgEggWeight.toFixed(2)} g`,
        MinimumEggWeight:`${report.minEggWeight.toFixed(2)} g  in 
        ${report.minWeek}`,
        MaximumEggWeight:`${report.maxEggWeight.toFixed(2)} g  in 
        ${report.maxWeek}`,
    }
]
return (
    <>
      <Typography.Title level={4}>Egg Weight Report</Typography.Title>
      <Table columns={columns} dataSource={data} pagination={false} />
    </>
  );
};

export default EggWeightReport;