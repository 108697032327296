import { Badge, Button, Card, Col, Form, Input, Row, message } from "antd";
import React, { useEffect, useState } from "react";
import { poultry_standard } from "../../config";
import { AddressField } from "./AddressField";

interface DataType {
  id: number;
  unit_code: string;
  unit_name: string;
  address: string;
  latitude: string;
  longitude: string;
}

interface ItemsFormProps {
  item: DataType | null;
  onItemAdded: () => void;
}

export const UnitCodeForm: React.FC<ItemsFormProps> = ({
  item,
  onItemAdded,
}) => {
  const [form] = Form.useForm();
  const [address, setAddress] = useState<string>("");
  const [latitude, setLatitude] = useState<string>("");
  const [longitude, setLongitude] = useState<string>("");

  useEffect(() => {
    if (item) {
      form.setFieldsValue(item);
      setAddress(item.address);
      setLatitude(item.latitude);
      setLongitude(item.longitude);
    }
  }, [item, form]);

  const handleAddressChange = (newAddress: string) => {
    setAddress(newAddress);
  };

  const handleAddressSelect = (addressData: any) => {
    form.setFieldsValue({
      address: addressData.address,
      latitude: addressData.latitude,
      longitude: addressData.longitude,
    });
    setAddress(addressData.address);
    setLatitude(addressData.latitude);
    setLongitude(addressData.longitude);
  };

  const handleAddEevnts = async (values: DataType) => {
    try {
      const url = item
        ? `${poultry_standard}/unit/updateUnit/`
        : `${poultry_standard}/unit/createUnit`;

      const response = await fetch(url, {
        method: "Post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });

      const data = await response.json();

      if (data.success) {
        message.success(data.internalMessage);
        onItemAdded();
        form.resetFields();
        setAddress("");
        setLatitude("");
        setLongitude("");
      } else {
        message.error(data.internalMessage);
      }
    } catch (err) {
      console.error("Error while fetching data", err);
      message.error("Failed to save the unit.");
    }
  };

  return (
    <Badge.Ribbon text={item ? "UPDATE" : "+ NEW"} color="cyan">
      <Card size="small" className="form-container">
        <Form
          form={form}
          layout="vertical"
          onFinish={handleAddEevnts}
          className="form"
        >
          <Row gutter={24}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
              xl={{ span: 24 }}
            >
              <Form.Item
                name="unit_code"
                label="Unit Code"
                rules={[{ required: true, message: "Missing Unit Code!" }]}
              >
                <Input readOnly={!!item} placeholder="Enter Unit Code" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
              xl={{ span: 24 }}
            >
              <Form.Item
                name="unit_name"
                label="Unit Name"
                rules={[{ required: true, message: "Missing Unit Name!" }]}
              >
                <Input placeholder="Enter Unit Name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
              xl={{ span: 24 }}
            >
              <AddressField
                value={address}
                onChange={handleAddressChange}
                onSelect={handleAddressSelect}
                latitude={latitude}
                longitude={longitude}
              />
            </Col>
          </Row>
          <Row justify={"end"}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ backgroundColor: "#13C2C2" }}
              >
                {item ? "Update" : "Add"}
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Card>
    </Badge.Ribbon>
  );
};
