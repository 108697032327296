// import { Line } from '@ant-design/plots';
// import React, { useEffect, useState } from 'react';
// import { Select } from 'antd';
// import './temp-vs-mortality-chart.css';

// const { Option } = Select;

// export interface TempVariation {
//   temparature: number;
//   mortalityRate: number;
//   mortalityStandard: number;
//   mortalityMin: number;
//   mortalityMax: number;
//   production: number;
// }

// export interface BreedAgeWiseTempVariation {
//   birdAgeInWeek: number;
//   tempVariation: TempVariation;
// }

// export interface TempVsMortalityObj {
//   breedName: string;
//   unitCode: string;
//   breedAgeWiseTempVariation: BreedAgeWiseTempVariation[];
// }

// export interface TempVsMortalityProps {
//   tempVsMortalityData: TempVsMortalityObj[];
// }

// const TempVsMortality: React.FC<TempVsMortalityProps> = ({ tempVsMortalityData }) => {
//   const [data, setData] = useState<any[]>([]);
//   const [selectedUnit, setSelectedUnit] = useState<string>('All Units');
//   const [selectedWeek, setSelectedWeek] = useState<number | string>('All Weeks');

//   useEffect(() => {
//     const transformedData = tempVsMortalityData.flatMap(item =>
//       item.breedAgeWiseTempVariation.map((variation: BreedAgeWiseTempVariation) => ({ 
//         birdAgeInWeek: variation.birdAgeInWeek,
//         temperature: variation.tempVariation.temparature,
//         mortalityRate: variation.tempVariation.mortalityRate,
//         unitCode: item.unitCode,
//       }))
//     );

//     setData(transformedData);
//   }, [tempVsMortalityData]);

//   const handleUnitChange = (unitName: string) => {
//     setSelectedUnit(unitName);
//   };

//   const handleWeekChange = (weekName: string | number) => {
//     setSelectedWeek(weekName);
//   };

//   const filteredData = data.filter(dataItem => {
//     const unitMatch = selectedUnit === 'All Units' || dataItem.unitCode === selectedUnit;
//     const weekMatch = selectedWeek === 'All Weeks' || dataItem.birdAgeInWeek === selectedWeek;
//     return unitMatch && weekMatch;
//   });

//   const config: any = {
//     data: filteredData,
//     xField: 'temperature',
//     yField: 'mortalityRate',
//     seriesField: 'unitCode',
//     lineStyle: {
//       lineWidth: 2,
//     },
//     xAxis: {
//       title: { text: 'Temperature (°C)' },
//     },
//     yAxis: {
//       title: { text: 'Mortality Rate (%)' },
//     },
//     legend: { position: 'top' },
//   };

//   const unitOptions = ['All Units', ...Array.from(new Set(tempVsMortalityData.map(item => item.unitCode)))];
//   const weekOptions = ['All Weeks', ...Array.from(new Set(tempVsMortalityData.flatMap(item => item.breedAgeWiseTempVariation.map((variation: BreedAgeWiseTempVariation) => variation.birdAgeInWeek))))];

//   return (
//     <div>
//       <p className="dual-line-chart-heading">Temperature vs Mortality</p>
//       <div style={{ marginBottom: 16 }}>
//         <Select
//           value={selectedUnit}
//           onChange={handleUnitChange}
//           style={{ width: 200, marginRight: 16 }}
//         >
//           {unitOptions.map(unit => (
//             <Option key={unit} value={unit}>
//               {unit}
//             </Option>
//           ))}
//         </Select>
//         <Select
//           value={selectedWeek}
//           onChange={handleWeekChange}
//           style={{ width: 200 }}
//         >
//           {weekOptions.map(week => (
//             <Option key={week} value={week}>
//               {week}
//             </Option>
//           ))}
//         </Select>
//       </div>
//       <div className="dual-line-chart-container">
//         <Line {...config} className="dual-line-chart" />
//       </div>
//     </div>
//   );
// };

// export default TempVsMortality;













// this code has filtering. and its working perfectly.





import { Line } from '@ant-design/plots';
import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import './temp-vs-mortality-chart.css';

const { Option } = Select;

export interface TempVariation {
  temparature: number;
  mortalityRate: number;
  mortalityStandard: number;
  mortalityMin: number;
  mortalityMax: number;
  production: number;
}

export interface BreedAgeWiseTempVariation {
  birdAgeInWeek: number;
  tempVariation: TempVariation;
}

export interface TempVsMortalityObj {
  breedName: string;
  unitCode: string;
  breedAgeWiseTempVariation: BreedAgeWiseTempVariation[];
}

export interface TempVsMortalityProps {
  tempVsMortalityData: TempVsMortalityObj[];
}

const TempVsMortality: React.FC<TempVsMortalityProps> = ({ tempVsMortalityData }) => {
  const [data, setData] = useState<any[]>([]);
  const [selectedUnit, setSelectedUnit] = useState<string>('All Units');
  const [selectedWeek, setSelectedWeek] = useState<number | string>('All Weeks');

  useEffect(() => {
    const transformedData = tempVsMortalityData.flatMap(item =>
      item.breedAgeWiseTempVariation.map((variation: BreedAgeWiseTempVariation) => ({ 
        birdAgeInWeek: variation.birdAgeInWeek,
        temperature: variation.tempVariation.temparature,
        mortalityRate: variation.tempVariation.mortalityRate,
        unitCode: item.unitCode,
      }))
    );

    setData(transformedData);
  }, [tempVsMortalityData]);

  const handleUnitChange = (unitName: string) => {
    setSelectedUnit(unitName);
  };

  const handleWeekChange = (weekName: string | number) => {
    setSelectedWeek(weekName);
  };

  // Calculate the average mortality rate for each temperature based on the selected filters
  const getAverageData = () => {
    let filteredData = data;

    if (selectedWeek !== 'All Weeks') {
      filteredData = filteredData.filter(dataItem => dataItem.birdAgeInWeek === selectedWeek);
    }

    if (selectedUnit === 'All Units') {
      // Calculate average data across all units for the selected week
      const temperatureGroups = filteredData.reduce((acc, curr) => {
        if (!acc[curr.temperature]) {
          acc[curr.temperature] = [];
        }
        acc[curr.temperature].push(curr.mortalityRate);
        return acc;
      }, {} as Record<number, number[]>);

      return Object.keys(temperatureGroups).map(temp => ({
        temperature: Number(temp),
        mortalityRate: temperatureGroups[Number(temp)].reduce((a: number, b: number) => a + b, 0) / temperatureGroups[Number(temp)].length,
        unitCode: 'Average of All Units',
      }));
    } else if (selectedWeek === 'All Weeks') {
      // Calculate average data for the selected unit across all weeks
      const filteredDataByUnit = filteredData.filter(dataItem => dataItem.unitCode === selectedUnit);
      const temperatureGroups = filteredDataByUnit.reduce((acc, curr) => {
        if (!acc[curr.temperature]) {
          acc[curr.temperature] = [];
        }
        acc[curr.temperature].push(curr.mortalityRate);
        return acc;
      }, {} as Record<number, number[]>);

      return Object.keys(temperatureGroups).map(temp => ({
        temperature: Number(temp),
        mortalityRate: temperatureGroups[Number(temp)].reduce((a: number, b: number) => a + b, 0) / temperatureGroups[Number(temp)].length,
        unitCode: selectedUnit,
      }));
    } else {
      // Filter data based on selected unit and week
      return filteredData.filter(dataItem => dataItem.unitCode === selectedUnit);
    }
  };

  const filteredData = getAverageData();

  const config: any = {
    data: filteredData,
    xField: 'temperature',
    yField: 'mortalityRate',
    seriesField: 'unitCode',
    lineStyle: {
      lineWidth: 2,
    },
    xAxis: {
      title: { text: 'Temperature (°C)' },
    },
    yAxis: {
      title: { text: 'Mortality Rate (%)' },
    },
    legend: { position: 'top' },
  };

  const unitOptions = ['All Units', ...Array.from(new Set(tempVsMortalityData.map(item => item.unitCode)))];
  const weekOptions = ['All Weeks', ...Array.from(new Set(tempVsMortalityData.flatMap(item => item.breedAgeWiseTempVariation.map((variation: BreedAgeWiseTempVariation) => variation.birdAgeInWeek))))];

  return (
    <div>
      <p className="dual-line-chart-heading">Temperature vs Mortality</p>
      <div style={{ marginBottom: 16 }}>
        <Select
          value={selectedUnit}
          onChange={handleUnitChange}
          style={{ width: 200, marginRight: 16 }}
        >
          {unitOptions.map(unit => (
            <Option key={unit} value={unit}>
              {unit}
            </Option>
          ))}
        </Select>
        <Select
          value={selectedWeek}
          onChange={handleWeekChange}
          style={{ width: 200 }}
        >
          {weekOptions.map(week => (
            <Option key={week} value={week}>
              {week}
            </Option>
          ))}
        </Select>
      </div>
      <div className="dual-line-chart-container">
        <Line {...config} className="dual-line-chart" />
      </div>
    </div>
  );
};

export default TempVsMortality;






// code which has two charts 




// import { Line } from '@ant-design/plots';
// import React, { useEffect, useState } from 'react';
// import { Select, Switch } from 'antd';
// import './temp-vs-mortality-chart.css';

// const { Option } = Select;

// export interface TempVariation {
//   temparature: number;
//   mortalityRate: number;
//   mortalityStandard: number;
//   mortalityMin: number;
//   mortalityMax: number;
//   production: number;
// }

// export interface BreedAgeWiseTempVariation {
//   birdAgeInWeek: number;
//   tempVariation: TempVariation;
// }

// export interface TempVsMortalityObj {
//   breedName: string;
//   unitCode: string;
//   breedAgeWiseTempVariation: BreedAgeWiseTempVariation[];
// }

// export interface TempVsMortalityProps {
//   tempVsMortalityData: TempVsMortalityObj[];
// }

// const TempVsMortality: React.FC<TempVsMortalityProps> = ({ tempVsMortalityData }) => {
//   const [data, setData] = useState<any[]>([]);
//   const [selectedUnit, setSelectedUnit] = useState<string>('All Units');
//   const [selectedWeek, setSelectedWeek] = useState<number | string>('All Weeks');
//   const [isTempVsMortalityChart, setIsTempVsMortalityChart] = useState<boolean>(true); // Toggle state

//   useEffect(() => {
//     const transformedData = tempVsMortalityData.flatMap(item =>
//       item.breedAgeWiseTempVariation.map((variation: BreedAgeWiseTempVariation) => ({
//         birdAgeInWeek: variation.birdAgeInWeek,
//         temperature: variation.tempVariation.temparature,
//         mortalityRate: variation.tempVariation.mortalityRate,
//         unitCode: item.unitCode,
//       }))
//     );

//     setData(transformedData);
//   }, [tempVsMortalityData]);

//   const handleUnitChange = (unitName: string) => {
//     setSelectedUnit(unitName);
//   };

//   const handleWeekChange = (weekName: string | number) => {
//     setSelectedWeek(weekName);
//   };

//   const handleToggleChange = (checked: boolean) => {
//     setIsTempVsMortalityChart(checked);
//   };

//   const getAverageData = () => {
//     let filteredData = data;

//     if (selectedWeek !== 'All Weeks') {
//       filteredData = filteredData.filter(dataItem => dataItem.birdAgeInWeek === selectedWeek);
//     }

//     if (selectedUnit === 'All Units') {
//       const temperatureGroups = filteredData.reduce((acc, curr) => {
//         if (!acc[curr.temperature]) {
//           acc[curr.temperature] = [];
//         }
//         acc[curr.temperature].push(curr.mortalityRate);
//         return acc;
//       }, {} as Record<number, number[]>);

//       return Object.keys(temperatureGroups).map(temp => ({
//         temperature: Number(temp),
//         mortalityRate: temperatureGroups[Number(temp)].reduce((a: number, b: number) => a + b, 0) / temperatureGroups[Number(temp)].length,
//         unitCode: 'Average of All Units',
//       }));
//     } else if (selectedWeek === 'All Weeks') {
//       const filteredDataByUnit = filteredData.filter(dataItem => dataItem.unitCode === selectedUnit);
//       const temperatureGroups = filteredDataByUnit.reduce((acc, curr) => {
//         if (!acc[curr.temperature]) {
//           acc[curr.temperature] = [];
//         }
//         acc[curr.temperature].push(curr.mortalityRate);
//         return acc;
//       }, {} as Record<number, number[]>);

//       return Object.keys(temperatureGroups).map(temp => ({
//         temperature: Number(temp),
//         mortalityRate: temperatureGroups[Number(temp)].reduce((a: number, b: number) => a + b, 0) / temperatureGroups[Number(temp)].length,
//         unitCode: selectedUnit,
//       }));
//     } else {
//       return filteredData.filter(dataItem => dataItem.unitCode === selectedUnit);
//     }
//   };

//   const getWeekData = () => {
//     if (selectedUnit !== 'All Units') {
//       const filteredDataByUnit = data.filter(dataItem => dataItem.unitCode === selectedUnit);

//       return filteredDataByUnit.map(item => ({
//         temperature: item.temperature,
//         birdAgeInWeek: item.birdAgeInWeek,
//         mortalityRate: item.mortalityRate,
//       }));
//     } else {
//       const allUnitsData = data.map(item => ({
//         temperature: item.temperature,
//         birdAgeInWeek: item.birdAgeInWeek,
//         mortalityRate: item.mortalityRate,
//       }));

//       return allUnitsData;
//     }
//   };

//   const filteredData = isTempVsMortalityChart ? getAverageData() : getWeekData();

//   const config: any = {
//     data: filteredData,
//     xField: isTempVsMortalityChart ? 'temperature' : 'temperature',
//     yField: isTempVsMortalityChart ? 'mortalityRate' : 'birdAgeInWeek',
//     seriesField: isTempVsMortalityChart ? 'unitCode' : 'birdAgeInWeek',
//     lineStyle: {
//       lineWidth: 2,
//     },
//     xAxis: {
//       title: { text: 'Temperature (°C)' },
//     },
//     yAxis: {
//       title: { text: isTempVsMortalityChart ? 'Mortality Rate (%)' : 'Week' },
//     },
//     legend: { position: 'top' },
//   };

//   const unitOptions = ['All Units', ...Array.from(new Set(tempVsMortalityData.map(item => item.unitCode)))];
//   const weekOptions = ['All Weeks', ...Array.from(new Set(tempVsMortalityData.flatMap(item => item.breedAgeWiseTempVariation.map((variation: BreedAgeWiseTempVariation) => variation.birdAgeInWeek))))];

//   return (
//     <div>
//       <p className="dual-line-chart-heading">Temperature vs {isTempVsMortalityChart ? 'Mortality Rate' : 'Week'}</p>
//       <div style={{ marginBottom: 16 }}>
//         <Select
//           value={selectedUnit}
//           onChange={handleUnitChange}
//           style={{ width: 200, marginRight: 16 }}
//         >
//           {unitOptions.map(unit => (
//             <Option key={unit} value={unit}>
//               {unit}
//             </Option>
//           ))}
//         </Select>
//         <Select
//           value={selectedWeek}
//           onChange={handleWeekChange}
//           style={{ width: 200, marginRight: 16 }}
//         >
//           {weekOptions.map(week => (
//             <Option key={week} value={week}>
//               {week}
//             </Option>
//           ))}
//         </Select>
//         <Switch
//           checkedChildren="Temp vs Mortality Rate"
//           unCheckedChildren="Temp vs Week"
//           checked={isTempVsMortalityChart}
//           onChange={handleToggleChange}
//         />
//       </div>
//       <div className="dual-line-chart-container">
//         <Line {...config} className="dual-line-chart" />
//       </div>
//     </div>
//   );
// };

// export default TempVsMortality;












// // TempVsMortalityChart.tsx
// import { Bar } from '@ant-design/plots';
// import React, { useState } from 'react';

// // interfaces.ts
// export interface TempVariation {
//   temparature: number;
//   mortalityRate: number;
//   mortalityStandard: number;
//   mortalityMin: number;
//   mortalityMax: number;
//   production: number;
// }

// export interface BreedAgeWiseTempVariation {
//   birdAgeInWeek: number;
//   tempVariation: TempVariation;
// }

// export interface TempVsMortalityObj {
//   breedName: string;
//   unitCode: string;
//   breedAgeWiseTempVariation: BreedAgeWiseTempVariation[];
// }

// export interface TempVsMortalityProps {
//   tempVsMortalityData: TempVsMortalityObj[];
// }


// const transformData = (data: TempVsMortalityObj[]) => {
//   return data.flatMap(({ breedAgeWiseTempVariation }) =>
//     breedAgeWiseTempVariation.map(({ birdAgeInWeek, tempVariation }) => ({
//       ageInWeek: birdAgeInWeek,
//       temperature: tempVariation.temparature,
//       mortalityRate: tempVariation.mortalityRate,
//     }))
//   );
// };

// const TempVsMortalityChart: React.FC<TempVsMortalityProps> = ({ tempVsMortalityData }) => {
//   const data = transformData(tempVsMortalityData);
//   const [selectedUnit, setSelectedUnit] = useState<string>('All Units');


//   const handleUnitChange = (unitName: string) => {
//     setSelectedUnit(unitName);
//   }

  

//   const config: any = {
//     data,
//     xField: 'temperature',
//     yField: 'ageInWeek',
//     seriesField: 'ageInWeek',
//     legend: {
//       position: 'top-right',
//     },
//     xAxis: {
//       title: {
//         text: 'Temperature (°C)',
//       },
//     },
//     yAxis: {
//       title: {
//         text: 'Bird Age in Weeks',
//       },
//     },
//     tooltip: {
//       fields: ['temperature', 'ageInWeek', 'mortalityRate'],
//     },
//   };

//   return <Bar {...config} />;
// };

// export default TempVsMortalityChart;
