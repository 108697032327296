import {
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Input,
  InputRef,
  Popconfirm,
  Row,
  Space,
  Table,
  TableColumnType,
  TableColumnsType,
  Tooltip,
  message,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { UnitCodeForm } from "./UnitCodeForm";
import { poultry_standard } from "../../config";

interface DataType {
  id: number;
  unit_code: string;
  unit_name: string;
  address: string;
  latitude: string;
  longitude: string;
}

export const UnitCodeView = () => {
  type DataIndex = keyof DataType;
  const [searchText, setSearchText] = useState<string>("");
  const [searchedColumn, setSearchedColumn] = useState<DataIndex | null>(null);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const searchInput = useRef<InputRef>(null);
  const [itemData, setItemData] = useState<DataType[]>([]);
  const [editingItem, setEditingItem] = useState<DataType | null>(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await fetch(poultry_standard + "/unit/getAllUnits");
    const data = await response.json();
    console.log(data, "getting data");
    if (data.success) {
      setItemData(data.data);
      message.success(data.internalMessage);
    } else {
      message.error(data.internalMessage);
    }
  };

  const handleReset = (clearFilters?: () => void) => {
    clearFilters?.();
    setSearchText("");
  };

  const handleSearch = (
    selectedKeys: React.Key[],
    confirm: () => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0] as string);
    setSearchedColumn(dataIndex);
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): TableColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 12 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value.toString()] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 12, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            size="small"
            style={{ width: 90 }}
            onClick={() => {
              handleReset(clearFilters);
              confirm({ closeDropdown: true });
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase())
        : false,
  });

  const columns: TableColumnsType<DataType> = [
    {
      title: "S.No",
      key: "sno",
      align: "center",
      fixed: "left",
      width: "5%",
      render: (text, record, index) => (page - 1) * pageSize + (index + 1),
    },
    {
      title: "Unit Code",
      dataIndex: "unit_code",
      key: "unit_code",
      align: "center",
      ...getColumnSearchProps("unit_code"),
    },
    {
      title: "Unit Name",
      dataIndex: "unit_name",
      key: "unit_name",
      align: "center",
      ...getColumnSearchProps("unit_name"),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      align: "center",
      ...getColumnSearchProps("address"),
    },
    {
      title: "Latitude",
      dataIndex: "latitude",
      key: "latitude",
      align: "center",
      ...getColumnSearchProps("latitude"),
    },
    {
      title: "Longitutde",
      dataIndex: "longitude",
      key: "longitude",
      align: "center",
      ...getColumnSearchProps("longitude"),
    },
    {
      title: "Actions",
      key: "actions",
      align: "center",
      fixed: "right",
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="Edit">
            <EditOutlined
              onClick={() => handleEdit(record)}
              style={{ color: "green" }}
            />
          </Tooltip>
          <Divider type="vertical" />
          <Popconfirm
            title="Are you want to delete this item record?"
            onConfirm={() => handleDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title="Delete">
              <DeleteOutlined style={{ color: "red" }} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const deleteUnitData = async (id: number) => {
    try {
      const response = await fetch(
        `${poultry_standard}/unit/deleteUnit/${id}`,
        {
          method: "DELETE",
        }
      );
      const data = await response.json();
      if (response.ok) {
        message.success(data.internalMessage);
        fetchData();
      } else {
        message.error(data.internalMessage);
      }
    } catch (error) {
      console.error("Error deleting unit data:", error);
      message.error("Failed to delete the unit.");
    }
  };

  const handleItemAdded = () => {
    fetchData();
    setEditingItem(null);
  };

  const handleEdit = (record: DataType) => {
    setEditingItem(record);
  };

  const handleDelete = (record: DataType) => {
    deleteUnitData(record.id);
  };
  return (
    <Card size="small" style={{ height: "100%" }}>
      <Row gutter={24}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 4 }}
          lg={{ span: 16 }}
          xl={{ span: 16 }}
        >
          <Table
            dataSource={itemData}
            columns={columns}
            className="custom-table-wrapper"
            size="small"
            pagination={{
              current: page,
              pageSize,
              onChange: (page, pageSize) => {
                setPage(page);
                setPageSize(pageSize);
              },
            }}
            scroll={{ x: "max-content" }}
          />
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 4 }}
          lg={{ span: 8 }}
          xl={{ span: 8 }}
        >
          <UnitCodeForm item={editingItem} onItemAdded={handleItemAdded} />
        </Col>
      </Row>
    </Card>
  );
};
