// import React from "react";
// import { Card, Row, Col } from "antd";
// import "./dashboardCard.css";

// export interface PoultryDataItem {
//     title: string;
//     imgSrc: string;
//     today: number | string;
//     yesterday: number | string;
//     change: number;
//     borderColor: string;
//     lastThreeDays: {
//         actual: string;
//         standard: string;
//     }[];
// }

// interface PoultryDataAnalysisProps {
//     data: PoultryDataItem[];
// }

// const getColor = (actual: string, standard: string): string => {
//     const actualValue = parseFloat(actual);
//     const standardValue = parseFloat(standard);

//     if (actualValue >= standardValue) {
//         return "green";
//     } else if (actualValue < standardValue) {
//         return "red";
//     }
//     return "white";
// };

// const PoultryDataAnalysis: React.FC<PoultryDataAnalysisProps> = ({ data }) => {
//     return (
//         <div className="poultry-data-analysis">
//             <div className="cards-container">
//                 <Row className="cards">
//                     {data.map((item, index) => (
//                         <Col style={{display:"flex", justifyContent:"space-evenly"}} xs={24} sm={18} md={12} lg={8} xl={3} key={index}>
//                             <Card
//                                 bordered={false}
//                                 className="data-card"
//                                 style={{ borderTop: `3px solid ${item.borderColor || '#009688'}`, borderRadius:"10px", boxShadow:"0 2px 8px rgb(0,0,0,0.15)" }}
//                             >
//                                 <div className="card-content">
//                                     <div className="card-title">
//                                         <img src={item.imgSrc} />
//                                         <h5>{item.title}</h5>
//                                     </div>
//                                     <div className="card-stats">
//                                         <div>
//                                             <p className="card-stats-p"> YDay </p>
//                                             <div className="yday-value"> {item.yesterday} </div>
//                                         </div>
//                                         <div className="value-div">
//                                             <div className="today-value">{item.today}</div>
//                                             <div className="icon-and-change">
//                                                 <span className={`change ${item.change >= 0 ? 'positive' : 'negative'}`}>
//                                                     {item.change >= 0 ? '▲' : '▼'}
//                                                 </span>
//                                                 <div
//                                                     className="change-percentage"
//                                                     style={{ color: item.change >= 0 ? 'green' : 'red' }}
//                                                 >
//                                                     {item.change}%
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className="last3Days-div">
//                                         {item.lastThreeDays.map((day, idx) => (
//                                             <div key={idx}>
//                                                 <span className="last3Days" style={{ backgroundColor: getColor(day.actual, day.standard) }}>
//                                                     {day.actual}
//                                                 </span>
//                                             </div>
//                                         ))}
//                                     </div>
//                                 </div>
//                             </Card>
//                         </Col>
//                     ))}
//                 </Row>
//             </div>
//         </div>
//     );
// };

// export default PoultryDataAnalysis;














import React from "react";
import { Card, Row, Col } from "antd";
import "./dashboardCard.css";

export interface PoultryDataItem {
    title: string;
    imgSrc: string;
    today: number | string;
    yesterday: number | string;
    borderColor: string;
    lastThreeDays: {
        actual: string;
        standard: string;
    }[];
}

interface PoultryDataAnalysisProps {
    data: PoultryDataItem[];
}

const getColor = (actual: string, standard: string): string => {
    const actualValue = parseFloat(actual);
    const standardValue = parseFloat(standard);

    if (actualValue >= standardValue) {
        return "green";
    } else if (actualValue < standardValue) {
        return "red";
    }
    return "white";
};

// Function to calculate percentage change
const calculateChangePercentage = (today: number | string, yesterday: number | string): number => {
    const todayValue = parseFloat(today.toString());
    const yesterdayValue = parseFloat(yesterday.toString());
    const change = ((todayValue - yesterdayValue) / yesterdayValue) * 100;
    return parseFloat(change.toFixed(1)); 
};

const PoultryDataAnalysis: React.FC<PoultryDataAnalysisProps> = ({ data }) => {
    return (
        <div className="poultry-data-analysis"  >
            <div className="cards-container">
                <Row className="cards">
                    {data.map((item, index) => {
                        const isDamageOrMortality = item.title.toLowerCase().includes("damage") || item.title.toLowerCase().includes("mortality");

                        const changePercentage = calculateChangePercentage(item.today, item.yesterday);

                        return (
                            <Col style={{display:"flex", justifyContent:"space-evenly", }} xs={24} sm={18} md={12} lg={8} xl={3} key={index}>
                                <Card
                                    bordered={false}
                                    className="data-card"
                                    style={{ borderTop: `3px solid ${item.borderColor || '#009688'}`, borderRadius:"10px", boxShadow:"0 2px 8px rgb(0,0,0,0.15)" }}
                                >
                                    <div className="card-content">
                                        <div className="card-title">
                                            <img src={item.imgSrc} />
                                            <h5>{item.title}</h5>
                                        </div>
                                        <div className="card-stats">
                                            <div>
                                                <p className="card-stats-p"> YDay </p>
                                                <div className="yday-value"> {item.yesterday} </div>
                                            </div>
                                            <div className="value-div">
                                                <div className="today-value">{item.today}</div>
                                                <div className="icon-and-change">
                                                    <span className={`change ${isDamageOrMortality ? (changePercentage < 0 ? 'positive' : 'negative') : (changePercentage >= 0 ? 'positive' : 'negative')}`}>
                                                        {changePercentage >= 0 ? '▲' : '▼'}
                                                    </span>
                                                    <div
                                                        className="change-percentage"
                                                        style={{ color: isDamageOrMortality ? (changePercentage < 0 ? 'green' : 'red') : (changePercentage >= 0 ? 'green' : 'red') }}
                                                    >
                                                        {changePercentage}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="last3Days-div">
                                            {item.lastThreeDays.map((day, idx) => (
                                                <div key={idx}>
                                                    <span className="last3Days" style={{ backgroundColor: getColor(day.actual, day.standard) }}>
                                                        {day.actual}
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        );
                    })}
                </Row>
            </div>
        </div>
    );
};

export default PoultryDataAnalysis;
